import React from "react";

import {
  Button,
  gridSpacing,
  TableLink,
  ToolTip,
} from "@lucernahealth/lucerna-health-ui";
import { styled } from "styled-components";

import { colorTheme, Divider, makeHumanReadable } from "@utils";

import FavoriteButton from "./FavoriteButton";
import type { GroupProps } from "./helpers";
import {
  checkGroupPermission,
  DashboardContainer,
  DashboardHeader,
  Description,
  DisabledDashboardContainer,
} from "./helpers";
import type { ListInsightsDashboard, UserFavorites } from "~/src/types";
import { internalPageSuccess } from "~/src/utils/tsUtils";

const ActionContainer = styled.div`
  display: none;
`;

type GroupObjectProps = {
  subdomain: GroupProps;
  insightsDashboards: ListInsightsDashboard[];
  favorites: UserFavorites[];
  getFavorites: () => void;
};

const GroupObject = ({
  subdomain,
  insightsDashboards,
  favorites,
  getFavorites,
}: GroupObjectProps) => {
  const pathArr = subdomain.path.split("/");
  const subject = pathArr[2];
  const group = pathArr[3];

  const favorited = favorites.some(
    (f) =>
      f.object_id === subdomain.uuid &&
      f.object_type === "leap_insights_subdomain",
  );

  const copyLink = (e: React.MouseEvent) => {
    e.preventDefault();
    navigator.clipboard.writeText(window.location.host + subdomain.path);
    internalPageSuccess("A shareable link has been copied to clipboard");
  };

  const getSubjectPath = makeHumanReadable(subject);

  const hasPermission = checkGroupPermission(subject, group);

  if (!hasPermission) {
    return (
      <ToolTip text="You do not have permission">
        <DisabledDashboardContainer
          style={{
            height: 212,
            maxHeight: 212,
          }}
        >
          <div>
            <DashboardHeader style={{ justifyContent: "space-between" }}>
              <h4>
                <i
                  className="fa-regular fa-folder"
                  style={{
                    color: colorTheme("neutralL1"),
                    marginRight: gridSpacing[2],
                  }}
                />
                {subdomain.name}
              </h4>
              <i
                className="fa-solid fa-lock"
                style={{
                  color: colorTheme("neutralL2"),
                  marginRight: gridSpacing[2],
                }}
              />
            </DashboardHeader>
            <p
              style={{
                color: colorTheme("neutralL2"),
                fontSize: 14,
                marginBottom: gridSpacing[2],
                marginTop: gridSpacing[1],
              }}
            >
              {getSubjectPath}
            </p>
          </div>
        </DisabledDashboardContainer>
      </ToolTip>
    );
  }

  return (
    <TableLink noUnderline url={subdomain.path}>
      <DashboardContainer
        style={{
          height: 212,
          maxHeight: 212,
        }}
      >
        <div>
          <DashboardHeader style={{ justifyContent: "space-between" }}>
            <h4>
              <i
                className="fa-regular fa-folder"
                style={{
                  color: colorTheme("neutralL1"),
                  marginRight: gridSpacing[2],
                }}
              />
              {subdomain.name}
            </h4>
            <FavoriteButton
              favorite={favorited}
              objectId={subdomain.uuid}
              objectType="leap_insights_subdomain"
              refreshFavorites={getFavorites}
            />
          </DashboardHeader>
          <p
            style={{
              color: colorTheme("neutralL2"),
              fontSize: 14,
              margin: `${gridSpacing[1]}px 0 ${gridSpacing[2]}px 26px`, // 26px to Line up with header text
            }}
          >
            {getSubjectPath}
          </p>
          <Description>{subdomain.short_description ?? ""}</Description>
        </div>
        <div>
          <Divider
            style={{
              color: colorTheme("neutralL4"),
              marginTop: 0,
              marginBottom: gridSpacing[3],
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 28,
              justifyContent: "space-between",
            }}
          >
            <span
              style={{
                color: colorTheme("neutralL2"),
                marginLeft: gridSpacing[2],
                fontSize: 14,
              }}
            >
              {subdomain &&
                insightsDashboards.filter(
                  (d) => d.subdomain.uuid === subdomain.uuid,
                ).length}{" "}
              Dashboards
            </span>
            <ActionContainer className="icons">
              <Button
                style={{ paddingRight: 0 }}
                type="link"
                icon="fa-link"
                onClick={copyLink}
                tooltip="Copy link"
              />
            </ActionContainer>
          </div>
        </div>
      </DashboardContainer>
    </TableLink>
  );
};

export default GroupObject;
