const initialState = {
  isFetching: true,
  error: false,
  result: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "VALIDATOR_SET_VALIDATORS": {
      return Object.assign({}, state, {
        isFetching: action.payload.isFetching,
        error: action.payload.error,
        result: action.payload.result,
      });
    }

    case "VALIDATOR_ADD_VALIDATOR": {
      return Object.assign({}, state, {
        ...state,
        result: [...state.result, action.payload],
      });
    }

    case "VALIDATOR_UPDATE_VALIDATOR": {
      let validators = state.result.map((validator) => {
        if (validator.identifier === action.payload.identifier) {
          return action.payload;
        }
        return validator;
      });
      return Object.assign({}, state, {
        ...state,
        result: validators,
      });
    }

    case "VALIDATOR_DELETE_VALIDATOR": {
      let validators = [...state.result].filter(
        (validator) => validator.identifier !== action.payload.validatorId,
      );

      return Object.assign({}, state, {
        ...state,
        result: validators,
      });
    }

    default:
      return state;
  }
}
