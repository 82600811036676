import { DataViz } from "@commonComponents";
import { type TQueryOrderArray } from "@cubejs-client/core";

import type { DashboardAdvancedFilterProps } from "../../Portal/Portal";
import type { AdvFilterProps } from "~/src/utils/tsUtils";

export const assignedDrillDownQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: [
      "assigned_drilldown.total_assigned",
      "assigned_drilldown.total_base_capacity",
      "assigned_drilldown.total_max_capacity",
      "assigned_drilldown.total_assigned_540_day_flag",
      "assigned_drilldown.total_any_540_day_flag",
      "assigned_drilldown.total_provider_count",
    ],
    dimensions: [
      "assigned_drilldown.provider_facility_id",
      "assigned_drilldown.panel_id",
    ],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;

export const unassignedDrillDownQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["unassigned_consumer_detail.total_luc_id_count"],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;

export const providerSeenQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: [
      "assigned_consumer_detail.total_luc_id_count",
      "appointment_completed.any_provider_total_luc_id_count_30_days",
      "appointment_completed.any_provider_total_luc_id_count_90_days",
      "appointment_completed.any_provider_total_luc_id_count_365_days",
      "appointment_completed.any_provider_total_luc_id_count_540_days",
      "appointment_completed.any_provider_total_luc_id_count_730_days",
      "appointment_completed.attributed_provider_total_luc_id_count_30_days",
      "appointment_completed.attributed_provider_total_luc_id_count_90_days",
      "appointment_completed.attributed_provider_total_luc_id_count_365_days",
      "appointment_completed.attributed_provider_total_luc_id_count_540_days",
      "appointment_completed.attributed_provider_total_luc_id_count_730_days",
    ],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;

export const panelStatisticsQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: [
      "assigned_drilldown.panel_name",
      "assigned_drilldown.panel_id",
      "assigned_drilldown.provider_facility_id",
    ],
    order: {
      "assigned_drilldown.total_assigned": "desc",
    },
    measures: [
      "assigned_drilldown.total_assigned",
      "assigned_drilldown.total_base_capacity",
      "assigned_drilldown.total_max_capacity",
      "assigned_drilldown.total_assigned_540_day_flag",
      "assigned_drilldown.total_any_540_day_flag",
    ],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;

export const topFacilityStatisticsQuery = (
  filters?: AdvFilterProps,
  sorting?: TQueryOrderArray,
  filterOrder?: DashboardAdvancedFilterProps[],
  // pagination?: CubePagination,
) =>
  ({
    measures: [
      "assigned_drilldown.total_assigned",
      "assigned_drilldown.total_base_capacity",
      "assigned_drilldown.total_max_capacity",
      "assigned_drilldown.total_assigned_540_day_flag",
      "assigned_drilldown.total_any_540_day_flag",
      "assigned_drilldown.total_provider_count",
    ],
    dimensions: [
      "assigned_drilldown.facility_name",
      "assigned_drilldown.facility_id",
      "assigned_drilldown.panel_id",
      "assigned_drilldown.provider_facility_id",
    ],
    order:
      !sorting || sorting.length === 0
        ? ([
            ["assigned_drilldown.total_assigned", "desc"],
            ["assigned_drilldown.total_qualified", "desc"],
          ] as TQueryOrderArray)
        : sorting,
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
    // limit: pagination?.limit,
    // offset: pagination?.offset,
    // total: !!pagination,
  }) as const;

export const topProviderStatisticsQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: [
      "assigned_drilldown.total_assigned",
      "assigned_drilldown.total_base_capacity",
      "assigned_drilldown.total_max_capacity",
      "assigned_drilldown.total_assigned_540_day_flag",
      "assigned_drilldown.total_any_540_day_flag",
    ],
    dimensions: [
      "assigned_drilldown.provider_first_name",
      "assigned_drilldown.provider_last_name",
      "assigned_drilldown.provider_facility_id",
      "assigned_drilldown.panel_name",
      "assigned_drilldown.panel_id",
      "assigned_drilldown.provider_id",
    ],
    order: [
      ["assigned_drilldown.total_assigned", "desc"],
      // ["assigned_drilldown.total_qualified", "desc"],
    ],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;

export const capacityAnalysisDimensions = [
  "assigned_drilldown.roster_market_segment_current",
  "assigned_drilldown.roster_payer_name_current",
  "assigned_drilldown.panel_name",
  "assigned_drilldown.provider_npi",
  "assigned_drilldown.provider_last_name",
  "assigned_drilldown.provider_first_name",
  "assigned_drilldown.facility_state",
  "assigned_drilldown.facility_zip",
  "assigned_drilldown.facility_county",
  "assigned_drilldown.facility_region",
];
