import React, { useState } from "react";

import PropTypes from "prop-types";

import { DragDropProvider } from "./Context";
import DragSource from "./DragSource";
import DragTarget from "./DragTarget";

function DragDrop({ children, style }) {
  // Context set up for future use
  const [state, setState] = useState({});

  const setValue = (key, value) => {
    setState({ ...state, [key]: value });
  };

  let values = {
    ...state,
    setValue: (key, value) => setValue(key, value),
  };

  return (
    <DragDropProvider style={style} values={values}>
      {children}
    </DragDropProvider>
  );
}

DragDrop.Source = DragSource;
DragDrop.Target = DragTarget;

DragDrop.propTypes = {
  children: PropTypes.any,
  /** Custom styled object */
  style: PropTypes.object,
};

export default DragDrop;
