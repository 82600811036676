import React from "react";

import {
  Button,
  gridSpacing,
  StatusPill,
  TableLink,
} from "@lucernahealth/lucerna-health-ui";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { styled } from "styled-components";

import { colorTheme, Divider } from "@utils";

import FavoriteButton from "./FavoriteButton";
import type { QuicksightProps } from "./helpers";
import { DashboardContainer, DashboardHeader, Description } from "./helpers";
import type { ListInsightsDashboard, UserFavorites } from "~/src/types";
import { LEAP_INSIGHTS_URLS } from "~/src/urls";
import { internalPageSuccess } from "~/src/utils/tsUtils";

const Icons = styled.div`
  display: none;
`;

type DashboardObjectProps = {
  dashboard: ListInsightsDashboard | QuicksightProps;
  setSelectedDashboard?: (dashboard: ListInsightsDashboard) => void;
  smallCard?: boolean;
  favorites: UserFavorites[];
  getFavorites: () => void;
  customerName: string;
};

const DashboardObject = ({
  dashboard,
  setSelectedDashboard,
  smallCard = false,
  favorites,
  getFavorites,
  customerName,
}: DashboardObjectProps) => {
  const dashboardObj =
    dashboard.dashboard_type === "quicksight"
      ? (dashboard as QuicksightProps)
      : (dashboard as ListInsightsDashboard);

  const dashboardType = dashboardObj.dashboard_type;

  const path = `${LEAP_INSIGHTS_URLS.baseUrl}/${dashboardObj?.path}`;
  const history = useHistory();

  const favorited = favorites.some(
    (f) =>
      f.object_id === dashboardObj.uuid &&
      f.object_type ===
        (dashboardType === "lucerna"
          ? "leap_insights_lucerna"
          : "leap_insights_customer"),
  );

  const copyLink = (e: React.MouseEvent) => {
    e.preventDefault();
    navigator.clipboard.writeText(window.location.host + path);
    internalPageSuccess("A shareable link has been copied to clipboard");
  };

  const TypeStatusPill = (
    <StatusPill
      capitalize
      text={
        dashboard.managed_by == "customer"
          ? customerName
          : dashboard.managed_by === "lucerna"
            ? "Lucerna"
            : "Quicksight"
      }
      style={{
        backgroundColor:
          dashboard.managed_by === "customer"
            ? colorTheme("neutralL2")
            : dashboard.managed_by === "lucerna"
              ? colorTheme("info")
              : colorTheme("discovery"),
        color: colorTheme("white"),
      }}
    />
  );

  return (
    <TableLink newTab={smallCard} noUnderline url={path}>
      <DashboardContainer
        $smallCard={smallCard}
        onClick={(e) => {
          const target = e.target as HTMLElement;
          if (target.innerText && !smallCard) {
            history.push(path);
          }
        }}
      >
        <div>
          <div>
            <DashboardHeader>
              <div style={{ display: "flex", alignItems: "center" }}>
                <i
                  className="fa-regular fa-table-layout"
                  style={{
                    color:
                      dashboardType === "customer"
                        ? colorTheme("neutralL1")
                        : dashboardType === "lucerna"
                          ? colorTheme("info")
                          : colorTheme("discovery"),
                    marginRight: gridSpacing[2],
                  }}
                />
                <h4>{dashboardObj.name}</h4>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: gridSpacing[2] }}>
                  {smallCard && (
                    <div style={{ display: "flex" }}>
                      {TypeStatusPill}
                      {dashboard.embedded_details?.lucerna_managed && (
                        <StatusPill
                          ml={2}
                          capitalize
                          text="Custom"
                          style={{
                            backgroundColor: colorTheme("neutralL2"),
                            color: colorTheme("white"),
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
                {["customer", "lucerna"].includes(dashboardType) && (
                  <FavoriteButton
                    favorite={favorited}
                    objectType={
                      dashboardType === "lucerna"
                        ? "leap_insights_lucerna"
                        : "leap_insights_customer"
                    }
                    objectId={dashboardObj.uuid}
                    refreshFavorites={getFavorites}
                  />
                )}
              </div>
            </DashboardHeader>
            {["customer", "lucerna"].includes(dashboardType) && (
              <div
                style={{
                  color: colorTheme("neutralL2"),
                  fontSize: 14,
                  marginBottom: gridSpacing[2],
                  marginLeft: 26, // Line up with header text
                }}
              >
                {`${
                  (dashboardObj as ListInsightsDashboard)?.subdomain
                    ?.analytic_domain?.name ?? ""
                } / ${
                  (dashboardObj as ListInsightsDashboard)?.subdomain?.name ?? ""
                }`}
              </div>
            )}
          </div>
          <Description>{dashboardObj.short_description ?? ""}</Description>
        </div>
        {!smallCard && (
          <div style={{ marginLeft: gridSpacing[2] }}>
            <Divider
              style={{
                color: colorTheme("neutralL4"),
                marginTop: 0,
                marginBottom: gridSpacing[3],
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: 28,
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex" }}>
                {TypeStatusPill}
                {dashboard.embedded_details?.lucerna_managed && (
                  <StatusPill
                    ml={2}
                    capitalize
                    text="Custom"
                    style={{
                      backgroundColor: colorTheme("neutralL2"),
                      color: colorTheme("white"),
                    }}
                  />
                )}
              </div>
              <Icons className="icons">
                {setSelectedDashboard &&
                  ["customer", "lucerna"].includes(dashboardType) && (
                    <Button
                      style={{
                        padding: `0 ${gridSpacing[2]}px`,
                      }}
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        setSelectedDashboard(
                          dashboardObj as ListInsightsDashboard,
                        );
                      }}
                      type="link"
                      icon="fa-info-circle"
                      tooltip="Info"
                    />
                  )}

                <Button
                  style={{
                    paddingLeft: gridSpacing[2],
                    paddingRight: 0,
                  }}
                  type="link"
                  icon="fa-link"
                  onClick={copyLink}
                  tooltip="Copy link"
                />
              </Icons>
            </div>
          </div>
        )}
      </DashboardContainer>
    </TableLink>
  );
};

const mapStateToProps = (state: {
  SystemManagement: {
    management: {
      general: {
        result: {
          name: string;
        };
      };
    };
  };
}) => {
  return {
    customerName:
      state.SystemManagement?.management?.general?.result?.name || "Customer",
  };
};

export default connect(mapStateToProps)(DashboardObject);
