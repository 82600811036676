import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

interface ProviderDirectoryCareTeamState {
  fetchList: boolean;
}

const initialState: ProviderDirectoryCareTeamState = {
  fetchList: false,
};

const careTeamSlice = createSlice({
  name: "careTeam",
  initialState,
  reducers: (create) => {
    return {
      setCareTeamFetchListRedux: create.reducer(
        (state, action: PayloadAction<boolean>) => {
          state.fetchList = action.payload;
        },
      ),
    };
  },
});

export const { setCareTeamFetchListRedux } = careTeamSlice.actions;

export default careTeamSlice.reducer;
