import React, { memo } from "react";

import equal from "fast-deep-equal/es6";
import * as Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown.js";
import HighchartsReact from "highcharts-react-official";

import { colorTheme } from "@utils";

import Error from "./Common/Error";
import Header from "./Common/Header";
import Loading from "./Common/Loading";
import { Wrapper } from "./Common/styles";

type Series = {
  name?: string | undefined;
  id?: string | undefined;
  data?: (
    | { name: string; isSum: boolean }
    | { name: string | undefined; y: number }
  )[];
}[];

type WaterfallChartProps = {
  title: string;
  subtitle?: string;
  yAxisLabel: string;
  maxNumber?: number;
  options?: string[];
  highlightTooltipSuffix?: string;
  data: Series;
  linkUrl?: string;
  linkText?: string;
  toolTip?: string;
  loading?: boolean;
  error?: string | null;
  drillDown?: Series;
};

const WaterfallChart = memo(
  ({
    title,
    subtitle,
    yAxisLabel,
    options,
    maxNumber,
    highlightTooltipSuffix,
    data,
    linkUrl,
    linkText,
    toolTip,
    loading,
    error,
    drillDown,
  }: WaterfallChartProps) => {
    const renderWaterfallChart = () => {
      const waterfallOptions: Highcharts.Options = {
        chart: {
          type: "waterfall",
        },
        yAxis: {
          min: 0,
          max: maxNumber ?? undefined,
          title: {
            text: yAxisLabel,
          },
        },
        tooltip: {
          valueSuffix: highlightTooltipSuffix,
        },
        series: data as Highcharts.SeriesWaterfallOptions[],
        legend: {
          enabled: false,
        },
        title: { text: undefined },
        credits: {
          enabled: false,
        },
      };

      if (options) {
        waterfallOptions.xAxis = { categories: options, crosshair: true };
      } else {
        waterfallOptions.xAxis = { type: "category", crosshair: true };
      }

      if (drillDown) {
        drilldown(Highcharts);
        waterfallOptions.drilldown = {
          series: drillDown as Highcharts.SeriesWaterfallOptions[],
          activeAxisLabelStyle: {
            color: colorTheme("neutralL1"),
          },
          activeDataLabelStyle: {
            color: colorTheme("neutralL1"),
            textDecoration: "none",
          },
        };
      }

      return (
        <HighchartsReact highcharts={Highcharts} options={waterfallOptions} />
      );
    };

    if (error) {
      return <Error errorMessage={error} />;
    }

    if (loading) {
      return <Loading />;
    }

    return (
      <Wrapper>
        <Header
          title={title}
          subtitle={subtitle}
          toolTip={toolTip}
          linkUrl={linkUrl}
          linkText={linkText}
        />
        {renderWaterfallChart()}
      </Wrapper>
    );
  },
  (oldProps, newProps) => equal(oldProps, newProps),
);

export default WaterfallChart;
