import React from "react";

import { Button, TableLink, ToolTip } from "@lucernahealth/lucerna-health-ui";
import { connect } from "react-redux";

import { colorTheme } from "~/src/utils";

type DocumentationButtonProps = {
  documentationUrl?: string;
  docRoute: string;
};

const DocumentationButton = ({
  documentationUrl,
  docRoute,
}: DocumentationButtonProps) => {
  if (!documentationUrl) return null;

  return (
    <TableLink newTab url={`${documentationUrl}${docRoute}`}>
      <ToolTip text="User Guide">
        <Button
          type="link"
          style={{
            color: colorTheme("neutralL2"),
          }}
          icon="fa-regular fa-book"
        />
      </ToolTip>
    </TableLink>
  );
};

const mapStateToProps = (state: {
  main: {
    documentationUrl?: string;
  };
}) => {
  return {
    documentationUrl: state.main.documentationUrl,
  };
};

export default connect(mapStateToProps)(DocumentationButton);
