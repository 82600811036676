const initialState = {
  slackNotifications: {
    isFetching: true,
    error: false,
    result: [],
  },
  slackNotificationJobOptions: {
    isFetching: true,
    error: false,
    result: [],
  },
  slackNotificationEventOptions: {
    isFetching: true,
    error: false,
    result: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "SLACK_NOTIFICATIONS_SET": {
      return Object.assign({}, state, {
        slackNotifications: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    case "SLACK_NOTIFICATIONS_ADD": {
      return Object.assign({}, state, {
        slackNotifications: {
          ...state.slackNotifications,
          result: [
            ...state.slackNotifications.result,
            action.payload.notification,
          ],
        },
      });
    }

    case "SLACK_NOTIFICATIONS_EDIT": {
      return Object.assign({}, state, {
        slackNotifications: {
          ...state.slackNotifications,
          result: state.slackNotifications.result.map((obj) => {
            if (obj.uuid === action.payload.notification.uuid) {
              return action.payload.notification;
            }
            return obj;
          }),
        },
      });
    }

    case "SLACK_NOTIFICATIONS_DELETE": {
      return Object.assign({}, state, {
        slackNotifications: {
          ...state.slackNotifications,
          result: state.slackNotifications.result.filter(
            (obj) => obj.uuid !== action.payload.notificationId,
          ),
        },
      });
    }

    case "SLACK_NOTIFICATIONS_JOB_OPTIONS_SET": {
      return Object.assign({}, state, {
        slackNotificationJobOptions: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    case "SLACK_NOTIFICATIONS_EVENT_OPTIONS_SET": {
      return Object.assign({}, state, {
        slackNotificationEventOptions: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    default:
      return state;
  }
}
