import React from "react";

import { gridSpacing } from "@lucernahealth/lucerna-health-ui";
import PropTypes from "prop-types";
import { styled, css } from "styled-components";

import { colorTheme } from "@utils";

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const TabNavigation = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${gridSpacing[6]}px;
`;

const TabNavigationChild = styled.div`
  cursor: ${({ selected }) => (selected ? "default" : "pointer")};
  color: ${({ selected }) =>
    selected ? colorTheme("primary") : colorTheme("neutralL1")};
  background: ${({ selected }) =>
    selected ? colorTheme("primaryL4") : "transparent"};

  font-size: 14px;
  padding: ${gridSpacing[3]}px ${gridSpacing[2]}px;
  margin-bottom: ${gridSpacing[2]}px;
  margin-left: ${gridSpacing[5]}px;
  display: flex;
  align-items: center;
  border-radius: 8px;

  min-width: 225px;

  ${({ selected }) =>
    !selected &&
    css`
    &:hover {
      color ${colorTheme("primaryL1")};
      background: ${colorTheme("primaryL4")};
    }
  `}
`;

const VerticalTabs = ({ tabs, disabled, tabStyle }) => (
  <Container>
    <TabNavigation>
      {tabs.map(({ tabKey, title, selected }, i) => (
        <TabNavigationChild
          key={i}
          onClick={() => {
            if (tabKey) {
              window.location.hash = tabKey;
            }
          }}
          selected={selected || window.location.hash.substring(1) === tabKey}
          style={{
            ...tabStyle,
            cursor: !selected && disabled ? "not-allowed" : "pointer",
          }}
        >
          <p>{title}</p>
        </TabNavigationChild>
      ))}
    </TabNavigation>
    <div
      style={{
        border: `1px solid ${colorTheme("neutralL4")}`,
        marginRight: gridSpacing[5],
        marginLeft: gridSpacing[5],
      }}
    />
  </Container>
);

VerticalTabs.propTypes = {
  tabs: PropTypes.array,
  disabled: PropTypes.bool,
  tabStyle: PropTypes.object,
};

export default VerticalTabs;
