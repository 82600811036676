import React, { useContext } from "react";

import { DataViz } from "@commonComponents";
import moment from "moment";

import {
  payerBreakdownFilterDimensions,
  payerTrendQuery,
  segmentTrendQuery,
  subSegmentTrendQuery,
} from "./Queries";
import { PortalContext } from "../../Portal/Portal";
import { useCubeApiQuery } from "~/src/utils/tsUtils";

type LineTrendData = {
  name: string;
  data: number[];
};

type LineTrendResult = {
  data: LineTrendData[];
  options: string[];
};

const PayerBreakdown = () => {
  const { filters, filterOrder } = useContext(PortalContext);

  DataViz.filterUtils.useFilterOptionsNormalized({
    dimensions: payerBreakdownFilterDimensions,
  });

  const {
    resultSet: payerTrendResult,
    error: payerTrendError,
    isLoading: payerTrendLoading,
  } = useCubeApiQuery(payerTrendQuery(filters, filterOrder));

  const {
    resultSet: segmentTrendResult,
    error: segmentTrendError,
    isLoading: segmentTrendLoading,
  } = useCubeApiQuery(segmentTrendQuery(filters, filterOrder));

  const {
    resultSet: subSegmentTrendResult,
    error: subSegmentTrendError,
    isLoading: subSegmentTrendLoading,
  } = useCubeApiQuery(subSegmentTrendQuery(filters, filterOrder));

  const generateLineTrend = (
    trendData: Record<string, string>[],
    key: string,
  ): LineTrendResult => {
    if (!trendData) {
      return { data: [], options: [] };
    }

    const names = [
      ...new Set(trendData.map((data) => data[`roster_volume.${key}`])),
    ];

    const uniqueYearmos = [
      ...new Set(
        trendData.map((data) => parseInt(data["roster_volume.current_yearmo"])),
      ),
    ].sort((a, b) => a - b);

    const yearmoOrderMapping = uniqueYearmos.reduce(
      (acc, curr, index) => {
        return { ...acc, [curr]: index };
      },
      {} as Record<number, number>,
    );

    const data: LineTrendData[] = [];
    names.forEach((name) => {
      const nameData = new Array(23).fill(null);

      trendData
        .filter((obj) => obj[`roster_volume.${key}`] === name)
        .forEach((obj) => {
          nameData[
            yearmoOrderMapping[parseInt(obj["roster_volume.current_yearmo"])]
          ] = parseInt(obj["roster_volume.total_member_count"]);
        });

      data.push({
        name,
        data: nameData,
      });
    });

    const options = uniqueYearmos.map((yearmo) =>
      moment(yearmo.toString(), "YYYYMM").format("MM/YYYY"),
    );

    return {
      data,
      options: options,
    };
  };

  const payerLineTrend = generateLineTrend(
    payerTrendResult?.rawData() ?? [],
    "payer_name",
  );
  const segmentLineTrend = generateLineTrend(
    segmentTrendResult?.rawData() ?? [],
    "market_segment",
  );
  const subSegmentLineTrend = generateLineTrend(
    subSegmentTrendResult?.rawData() ?? [],
    "market_sub_segment",
  );

  return (
    <>
      <DataViz.Row>
        <DataViz.LineChart
          title="Payer Trend"
          subtitle="Click on a legend to hide the corresponding series from the chart"
          toolTip="Change in roster volume by payer for the last 24 months"
          loading={payerTrendLoading}
          error={payerTrendError && "Something went wrong"}
          yAxisLabel="Total Members"
          {...payerLineTrend}
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.LineChart
          title="Segment Trend"
          subtitle="Click on a legend to hide the corresponding series from the chart"
          toolTip="Change in roster volume by market segment for the last 24 months"
          loading={segmentTrendLoading}
          error={segmentTrendError && "Something went wrong"}
          yAxisLabel="Total Members"
          {...segmentLineTrend}
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.LineChart
          title="Sub-Segment Trend"
          subtitle="Click on a legend to hide the corresponding series from the chart"
          toolTip="Change in roster volume by market sub-segment for the last 24 months"
          loading={subSegmentTrendLoading}
          error={subSegmentTrendError && "Something went wrong"}
          yAxisLabel="Total Members"
          {...subSegmentLineTrend}
        />
      </DataViz.Row>
    </>
  );
};

export default PayerBreakdown;
