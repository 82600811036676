import moment from "moment";

import { numberWithCommas } from "@utils";

import { DASHBOARD_COLOR } from "~/src/CommonComponents/DataVisualization/Common/styles";

const sortData = (
  data: {
    "appointment_completed.appointment_type"?: string;
    "appointment_completed.appointment_category"?: string;
  }[],
) => {
  const appointmentKey = data[0]["appointment_completed.appointment_type"]
    ? "appointment_completed.appointment_type"
    : "appointment_completed.appointment_category";

  const sortedData = data.sort((a, b) => {
    const aType = a[appointmentKey];
    const bType = b[appointmentKey];
    if (!aType) {
      return 1;
    } else if (!bType) {
      return -1;
    }
    return aType.localeCompare(bType);
  });

  return {
    appointmentKey: appointmentKey as
      | "appointment_completed.appointment_type"
      | "appointment_completed.appointment_category",
    sortedData,
  };
};

export const getDataByAppointmentTypeOrCategory = (
  _data?: {
    "appointment_completed.appointment_type"?: string;
    "appointment_completed.appointment_category"?: string;
  }[],
) => {
  if (!_data) {
    return [];
  }
  const { appointmentKey, sortedData: data } = sortData(_data);
  const resData = data.map((obj) => {
    const res = {} as {
      name: string;
      y: number;
      drilldown: string;
      id: string;
      color: string;
    };
    res.name = obj[appointmentKey]!;
    // sum of all numbers in the object
    res.y = Object.values(obj).reduce((acc, curr) => {
      const num = parseInt(curr);
      if (!isNaN(num)) {
        return acc + parseInt(curr);
      }
      return acc;
    }, 0);

    res.drilldown = obj[appointmentKey]!;
    res.id = obj[appointmentKey]!;
    res.color = DASHBOARD_COLOR;
    return res;
  });

  resData.sort((a, b) => b.y - a.y);

  return [{ name: "Total Visits", data: resData, color: DASHBOARD_COLOR }];
};

export const getDrilldownAppointmentByQuarter = (
  totalCounts?: {
    name: string;
    count: number;
  }[],
  quarterData?: {
    name?: string;
    count?: string;
    quarter?: string;
  }[],
  includeYears = true,
) => {
  if (!totalCounts || !quarterData) {
    return [];
  }

  const removeYearAndTime = (dateTimeStr: string): string => {
    // Extract the date part before the 'T' and split it into components
    const datePart = dateTimeStr.split("T")[0];
    const parts = datePart.split("-");

    // Return the month and day in "MM-DD" format
    return `${parts[1]}-${parts[2]}`;
  };

  const getTotalCountPerCategory = (category: string) =>
    quarterData
      .filter((o) => o["name"] === category)
      .reduce((acc, curr) => acc + parseInt(curr["count"] ?? "0"), 0);

  const getQuarterArray = (
    year: string,
    category: string,
    quarters: string[],
  ) => {
    const currentDate = moment();

    const quarterArray = quarters
      .filter(
        (quarter) =>
          year !== currentDate.format("YYYY") ||
          quarter.split("-")[0] < currentDate.format("MM"),
      )
      .map((quarter, i) => {
        return {
          name: `Q${i + 1} - ${year}`,
          y: quarterData
            .filter(
              (o) =>
                o["name"] === category &&
                o["quarter"]?.split("-")[0] === year &&
                removeYearAndTime(o["quarter"]) === quarter,
            )
            .reduce((acc, curr) => acc + parseInt(curr["count"] ?? "0"), 0),
        };
      });

    if (includeYears) {
      return [...quarterArray, { name: year, isSum: true }];
    } else {
      return quarterArray;
    }
  };

  const years = [
    ...new Set(quarterData.map((o) => o["quarter"]?.split("-")[0])),
  ].sort((a, b) => parseInt(`${a}`) - parseInt(`${b}`));

  const quarters = [
    ...new Set(quarterData.map((o) => removeYearAndTime(o["quarter"] ?? ""))),
  ].sort((a, b) => parseInt(a.split("-")[0]) - parseInt(b.split("-")[0]));

  const categories = quarterData
    .map((o) => o["name"])
    .filter((v, i, a) => a.indexOf(v) === i);

  const drilldownData = categories.map((category) => {
    const totalCount = totalCounts.find((o) => o.name === category)?.count ?? 0;
    return {
      name: category,
      id: category,
      data: [
        ...(includeYears
          ? [
              {
                name: moment().subtract(3, "years").format("YYYY"),
                y: totalCount - getTotalCountPerCategory(`${category}`),
              },
            ]
          : []),
        ...years.flatMap((year) =>
          getQuarterArray(`${year}`, `${category}`, quarters),
        ),
      ],
    };
  });

  return drilldownData;
};

export const getColumnsForDrillDownTable = (
  firstColumnDisplayName: string,
  data?: {
    name: string | undefined;
    id: string | undefined;
    data: ({ name: string; isSum: boolean } | { name: string; y: number })[];
  }[],
  includeYears = true,
) => {
  if (!data?.[0]) {
    return [];
  }

  const getHeader = (
    obj: { name: string; isSum: boolean } | { name: string; y: number },
    index: number,
  ) => {
    if ("isSum" in obj || (index === 0 && includeYears)) {
      if (index === data[0].data.length - 1) {
        return "Current Total";
      }

      return `Total as of ${obj.name}`;
    }

    return obj.name;
  };

  const columns = [
    {
      Header: firstColumnDisplayName,
      id: firstColumnDisplayName,
      accessor: "name",
      sticky: "left",
      Cell: (props: { value: string }) => props.value,
    },
  ];

  data[0].data.forEach((obj, i) => {
    columns.push({
      Header: getHeader(obj, i),
      accessor: obj.name,
      sticky: "",
      id: i === data[0].data.length - 1 ? "lastCol" : "",
      Cell: (props: { value: string }) => numberWithCommas(props.value),
    });
  });

  return columns;
};

export const getDataForDrillDownTable = (
  data?: {
    name: string | undefined;
    id: string | undefined;
    data: ({ name: string; isSum: boolean } | { name: string; y: number })[];
  }[],
) => {
  if (!data) {
    return [];
  }

  const resData = data.map((obj) => {
    const res = {} as Record<string, number | string>;
    res["name"] = obj.name ?? "";
    let total = 0;
    obj.data.forEach((o) => {
      if ("y" in o) {
        res[o.name] = o.y;
        total += parseInt(`${o.y}`);
      }

      if ("isSum" in o) {
        res[o.name] = total;
      }
    });
    return res;
  });

  return resData;
};

export const appointmentFilterOrder = [
  {
    isParent: true,
    label: "Consumer Demographics",
    id: "consumer",
  },
  {
    cubeKey: "assigned_consumer_detail.state",
    label: "State",
    parentId: "consumer",
  },
  {
    cubeKey: "assigned_consumer_detail.city",
    label: "City",
    parentId: "consumer",
  },
  {
    cubeKey: "assigned_consumer_detail.zip",
    label: "Zip",
    parentId: "consumer",
  },
  {
    cubeKey: "assigned_consumer_detail.gender",
    label: "Gender",
    parentId: "consumer",
  },
  {
    isParent: true,
    label: "Active Roster",
    id: "active-roster",
  },
  {
    cubeKey: "roster_active.plan_state",
    label: "Plan State",
    parentId: "active-roster",
  },
  {
    cubeKey: "roster_active.payer_name",
    label: "Payer Name",
    parentId: "active-roster",
  },
  {
    cubeKey: "roster_active.market_segment",
    label: "Market Segment",
    parentId: "active-roster",
  },
  {
    cubeKey: "roster_active.market_sub_segment",
    label: "Market Sub Segment",
    parentId: "active-roster",
  },
  {
    cubeKey: "roster_active.active_flag",
    label: "Active in Roster",
    parentId: "active-roster",
  },
  {
    isParent: true,
    label: "Attribution",
    id: "attribution",
  },
  {
    cubeKey: "assigned_consumer_detail.panel_name",
    label: "Panel Name",
    parentId: "attribution",
  },
  {
    cubeKey: "assigned_consumer_detail.provider_name_npi",
    label: "Provider Name (NPI)",
    parentId: "attribution",
  },
  {
    cubeKey: "assigned_consumer_detail.provider_practice_type_name",
    label: "Provider Practice Type",
    parentId: "attribution",
  },
  {
    cubeKey: "assigned_consumer_detail.facility_name",
    label: "Facility Name",
    parentId: "attribution",
  },
  {
    cubeKey: "assigned_consumer_detail.facility_state",
    label: "Facility State",
    parentId: "attribution",
  },
  {
    cubeKey: "assigned_consumer_detail.facility_zip",
    label: "Facility Zip",
    parentId: "attribution",
  },
];
