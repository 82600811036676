import BarChart from "./BarChart";
import BulletChart from "./BulletChart";
import * as filterUtils from "./filterUtils";
import FunnelChart from "./FunnelChart";
import Gauge from "./Gauge";
import HeatMap from "./HeatMap";
import LineChart from "./LineChart";
import NumberChart from "./NumberChart";
import PieChart from "./PieChart";
import Row from "./Row";
import ScatterChart from "./ScatterChart";
import WaterfallChart from "./WaterfallChart";

type DataViz = {
  Row: typeof Row;
  BarChart: typeof BarChart;
  WaterfallChart: typeof WaterfallChart;
  NumberChart: typeof NumberChart;
  Gauge: typeof Gauge;
  LineChart: typeof LineChart;
  PieChart: typeof PieChart;
  FunnelChart: typeof FunnelChart;
  ScatterChart: typeof ScatterChart;
  HeatMap: typeof HeatMap;
  BulletChart: typeof BulletChart;
  filterUtils: typeof filterUtils;
};

const DataViz: DataViz = {
  Row,
  NumberChart,
  Gauge,
  WaterfallChart,
  BarChart,
  LineChart,
  PieChart,
  FunnelChart,
  ScatterChart,
  HeatMap,
  BulletChart,
  filterUtils,
};

export default DataViz;
