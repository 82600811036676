import React, { useEffect, useRef, useState } from "react";

import { gridSpacing } from "@lucernahealth/lucerna-health-ui";
import { styled } from "styled-components";

import { colorTheme } from "~/src/utils";

const MAX_LABEL_WIDTH = 145;

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
const FilterButtonContainer = styled.div<{ $containsValues: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  padding: ${gridSpacing[1]}px ${gridSpacing[3]}px;
  border-radius: 30px;
  border: 1px solid
    ${({ $containsValues }) =>
      colorTheme($containsValues ? "primary" : "neutralL4")};
  background: ${({ $containsValues }) =>
    colorTheme($containsValues ? "primaryL4" : "white")};
  box-shadow:
    0px 2px 4px 0px rgba(0, 0, 0, 0.08),
    0px -4px 4px 0px rgba(0, 0, 0, 0.03) inset;

  &:hover {
    background: ${({ $containsValues }) =>
      colorTheme($containsValues ? "primaryL3" : "neutralL4")};
    cursor: pointer;
  }
`;

const Label = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: ${colorTheme("neutral")};
  padding-right: ${gridSpacing[1]}px;
`;

const Icon = styled.i<{ $containsValues: boolean }>`
  color: ${colorTheme("neutral")};
  font-size: 13px;

  &:hover {
    color: ${({ $containsValues }) =>
      colorTheme($containsValues ? "danger" : "neutral")};
  }
`;

const ValueText = styled.p<{ $bigWidth: boolean }>`
  max-width: ${({ $bigWidth }) => ($bigWidth ? MAX_LABEL_WIDTH + 10 : 460)}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 18px;
`;

type FilterButtonProps = {
  label: string;
  values?: string[];
  clearValues?: () => void;
  addButton?: boolean;
  onClick?: () => void;
};

const FilterButton = ({
  label,
  values,
  clearValues,
  addButton,
  onClick,
}: FilterButtonProps) => {
  const filterButtonRef = useRef<HTMLDivElement>(null);
  const containsValues = Boolean(values && values.length > 0);
  const [bigWidth, setBigWidth] = useState(false);

  useEffect(() => {
    if (filterButtonRef.current) {
      const width = (filterButtonRef.current as HTMLDivElement).offsetWidth;
      if (width > MAX_LABEL_WIDTH && !bigWidth && values && values.length > 1) {
        setBigWidth(true);
      } else if (bigWidth && values && values.length < 2) {
        setBigWidth(false);
      }
    }
  }, [filterButtonRef.current?.offsetWidth]);

  return (
    <FilterButtonContainer $containsValues={containsValues} onClick={onClick}>
      <Label>{label}</Label>
      {containsValues && (
        <div
          ref={filterButtonRef}
          style={{ display: "flex", paddingRight: gridSpacing[2] }}
        >
          <span
            style={{
              color: colorTheme("neutralL2"),
              paddingRight: gridSpacing[1],
            }}
          >
            |
          </span>
          {bigWidth && values ? (
            <ValueText
              style={{ color: colorTheme("primary") }}
              $bigWidth={bigWidth}
            >
              Any of {values.length}
            </ValueText>
          ) : (
            <ValueText
              style={{ color: colorTheme("primary") }}
              $bigWidth={bigWidth}
            >
              {values?.join(", ")}
            </ValueText>
          )}
        </div>
      )}
      {!onClick && (
        <Icon
          data-testid="filter-button-icon"
          $containsValues={containsValues}
          onClick={
            containsValues && clearValues
              ? (e) => {
                  e.stopPropagation();
                  clearValues();
                }
              : undefined
          }
          className={`fa-solid ${
            addButton
              ? "fa-plus"
              : containsValues
                ? "fa-times"
                : "fa-chevron-down"
          }`}
        />
      )}
    </FilterButtonContainer>
  );
};

export default FilterButton;
