import React, { useContext } from "react";

import { DataViz } from "@commonComponents";

import {
  consumerMissingAddressCountQuery,
  consumerMissingStateCountQuery,
  consumerMissingZipCountQuery,
  consumerMissingAddressLine1CountQuery,
  consumerMissingDateOfBirthCountQuery,
  consumerMissingGenderCountQuery,
  consumerMissingEmailCountQuery,
  consumerMissingLanguagePreferenceCountQuery,
  consumerMissingRaceCountQuery,
  consumerMissingEthnicityCountQuery,
  consumerMissingPhoneCountQuery,
  consumerMissingCellPhoneCountQuery,
  consumerMissingWorkPhoneCountQuery,
  consumerMissingHomePhoneCountQuery,
  consumerMissingCountryOfOriginCountQuery,
  cpsDataQualityDimensions,
} from "./Queries";
import { PortalContext } from "../../Portal/Portal";
import { totalConsumerCountQuery } from "../ConsumerDemographics/Queries";
import { colorTheme } from "~/src/utils";
import { useCubeApiQuery } from "~/src/utils/tsUtils";

const CpsDataQuality = () => {
  const { filters, filterOrder } = useContext(PortalContext);

  DataViz.filterUtils.useFilterOptionsNormalized({
    dimensions: cpsDataQualityDimensions,
  });

  const {
    resultSet: totalConsumerCountResult,
    error: totalConsumerCountError,
    isLoading: totalConsumerCountLoading,
  } = useCubeApiQuery(totalConsumerCountQuery(filters, filterOrder));

  const totalConsumers = parseInt(
    `${
      totalConsumerCountResult?.rawData()[0]?.["consumer.total_consumer_count"]
    }`,
  );

  const {
    resultSet: consumerMissingAddressCountResult,
    error: consumerMissingAddressCountError,
    isLoading: consumerMissingAddressCountLoading,
  } = useCubeApiQuery(consumerMissingAddressCountQuery(filters, filterOrder));

  const {
    resultSet: consumerMissingStateCountResult,
    error: consumerMissingStateCountError,
    isLoading: consumerMissingStateCountLoading,
  } = useCubeApiQuery(consumerMissingStateCountQuery(filters, filterOrder));

  const {
    resultSet: consumerMissingZipCountResult,
    error: consumerMissingZipCountError,
    isLoading: consumerMissingZipCountLoading,
  } = useCubeApiQuery(consumerMissingZipCountQuery(filters, filterOrder));

  const {
    resultSet: consumerMissingAddressLine1CountResult,
    error: consumerMissingAddressLine1CountError,
    isLoading: consumerMissingAddressLine1CountLoading,
  } = useCubeApiQuery(
    consumerMissingAddressLine1CountQuery(filters, filterOrder),
  );

  const {
    resultSet: consumerMissingDateOfBirthCountResult,
    error: consumerMissingDateOfBirthCountError,
    isLoading: consumerMissingDateOfBirthCountLoading,
  } = useCubeApiQuery(
    consumerMissingDateOfBirthCountQuery(filters, filterOrder),
  );

  const {
    resultSet: consumerMissingGenderCountResult,
    error: consumerMissingGenderCountError,
    isLoading: consumerMissingGenderCountLoading,
  } = useCubeApiQuery(consumerMissingGenderCountQuery(filters, filterOrder));

  const {
    resultSet: consumerMissingEmailCountResult,
    error: consumerMissingEmailCountError,
    isLoading: consumerMissingEmailCountLoading,
  } = useCubeApiQuery(consumerMissingEmailCountQuery(filters, filterOrder));

  const {
    resultSet: consumerMissingLanguagePreferenceCountResult,
    error: consumerMissingLanguagePreferenceCountError,
    isLoading: consumerMissingLanguagePreferenceCountLoading,
  } = useCubeApiQuery(
    consumerMissingLanguagePreferenceCountQuery(filters, filterOrder),
  );

  const {
    resultSet: consumerMissingRaceCountResult,
    error: consumerMissingRaceCountError,
    isLoading: consumerMissingRaceCountLoading,
  } = useCubeApiQuery(consumerMissingRaceCountQuery(filters, filterOrder));

  const {
    resultSet: consumerMissingEthnicityCountResult,
    error: consumerMissingEthnicityCountError,
    isLoading: consumerMissingEthnicityCountLoading,
  } = useCubeApiQuery(consumerMissingEthnicityCountQuery(filters, filterOrder));

  const {
    resultSet: consumerMissingPhoneCountResult,
    error: consumerMissingPhoneCountError,
    isLoading: consumerMissingPhoneCountLoading,
  } = useCubeApiQuery(consumerMissingPhoneCountQuery(filters, filterOrder));

  const {
    resultSet: consumerMissingCellPhoneCountResult,
    error: consumerMissingCellPhoneCountError,
    isLoading: consumerMissingCellPhoneCountLoading,
  } = useCubeApiQuery(consumerMissingCellPhoneCountQuery(filters, filterOrder));

  const {
    resultSet: consumerMissingWorkPhoneCountResult,
    error: consumerMissingWorkPhoneCountError,
    isLoading: consumerMissingWorkPhoneCountLoading,
  } = useCubeApiQuery(consumerMissingWorkPhoneCountQuery(filters, filterOrder));

  const {
    resultSet: consumerMissingHomePhoneCountResult,
    error: consumerMissingHomePhoneCountError,
    isLoading: consumerMissingHomePhoneCountLoading,
  } = useCubeApiQuery(consumerMissingHomePhoneCountQuery(filters, filterOrder));

  const {
    resultSet: consumerMissingCountryOfOriginCountResult,
    error: consumerMissingCountryOfOriginCountError,
    isLoading: consumerMissingCountryOfOriginCountLoading,
  } = useCubeApiQuery(
    consumerMissingCountryOfOriginCountQuery(filters, filterOrder),
  );

  const numberChartProps = {
    denominator: totalConsumers,
    type: "progress" as const,
    getProgressBarColor: (progress: number, isForeground: boolean) => {
      if (progress === 0) {
        return colorTheme("successL4");
      } else if (progress >= 0.5) {
        return colorTheme(isForeground ? "danger" : "dangerL4");
      } else if (progress >= 0.25) {
        return colorTheme(isForeground ? "warning" : "warningL4");
      } else {
        return colorTheme(isForeground ? "success" : "successL4");
      }
    },
  };

  return (
    <>
      <DataViz.Row>
        <DataViz.NumberChart
          {...numberChartProps}
          title={`"Address" Missing`}
          toolTip="Consumers without an address and their proportion out of the total consumer base."
          numerator={parseInt(
            `${
              consumerMissingAddressCountResult?.rawData()[0]?.[
                "consumer.consumer_missing_address_count"
              ]
            }`,
          )}
          loading={
            consumerMissingAddressCountLoading || totalConsumerCountLoading
          }
          error={
            (consumerMissingAddressCountError || totalConsumerCountError) &&
            "Something went wrong"
          }
        />
        <DataViz.NumberChart
          {...numberChartProps}
          title={`"State Code" Missing`}
          toolTip="Consumers without a state code (in address) and their proportion out of the total consumer base."
          numerator={parseInt(
            `${
              consumerMissingStateCountResult?.rawData()[0]?.[
                "consumer.consumer_missing_state_count"
              ]
            }`,
          )}
          loading={
            consumerMissingStateCountLoading || totalConsumerCountLoading
          }
          error={
            (consumerMissingStateCountError || totalConsumerCountError) &&
            "Something went wrong"
          }
        />
        <DataViz.NumberChart
          {...numberChartProps}
          title={`"Zip Code" Missing`}
          toolTip="Consumers without a zip code (in address) and their proportion out of the total consumer base."
          numerator={parseInt(
            `${
              consumerMissingZipCountResult?.rawData()[0]?.[
                "consumer.consumer_missing_zip_count"
              ]
            }`,
          )}
          loading={consumerMissingZipCountLoading || totalConsumerCountLoading}
          error={
            (consumerMissingZipCountError || totalConsumerCountError) &&
            "Something went wrong"
          }
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.NumberChart
          {...numberChartProps}
          title={`"Address Line 1" Missing`}
          toolTip="Consumers without address line 1 and their proportion out of the total consumer base."
          numerator={parseInt(
            `${
              consumerMissingAddressLine1CountResult?.rawData()[0]?.[
                "consumer.consumer_missing_address_line_1_count"
              ]
            }`,
          )}
          loading={
            consumerMissingAddressLine1CountLoading || totalConsumerCountLoading
          }
          error={
            (consumerMissingAddressLine1CountError ||
              totalConsumerCountError) &&
            "Something went wrong"
          }
        />
        <DataViz.NumberChart
          {...numberChartProps}
          title={`"Date of Birth" Missing`}
          toolTip="Consumers without date of birth and their proportion out of the total consumer base."
          numerator={parseInt(
            `${
              consumerMissingDateOfBirthCountResult?.rawData()[0]?.[
                "consumer.consumer_missing_date_of_birth_count"
              ]
            }`,
          )}
          loading={
            consumerMissingDateOfBirthCountLoading || totalConsumerCountLoading
          }
          error={
            (consumerMissingDateOfBirthCountError || totalConsumerCountError) &&
            "Something went wrong"
          }
        />
        <DataViz.NumberChart
          {...numberChartProps}
          title={`"Gender" Missing`}
          toolTip="Consumers without gender or with gender other than male or female, and their proportion out of the total consumer base."
          numerator={parseInt(
            `${
              consumerMissingGenderCountResult?.rawData()[0]?.[
                "consumer.consumer_missing_gender_count"
              ]
            }`,
          )}
          loading={
            consumerMissingGenderCountLoading || totalConsumerCountLoading
          }
          error={
            (consumerMissingGenderCountError || totalConsumerCountError) &&
            "Something went wrong"
          }
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.NumberChart
          {...numberChartProps}
          title={`"Email" Missing`}
          toolTip="Consumers without email address and their proportion out of the total consumer base."
          numerator={parseInt(
            `${
              consumerMissingEmailCountResult?.rawData()[0]?.[
                "consumer.consumer_missing_email_count"
              ]
            }`,
          )}
          loading={
            consumerMissingEmailCountLoading || totalConsumerCountLoading
          }
          error={
            (consumerMissingEmailCountError || totalConsumerCountError) &&
            "Something went wrong"
          }
        />
        <DataViz.NumberChart
          {...numberChartProps}
          title={`"Language Preference" Missing`}
          toolTip="Consumers without language preference and their proportion out of the total consumer base."
          numerator={parseInt(
            `${
              consumerMissingLanguagePreferenceCountResult?.rawData()[0]?.[
                "consumer.consumer_missing_language_preference_count"
              ]
            }`,
          )}
          loading={
            consumerMissingLanguagePreferenceCountLoading ||
            totalConsumerCountLoading
          }
          error={
            (consumerMissingLanguagePreferenceCountError ||
              totalConsumerCountError) &&
            "Something went wrong"
          }
        />
        <DataViz.NumberChart
          {...numberChartProps}
          title={`"Race" Missing`}
          toolTip="Consumers without race and their proportion out of the total consumer base."
          numerator={parseInt(
            `${
              consumerMissingRaceCountResult?.rawData()[0]?.[
                "consumer.consumer_missing_race_count"
              ]
            }`,
          )}
          loading={consumerMissingRaceCountLoading || totalConsumerCountLoading}
          error={
            (consumerMissingRaceCountError || totalConsumerCountError) &&
            "Something went wrong"
          }
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.NumberChart
          {...numberChartProps}
          title={`"Ethnicity" Missing`}
          toolTip="Consumers without ethnicity and their proportion out of the total consumer base."
          numerator={parseInt(
            `${
              consumerMissingEthnicityCountResult?.rawData()[0]?.[
                "consumer.consumer_missing_ethnicity_count"
              ]
            }`,
          )}
          loading={
            consumerMissingEthnicityCountLoading || totalConsumerCountLoading
          }
          error={
            (consumerMissingEthnicityCountError || totalConsumerCountError) &&
            "Something went wrong"
          }
        />
        <DataViz.NumberChart
          {...numberChartProps}
          title={`"Phone" Missing`}
          toolTip="Consumers without any phone number and their proportion out of the total consumer base."
          numerator={parseInt(
            `${
              consumerMissingPhoneCountResult?.rawData()[0]?.[
                "consumer.consumer_missing_phone_count"
              ]
            }`,
          )}
          loading={
            consumerMissingPhoneCountLoading || totalConsumerCountLoading
          }
          error={
            (consumerMissingPhoneCountError || totalConsumerCountError) &&
            "Something went wrong"
          }
        />
        <DataViz.NumberChart
          {...numberChartProps}
          title={`"Cell Phone" Missing`}
          toolTip="Consumers without a cell phone number and their proportion out of the total consumer base."
          numerator={parseInt(
            `${
              consumerMissingCellPhoneCountResult?.rawData()[0]?.[
                "consumer.consumer_missing_cell_phone_count"
              ]
            }`,
          )}
          loading={
            consumerMissingCellPhoneCountLoading || totalConsumerCountLoading
          }
          error={
            (consumerMissingCellPhoneCountError || totalConsumerCountError) &&
            "Something went wrong"
          }
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.NumberChart
          {...numberChartProps}
          title={`"Work Phone" Missing`}
          toolTip="Consumers without a work phone number and their proportion out of the total consumer base."
          numerator={parseInt(
            `${
              consumerMissingWorkPhoneCountResult?.rawData()[0]?.[
                "consumer.consumer_missing_work_phone_count"
              ]
            }`,
          )}
          loading={
            consumerMissingWorkPhoneCountLoading || totalConsumerCountLoading
          }
          error={
            (consumerMissingWorkPhoneCountError || totalConsumerCountError) &&
            "Something went wrong"
          }
        />
        <DataViz.NumberChart
          {...numberChartProps}
          title={`"Home Phone" Missing`}
          toolTip="Consumers without a home phone number and their proportion out of the total consumer base."
          numerator={parseInt(
            `${
              consumerMissingHomePhoneCountResult?.rawData()[0]?.[
                "consumer.consumer_missing_home_phone_count"
              ]
            }`,
          )}
          loading={
            consumerMissingHomePhoneCountLoading || totalConsumerCountLoading
          }
          error={
            (consumerMissingHomePhoneCountError || totalConsumerCountError) &&
            "Something went wrong"
          }
        />
        <DataViz.NumberChart
          {...numberChartProps}
          title={`"Country of Origin" Missing`}
          toolTip="Consumers without country of origin and their proportion out of the total consumer base."
          numerator={parseInt(
            `${
              consumerMissingCountryOfOriginCountResult?.rawData()[0]?.[
                "consumer.consumer_missing_country_of_origin_count"
              ]
            }`,
          )}
          loading={
            consumerMissingCountryOfOriginCountLoading ||
            totalConsumerCountLoading
          }
          error={
            (consumerMissingCountryOfOriginCountError ||
              totalConsumerCountError) &&
            "Something went wrong"
          }
        />
      </DataViz.Row>
    </>
  );
};
export default CpsDataQuality;
