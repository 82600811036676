import React, { useEffect, useState } from "react";

import { SweetAlert } from "@commonComponents";
import {
  Button,
  Collapse,
  DropDownV2,
  FilterButton,
  gridSpacing,
  SearchText,
  SlideOutPanel,
  StatusPill,
  Tabs,
} from "@lucernahealth/lucerna-health-ui";
import axios from "axios";
import PropTypes from "prop-types";
import queryString from "query-string";
import { connect } from "react-redux";
import { styled } from "styled-components";

import { capitalizeFirstLetters, colorTheme } from "@utils";

import { setAdvancedFilterRefresh } from "@actions/main";

import Options from "./Options";
import SaveFilterModal from "./SaveFilterModal";

const RankCircle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${colorTheme("neutralL4")};
  margin-right: ${gridSpacing[3]}px;
  margin-top: 3px;
`;

const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${gridSpacing[3]}px;

  border-radius: 5px;

  &:hover {
    cursor: pointer;
    background: ${colorTheme("neutralL6")};
  }
`;

const FilterBody = styled.div`
  padding: ${gridSpacing[3]}px;
`;

const FilterContainer = styled.div``;

const AdvancedFilters = ({
  app,
  urlPattern,
  setQuery,
  primaryFilters,
  hideAllFilters,
  quickFilters,
  hiddenFilters,
  hiddenGroups,
  persistentBaseFilters,
  baseFilters,
  noFilters = false,
  refresh,
  setAdvancedFilterRefresh,
}) => {
  const [clearedFilters, setFiltersCleared] = useState(false);

  const addBaseFilters = (
    queryParams = "",
    _clearedFilters = clearedFilters,
  ) => {
    if (persistentBaseFilters) {
      if (!queryParams) {
        queryParams = "?";
      }
      Object.keys(persistentBaseFilters).forEach((filterKey, i) => {
        queryParams += `${
          queryParams === "?" && i === 0 ? "" : "&"
        }${filterKey}=${persistentBaseFilters[filterKey]}`;
      });
    }

    if (
      !_clearedFilters &&
      baseFilters &&
      !queryParams.startsWith("?filter=")
    ) {
      if (!queryParams) {
        queryParams = "?";
      }
      Object.keys(baseFilters).forEach((filterKey, i) => {
        queryParams += `${
          queryParams === "?" && i === 0 ? "" : "&"
        }${filterKey}=${baseFilters[filterKey]}`;
      });
    }

    return queryParams;
  };

  const getQueryParams = (_clearedFilters) => {
    let queryParams = window.location.search;
    if (queryParams.startsWith("?version")) {
      queryParams = "";
    }

    queryParams = addBaseFilters(queryParams, _clearedFilters);

    return queryParams;
  };

  const buildFilters = (url) => {
    let _filter = {};
    url.split("&").map((obj) => {
      let keyValue = obj.split("=");
      let key = keyValue[0].replace("?", "");

      if (key) {
        if (_filter[key]) {
          _filter[key].push(keyValue[1].replaceAll("%20", " "));
        } else {
          _filter[key] = [keyValue[1].replaceAll("%20", " ")];
        }
      }
    });

    return _filter;
  };

  const buildQuery = (_filters) => {
    let query = "?";
    Object.keys(_filters).forEach((key) => {
      if (_filters[key]?.length > 0) {
        if (Array.isArray(_filters[key])) {
          _filters[key].forEach((filter) => {
            query += `${key}=${filter}&`;
          });
        } else {
          query += `${key}=${_filters[key]}&`;
        }
      }
    });

    return query.substring(0, query.length - 1);
  };

  const [filterData, setFilterData] = useState([]);
  const [openFilters, setOpenFilters] = useState([]);
  const [filters, setFilters] = useState(buildFilters(getQueryParams()));
  const [filterQuery, setFilterQuery] = useState(
    buildQuery(buildFilters(getQueryParams())),
  );
  const [slideOutOpened, setSlideOutOpened] = useState(false);
  const [saveFilterModalOpened, setSaveFilterModalOpened] = useState(false);
  const [savedFilters, setSavedFilters] = useState([]);
  const [tab, setTab] = useState("filters");
  const [model, setModel] = useState("");
  const [activeSavedFilter, setActiveSavedFilter] = useState(null);
  const [savedFiltersOpened, setSavedFiltersOpened] = useState([]);
  const [editingFilter, setEditingFilter] = useState(null);
  const [editSavedFilterMetadata, setEditSavedFilterMetadata] = useState(null);
  const [primarySearchFilter, setPrimarySearchFilter] = useState("");
  const [filterGroups, setFilterGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const clearFilters = () => {
    window.history.replaceState(null, null, window.location.pathname);
    setFiltersCleared(true);
    setQuery(getQueryParams(true));
    setSlideOutOpened(false);
    setFilters(buildFilters(getQueryParams(true)));
    setActiveSavedFilter(null);
    setPrimarySearchFilter("");
  };

  const loadFilters = async () => {
    let loadingTimeout = null;
    let isRequestCompleted = false;

    const startLoading = () => {
      loadingTimeout = setTimeout(() => {
        if (!isRequestCompleted) {
          setLoading(true);
        }
      }, 250);
    };

    const stopLoading = () => {
      if (loadingTimeout) {
        clearTimeout(loadingTimeout);
      }
      setLoading(false);
    };

    if (noFilters) {
      stopLoading();
      return;
    }

    startLoading();
    const params = {};
    if (hiddenFilters) {
      params.excluded_filter = hiddenFilters;
    }
    if (hiddenGroups) {
      params.excluded_group = hiddenGroups;
    }
    const advFilterData = await axios
      .get(`${app}/${urlPattern}/advanced_filters`, {
        params,
        paramsSerializer: (params) =>
          queryString.stringify(params, {
            arrayFormat: "index",
          }),
      })
      .then((res) => res.data.result)
      .catch((err) => {
        console.error(err);
      });

    let newFilters = buildFilters(getQueryParams());

    const searchFilters = await axios
      .get("search/filters")
      .then((res) => res.data.result)
      .catch((err) => {
        console.error(err);
      });

    isRequestCompleted = true;

    setFilterData(advFilterData.filters);
    setModel(advFilterData.model);
    setFilterGroups([
      ...new Set(advFilterData.filters.map((filter) => filter.group)),
    ]);

    setSavedFilters(searchFilters);
    if (newFilters["filter"]) {
      const slug = newFilters["filter"][0];
      setActiveSavedFilter(slug);

      const foundFilter = searchFilters.find((filter) => filter.slug === slug);

      if (foundFilter) {
        newFilters = buildFilters(foundFilter.query);
      }
    }

    setFilters(newFilters);
    setLoading(false);
  };

  useEffect(() => {
    loadFilters();
    // Hack to allow the list page to load in the background when a versioned detail page is the landing spot
    setQuery(getQueryParams());
  }, []);

  useEffect(() => {
    if (refresh) {
      setQuery(getQueryParams());
      setAdvancedFilterRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    setFilterQuery(buildQuery(filters));
  }, [filters]);

  useEffect(() => {
    if (!slideOutOpened) {
      setOpenFilters([]);
      setSavedFiltersOpened([]);
      setTab("filters");

      if (editingFilter) {
        setEditingFilter(null);
        setActiveSavedFilter(null);
        setFilters({});
      }
    }
  }, [slideOutOpened]);

  const filterOutHiddenAndBaseFilters = (query) => {
    const queryObject = queryString.parse(query);
    const queryKeys = Object.keys(queryObject);
    const filteredQueryKeys = queryKeys.filter(
      (key) =>
        !persistentBaseFilters ||
        !Object.keys(persistentBaseFilters).includes(key),
    );

    if (filteredQueryKeys.length === 0) return "";

    const filteredQueryObject = {};
    filteredQueryKeys.forEach((key) => {
      filteredQueryObject[key] = queryObject[key];
    });

    return queryString.stringify(filteredQueryObject);
  };

  const filterLength =
    Object.keys(filters).filter(
      (key) => filters[key]?.length && !hiddenFilters?.includes(key),
    ).length || 0;

  const renderFooter = () => {
    if (tab !== "filters") return null;
    else if (editingFilter) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            padding: `0 ${gridSpacing[5]}px`,
          }}
        >
          <Button
            mr
            text="Cancel"
            type="secondary"
            onClick={() => {
              setEditingFilter(null);
              setActiveSavedFilter(null);
              setTab("savedFilters");
              setFilters({});
            }}
          />
          <Button
            text="Save"
            onClick={() => {
              axios
                .put(`search/filters/${editingFilter.uuid}`, {
                  ...editingFilter,
                  query: filterQuery,
                })
                .then((res) => {
                  window.history.pushState(
                    null,
                    null,
                    `?filter=${res.data.result.slug}`,
                  );
                  setActiveSavedFilter(res.data.result.slug);
                  setQuery(addBaseFilters(filterQuery));
                  setEditingFilter(null);
                  setSlideOutOpened(false);
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          />
        </div>
      );
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          padding: `0 ${gridSpacing[5]}px`,
        }}
      >
        <Button
          text="Save Filter"
          type="secondary"
          onClick={() => {
            setSaveFilterModalOpened(true);
            setEditSavedFilterMetadata(null);
          }}
          disabled={filterLength === 0}
        />
        <div style={{ display: "flex" }}>
          <Button
            text="Clear all"
            type="link"
            noUnderline
            onClick={clearFilters}
          />
          <Button
            text="Apply"
            onClick={() => {
              window.history.pushState(null, null, filterQuery);
              setQuery(filterQuery);
              setActiveSavedFilter(null);
              setSlideOutOpened(false);
              setPrimarySearchFilter(filters.search);
            }}
          />
        </div>
      </div>
    );
  };

  const getAmount = (obj) => {
    let amount = 1;
    if (
      filters?.[`${obj.identifier}${obj?.lt_suffix}`] ||
      filters?.[`${obj.identifier}${obj?.gt_suffix}`]
    ) {
      if (
        filters?.[`${obj.identifier}${obj?.lt_suffix}`] &&
        filters?.[`${obj.identifier}${obj?.gt_suffix}`]
      ) {
        amount = 2;
      }
    } else if (filters[obj.identifier] && filters[obj.identifier].length > 0) {
      if (Array.isArray(filters[obj.identifier])) {
        amount = filters[obj.identifier].length;
      }
    } else {
      return null;
    }

    return amount;
  };

  const renderRankCircle = (obj) => {
    const amount = getAmount(obj);
    if (!amount) return null;

    return (
      <RankCircle
        style={{
          marginLeft: gridSpacing[2],
          background: colorTheme("primaryL3"),
        }}
      >
        {amount}
      </RankCircle>
    );
  };

  const renderSelectedText = (obj) => {
    const amount = getAmount(obj);
    if (!amount) return null;
    let text = `(${amount}) selected`;
    const objOptions = obj.options;
    const objFilters = filters[obj.identifier];
    if (!objOptions || !objFilters) return null;

    if (Array.isArray(objFilters)) {
      if (objFilters.length > 0) {
        text = `(${objFilters.length}/${objOptions.length}) selected`;
      } else {
        return `(${objOptions.length})`;
      }
    }

    return (
      <span
        style={{
          fontSize: 12,
          color: colorTheme("neutralL2"),
          marginRight: gridSpacing[2],
        }}
      >
        {text}
      </span>
    );
  };

  return (
    <>
      <SaveFilterModal
        app={app}
        model={model}
        query={filterOutHiddenAndBaseFilters(filterQuery)}
        isOpened={saveFilterModalOpened}
        close={() => setSaveFilterModalOpened(false)}
        filters={savedFilters}
        incomingData={editSavedFilterMetadata}
        updateFilter={(filter) => {
          setSavedFilters(
            savedFilters.map((obj) => {
              if (obj.uuid === filter.uuid) {
                return filter;
              }

              return obj;
            }),
          );
          setSaveFilterModalOpened(false);
        }}
        addFilter={(newFilter) => {
          let _filters = buildFilters(addBaseFilters(newFilter.query));

          setSavedFilters([...savedFilters, newFilter]);
          setSaveFilterModalOpened(false);
          setFilters(_filters);
          setQuery(addBaseFilters(newFilter.query));
          setActiveSavedFilter(newFilter.slug);
          setSlideOutOpened(false);
          setPrimarySearchFilter(_filters.search);
        }}
      />
      <SlideOutPanel
        size={2}
        isOpened={slideOutOpened}
        setIsOpened={setSlideOutOpened}
        title={
          editingFilter ? `Editing Filter ${editingFilter.name}` : "All Filters"
        }
        style={{ padding: 0 }}
        footerContent={renderFooter()}
      >
        <Tabs
          currentTab={tab}
          onSelect={setTab}
          tabStyle={{ marginBottom: 0 }}
          style={{ paddingLeft: gridSpacing[3], paddingRight: gridSpacing[3] }}
        >
          <Tabs.Content tabKey="filters" title="Filters">
            <div style={{ overflow: "auto", height: "calc(100vh - 230px)" }}>
              <SearchText
                placeholder="Search with keyword or query (e.g. name:beth)"
                fixedWidth
                value={filters.search || ""}
                onChange={(e) =>
                  setFilters({ ...filters, search: e.target.value })
                }
                style={{ flexGrow: 1, width: "99%", marginTop: gridSpacing[2] }}
                mb={2}
              />
              {filterGroups.map((group) => (
                <>
                  <h4 style={{ marginTop: gridSpacing[7] }}>
                    {group === null
                      ? `${capitalizeFirstLetters(
                          model.replaceAll("_", " "),
                        )} Attributes`
                      : group}
                  </h4>
                  {filterData
                    .filter((data) => data.group === group)
                    .map((obj) => {
                      let isOpened = openFilters.includes(obj.identifier);
                      return (
                        <FilterContainer key={obj.identifier}>
                          <FilterHeader
                            onClick={() => {
                              if (isOpened) {
                                setOpenFilters((filters) =>
                                  filters.filter((id) => id !== obj.identifier),
                                );
                              } else {
                                setOpenFilters((filters) => [
                                  ...filters,
                                  obj.identifier,
                                ]);
                              }
                            }}
                            style={{
                              borderBottom: `1px solid ${colorTheme(
                                "neutralL4",
                              )}`,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {obj.name}
                              {renderRankCircle(obj)}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {renderSelectedText(obj)}
                              <i
                                className={`fa-solid fa-chevron-${
                                  isOpened ? "down" : "right"
                                }`}
                              />
                            </div>
                          </FilterHeader>
                          <Collapse isOpened={isOpened}>
                            {isOpened && (
                              <FilterBody>
                                <Options
                                  {...obj}
                                  filters={filters[obj.identifier] || []}
                                  setFilters={(e) =>
                                    setFilters({
                                      ...filters,
                                      [obj.identifier]: e,
                                    })
                                  }
                                  values={filters}
                                  setValue={(key, value) =>
                                    setFilters((filters) => {
                                      return {
                                        ...filters,
                                        [key]: value,
                                      };
                                    })
                                  }
                                />
                              </FilterBody>
                            )}
                          </Collapse>
                        </FilterContainer>
                      );
                    })}
                </>
              ))}
            </div>
          </Tabs.Content>
          <Tabs.Content tabKey="savedFilters" title="Saved Filters">
            <div>
              {savedFilters.filter(
                (filter) => filter.app === app && filter.model === model,
              ).length > 0 ? (
                savedFilters
                  .filter(
                    (filter) => filter.app === app && filter.model === model,
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((filter) => {
                    let isOpened = savedFiltersOpened.includes(filter.slug);
                    let filterObject = buildFilters(filter.query);
                    return (
                      <div key={filter.uuid}>
                        <FilterHeader style={{ padding: 0 }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              padding: gridSpacing[3],
                            }}
                            onClick={() => {
                              if (isOpened) {
                                setSavedFiltersOpened((filters) =>
                                  filters.filter((id) => id !== filter.slug),
                                );
                              } else {
                                setSavedFiltersOpened((filters) => [
                                  ...filters,
                                  filter.slug,
                                ]);
                              }
                            }}
                          >
                            <i
                              className={`fa-solid fa-chevron-${
                                isOpened ? "down" : "right"
                              }`}
                              style={{ marginRight: gridSpacing[2] }}
                            />
                            {filter.name}
                            {filter.public && (
                              <StatusPill ml={3} text="Public" />
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: gridSpacing[3],
                            }}
                          >
                            <Button
                              small
                              mr={3}
                              toggled={activeSavedFilter === filter.slug}
                              text={
                                activeSavedFilter === filter.slug
                                  ? "Unapply"
                                  : "Apply"
                              }
                              type="toggle"
                              onClick={() => {
                                if (activeSavedFilter !== filter.slug) {
                                  window.history.pushState(
                                    null,
                                    null,
                                    `?filter=${filter.slug}`,
                                  );
                                  setFilters(
                                    buildFilters(
                                      addBaseFilters(`?${filter.query}`),
                                    ),
                                  );
                                  setQuery(addBaseFilters(`?${filter.query}`));
                                  setActiveSavedFilter(filter.slug);
                                } else {
                                  setFilters({});
                                  setQuery(addBaseFilters());
                                  setActiveSavedFilter(null);
                                }

                                setSlideOutOpened(false);
                              }}
                            />
                            <DropDownV2 direction="left">
                              <DropDownV2.Toggle>
                                <i className="fa-solid fa-ellipsis" />
                              </DropDownV2.Toggle>
                              <DropDownV2.Menu>
                                <DropDownV2.Item
                                  onClick={() => {
                                    setEditSavedFilterMetadata(filter);
                                    setSaveFilterModalOpened(true);
                                  }}
                                >
                                  Edit
                                </DropDownV2.Item>
                                <DropDownV2.Item
                                  onClick={() => {
                                    setFilters(buildFilters(filter.query));
                                    setEditingFilter(filter);
                                    setTab("filters");
                                  }}
                                >
                                  Edit Filters
                                </DropDownV2.Item>
                                <DropDownV2.Item
                                  onClick={() => {
                                    SweetAlert({
                                      title: "Are you sure?",
                                      text: `You are about to delete the filter ${filter.name}`,

                                      success: () => {
                                        axios
                                          .delete(
                                            `search/filters/${filter.uuid}`,
                                          )
                                          .then(() => {
                                            setSavedFilters(
                                              savedFilters.filter(
                                                (obj) =>
                                                  obj.uuid !== filter.uuid,
                                              ),
                                            );
                                          })
                                          .catch((err) => {
                                            console.error(err);
                                          });
                                      },
                                      showCancelButton: true,
                                      isWarning: true,
                                    });
                                  }}
                                >
                                  Delete
                                </DropDownV2.Item>
                              </DropDownV2.Menu>
                            </DropDownV2>
                          </div>
                        </FilterHeader>
                        <Collapse isOpened={isOpened}>
                          <FilterBody>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: gridSpacing[3],
                              }}
                            >
                              <div>
                                <b style={{}}>{filter.name}</b>
                                <p
                                  style={{
                                    fontSize: 14,
                                    color: colorTheme("neutralL1"),
                                  }}
                                >
                                  {filter.description}
                                </p>
                              </div>
                              <Button
                                small
                                text="Edit Filters"
                                type="secondary"
                                onClick={() => {
                                  setFilters(buildFilters(filter.query));
                                  setEditingFilter(filter);
                                  setTab("filters");
                                }}
                              />
                            </div>
                            {Object.keys(filterObject).map((key) => (
                              <div
                                key={key}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  paddingTop: gridSpacing[3],
                                  paddingBottom: gridSpacing[3],
                                  borderBottom: `1px solid ${colorTheme(
                                    "neutralL4",
                                  )}`,
                                }}
                              >
                                <b style={{ textTransform: "capitalize" }}>
                                  {key.replaceAll("_", " ")}:
                                </b>
                                <div
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  {filterObject[key].map((filterValue) => {
                                    let filterOption = filterData.find(
                                      (obj) =>
                                        obj.identifier === key && obj.options,
                                    );
                                    let value = filterValue;
                                    if (filterOption) {
                                      let foundIdentifier =
                                        filterOption.options.find(
                                          (obj) =>
                                            obj.identifier === filterValue,
                                        );
                                      if (foundIdentifier) {
                                        value = foundIdentifier.name;
                                      }
                                    }
                                    return (
                                      <StatusPill
                                        mt={1}
                                        ml={2}
                                        key={filterValue}
                                        text={value}
                                        type="default"
                                      />
                                    );
                                  })}
                                </div>
                              </div>
                            ))}
                          </FilterBody>
                        </Collapse>
                      </div>
                    );
                  })
              ) : (
                <div style={{ paddingTop: gridSpacing[3] }}>
                  No filters saved
                </div>
              )}
            </div>
          </Tabs.Content>
        </Tabs>
      </SlideOutPanel>
      <div style={{ display: "flex" }}>
        <SearchText
          searchButton
          value={primarySearchFilter}
          onChange={(e) => {
            setPrimarySearchFilter(e.target.value);
            if (!e.target.value) {
              let _filters = { ...filters, search: "" };
              setFilters(_filters);

              const query = buildQuery(_filters);
              setQuery(query);
              if (query) {
                window.history.pushState(null, null, query);
              } else {
                window.history.replaceState(
                  null,
                  null,
                  window.location.pathname,
                );
              }
            }
          }}
          onEnter={() => {
            let _filters = { ...filters, search: primarySearchFilter };
            setFilters(_filters);
            const query = buildQuery(_filters);
            setQuery(query);

            if (query) {
              window.history.pushState(null, null, query);
            } else {
              window.history.replaceState(null, null, window.location.pathname);
            }
          }}
          style={{ marginRight: gridSpacing[3], width: loading && 160 }}
          placeholder={loading && "Loading Filters..."}
        />
        {primaryFilters?.map((identifier) => {
          let filter = filterData.find((obj) => obj.identifier === identifier);

          if (filter && filter.type === "MULTI_OPTION") {
            let filterOptions = filter.options.map((option) => {
              return {
                label: option.name,
                value: option.identifier,
              };
            });

            return (
              <FilterButton
                isMulti
                formatLabel={false}
                key={identifier}
                options={filterOptions}
                text={filter.name}
                value={
                  filters[identifier]
                    ? filters[identifier].map((obj) =>
                        filterOptions.find((option) => option.value === obj),
                      )
                    : []
                }
                setValue={(e) => {
                  const _newFilters = { ...filters };
                  if (e.length === 0) {
                    delete _newFilters[identifier];
                  } else {
                    _newFilters[identifier] = e.map((obj) => obj?.value || obj);
                  }

                  setFilters(_newFilters);
                  const query = buildQuery(_newFilters);
                  setQuery(query);

                  if (query) {
                    window.history.pushState(null, null, query);
                  } else {
                    window.history.replaceState(
                      null,
                      null,
                      window.location.pathname,
                    );
                  }
                }}
              />
            );
          }
        })}
        {quickFilters?.map((filter) => (
          <Button
            mr={2}
            key={filter.name}
            text={filter.name}
            type="secondary"
            onClick={() => {
              const _filters = { ...filters, ...filter.filters };
              setFilters(_filters);
              const filterQuery = buildQuery(_filters);
              setQuery(filterQuery);
            }}
          />
        ))}
        {!hideAllFilters && filterData.length > 0 && (
          <Button
            ml={primaryFilters?.length > 0}
            text={activeSavedFilter || "All Filters"}
            toggled={filterLength > 0}
            type={filterLength > 0 ? "toggle" : "secondary"}
            count={filterLength > 0 ? filterLength : null}
            icon="fa-bars-filter"
            onClick={() => setSlideOutOpened(true)}
          />
        )}
        {filterData.length > 0 && filterLength > 0 && (
          <Button
            noUnderline
            ml={1}
            text="Clear all"
            type="link"
            onClick={clearFilters}
          />
        )}
      </div>
    </>
  );
};

AdvancedFilters.propTypes = {
  app: PropTypes.string,
  urlPattern: PropTypes.string,
  setQuery: PropTypes.func,
  primaryFilters: PropTypes.array,
  hiddenFilters: PropTypes.object,
  hiddenGroups: PropTypes.object,
  persistentBaseFilters: PropTypes.object,
  baseFilters: PropTypes.object,
  hideAllFilters: PropTypes.bool,
  quickFilters: PropTypes.array,
  noFilters: PropTypes.bool,
  refresh: PropTypes.bool,
  setAdvancedFilterRefresh: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    refresh: state?.main?.advancedFilters?.refresh,
  };
};

export default connect(mapStateToProps, { setAdvancedFilterRefresh })(
  AdvancedFilters,
);
