const initialState = {
  isFetching: true,
  error: false,
  result: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "DATA_TEST_FETCH_ALL": {
      return Object.assign({}, state, {
        isFetching: action.payload.isFetching,
        error: action.payload.error,
        result: action.payload.result,
      });
    }
    case "DATA_TEST_ADD": {
      return Object.assign({}, state, {
        result: [...state.result, action.payload.data],
      });
    }
    case "DATA_TEST_UPDATE": {
      const tests = state.result.map((test) => {
        if (test.uuid === action.payload.data.uuid) {
          return action.payload.data;
        }
        return test;
      });

      return Object.assign({}, state, {
        result: tests,
      });
    }
    case "DATA_TEST_DELETE": {
      const tests = state.result.filter(
        (test) => test.uuid !== action.payload.testUuid,
      );
      return Object.assign({}, state, {
        result: tests,
      });
    }

    default:
      return state;
  }
}
