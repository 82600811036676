import React from "react";

import {
  GraphicCallout,
  Loading,
  Table,
} from "@lucernahealth/lucerna-health-ui";

import Header from "./Header";
import type { FilterProps } from "./helpers";
import { ContentWrapper, ObjectsContainer } from "./helpers";
import DashboardObject from "../DashboardObject";
import type { QuicksightProps } from "../helpers";
import { quicksightDashboardColumns } from "../tableColumns";
import type { UserFavorites } from "~/src/types";

interface QuicksightDashboardsTabProps {
  filters: FilterProps;
  setFilters: (filters: FilterProps) => void;
  tableView: boolean;
  setTableView: (value: boolean) => void;
  allQuicksightDashboardsLoading: boolean;
  allQuicksightDashboards: QuicksightProps[];
  favoritesData: UserFavorites[];
  getFavorites: () => void;
}

const QuicksightDashboardsTab = ({
  filters,
  setFilters,
  tableView,
  setTableView,
  allQuicksightDashboardsLoading,
  allQuicksightDashboards,
  favoritesData,
  getFavorites,
}: QuicksightDashboardsTabProps) => {
  const filteredAllQuicksightDashboards = allQuicksightDashboards
    .filter((d) => d.name.toLowerCase().includes(filters.search.toLowerCase()))
    .map(
      (d) =>
        ({
          name: d.name,
          path: d.path,
          dashboard_type: "quicksight",
          uuid: "",
          short_description: "",
          embedded_details: {
            lucerna_managed: false,
          },
        }) as QuicksightProps,
    );

  if (allQuicksightDashboardsLoading) {
    return <Loading center />;
  }

  if (allQuicksightDashboards.length === 0) {
    return (
      <GraphicCallout
        header="No unembedded Quicksight dashboards"
        subheader="All Quicksight dashboards have been embedded. Go to Dashboard Settings to manage all emebedded dashboards."
      />
    );
  }

  return (
    <>
      <Header
        includeFilters={false}
        filters={filters}
        setFilters={setFilters}
        tableView={tableView}
        setTableView={setTableView}
        subjects={[]}
      />
      {filteredAllQuicksightDashboards.length === 0 ? (
        <GraphicCallout empty="search" />
      ) : (
        <ContentWrapper>
          <ObjectsContainer>
            {tableView ? (
              <Table
                columns={quicksightDashboardColumns()}
                data={filteredAllQuicksightDashboards}
                style={{ width: "100%" }}
              />
            ) : (
              <ObjectsContainer>
                {filteredAllQuicksightDashboards.map((_dashboard) => (
                  <DashboardObject
                    dashboard={_dashboard as QuicksightProps}
                    key={_dashboard?.name}
                    favorites={favoritesData}
                    getFavorites={getFavorites}
                  />
                ))}
              </ObjectsContainer>
            )}
          </ObjectsContainer>
        </ContentWrapper>
      )}
    </>
  );
};

export default QuicksightDashboardsTab;
