import { DataViz } from "@commonComponents";

import type { DashboardAdvancedFilterProps } from "../../Portal/Portal";
import type { AdvFilterProps } from "~/src/utils/tsUtils";

export const totalConsumerCountQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["consumer.total_consumer_count"],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const genderBreakdownQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["consumer.total_consumer_count"],
    dimensions: ["consumer.gender"],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const languageBreakdownQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["consumer.total_consumer_count"],
    dimensions: ["consumer.language_preference"],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const raceBreakdownQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["consumer.total_consumer_count"],
    dimensions: ["consumer.race"],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const ethnicityBreakdownQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["consumer.total_consumer_count"],
    dimensions: ["consumer.ethnicity"],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const consumerCountByStateQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["consumer.total_consumer_count"],
    dimensions: ["consumer.enrich_state"],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const top10CitiesQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["consumer.total_consumer_count"],
    dimensions: ["consumer.enrich_city"],
    order: {
      "consumer.total_consumer_count": "desc",
    },
    limit: 10,
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const top10CitiesByStateQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["consumer.total_consumer_count"],
    dimensions: ["consumer.enrich_state", "consumer.enrich_city"],
    order: [["consumer.total_consumer_count", "desc"]],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const age5YearSpanQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["consumer.total_consumer_count"],
    dimensions: ["consumer.age_bands_5_years", "consumer.gender"],
    order: [["consumer.total_consumer_count", "desc"]],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const consumerDemographicsDimensions = [
  "consumer.enrich_state",
  "consumer.enrich_city",
  "consumer.enrich_zip",
  "consumer.gender",
  "consumer.age_bands_5_years",
  "consumer.race",
  "consumer.ethnicity",
  "consumer_attribution.panel_name",
];
