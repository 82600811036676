import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

interface ProviderDirectoryCompanyState {
  fetchList: boolean;
}

const initialState: ProviderDirectoryCompanyState = {
  fetchList: false,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: (create) => {
    return {
      setCompanyFetchListRedux: create.reducer(
        (state, action: PayloadAction<boolean>) => {
          state.fetchList = action.payload;
        },
      ),
    };
  },
});

export const { setCompanyFetchListRedux } = companySlice.actions;

export default companySlice.reducer;
