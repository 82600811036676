const baseUrl = "/provider-360";

export default {
  baseUrl,
  ATTRIBUTION_PANELS: `${baseUrl}/attribution/panels`,
  ATTRIBUTION_ASSIGNMENT_OPERATIONS: `${baseUrl}/attribution/assignment-operations`,
  ATTRIBUTION_CAPACITY_OPERATIONS: `${baseUrl}/attribution/capacity-operations`,
  ATTRIBUTION_METRICS: `${baseUrl}/attribution/metrics`,
  ATTRIBUTION_METRIC_GROUPS: `${baseUrl}/attribution/metric-groups`,
  PROVIDER_DIRECTORY_ORGANIZATIONS: `${baseUrl}/provider-directory/organizations`,
  PROVIDER_DIRECTORY_COMPANIES: `${baseUrl}/provider-directory/companies`,
  PROVIDER_DIRECTORY_FACILITIES: `${baseUrl}/provider-directory/facilities`,
  PROVIDER_DIRECTORY_PROVIDERS: `${baseUrl}/provider-directory/providers`,
  PROVIDER_DIRECTORY_CARE_TEAMS: `${baseUrl}/provider-directory/care-teams`,
  PROVIDER_DIRECTORY_HEALTH_PLANS: `${baseUrl}/provider-directory/health-plans`,
  INSIGHTS_ATTRIBUTION: `${baseUrl}/insights/attribution`,
  INSIGHTS_ATTRIBUTION_CAPACITY_ANALYSIS: `${baseUrl}/insights/attribution/capacity-analysis`,
  CONFIGURATION_DIRECTORY_OPTIONS: `${baseUrl}/configuration/directory-options`,
  CONFIGURATION_ACTIVITY_STREAM: `${baseUrl}/configuration/activity-stream`,
};
