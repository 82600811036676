import React from "react";

import PropTypes from "prop-types";
import { styled } from "styled-components";

import { colorTheme } from "@utils";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  height: auto;
  padding: 24px;
  overflow: hidden;
  outline: none;
  position: relative;
  white-space: nowrap;
  font-weight: 400;

  &:hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    background-color: ${colorTheme("black")};
  }

  &:after {
    border-top-width: 1px;
    border-top-style: solid;
    content: "";
    display: inline-block;
    height: 0;
    position: absolute;
    top: 36px;
    border-color: ${colorTheme("neutralL3")};
    width: calc(100%);
    border-width: 1px;
    left: calc(50% + 15px);
    top: 36%;
  }

  &:before {
    border-top-width: 1px;
    border-top-style: solid;
    content: "";
    display: inline-block;
    height: 0;
    position: absolute;
    top: 36px;
    border-color: ${colorTheme("neutralL3")};
    width: calc(100%);
    border-width: 1px;
    right: calc(50% + 15px);
    top: 36%;
  }

  &:first-child {
    &:before {
      border: none;
    }
  }

  &:last-child {
    &:after {
      border: none;
    }
  }
`;

const StepState = styled.div`
  background-color: ${(props) =>
    props.selected
      ? colorTheme("primary")
      : props.completed
        ? colorTheme("success")
        : colorTheme("neutralL2")};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  margin-left: 0;
  color: ${colorTheme("white")};
  border-radius: 50%;
  height: 24px;
  width: 24px;
  position: relative;
  margin-bottom: 5px;
`;

const InnerCircle = styled.div`
  background: ${colorTheme("white")};
  height: 14px;
  width: 14px;
  border-radius: 50%;
`;

const Header = ({ children, onClick, selected, completed, disabled }) => (
  <HeaderContainer
    disabled={disabled}
    className={`${!disabled ? "waves-effect" : ""}`}
    onClick={!disabled ? onClick : undefined}
  >
    <StepState selected={selected} completed={completed}>
      {completed ? <i className="fa-solid fa-check" /> : <InnerCircle />}
    </StepState>
    {children}
  </HeaderContainer>
);

Header.propTypes = {
  children: PropTypes.any,
  /** Callback when stepper header is clicked */
  onClick: PropTypes.func,
  /** Boolean whether or not the header is selected */
  selected: PropTypes.bool,
  /** Boolean whether or not the header is completed */
  completed: PropTypes.bool,
  /** Boolean whether or not the header is disabled" */
  disabled: PropTypes.bool,
};

export default Header;
