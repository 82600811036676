import { combineReducers } from "redux";

import cohort from "./cohort";
import dashboard from "./dashboard";
import dataset from "./dataset";
import portal from "./portal";

export default combineReducers({
  portal,
  cohort,
  dataset,
  dashboard,
});
