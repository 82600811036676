export default [
  {
    isParent: true,
    label: "Dashboard View",
    id: "dashboard-view",
  },
  {
    cubeKey: "insights_dashboard_views.page_name",
    label: "Dashboard Name",
    parentId: "dashboard-view",
  },
  {
    cubeKey: "insights_analytic_domain.analytic_domain_name",
    label: "Analytic Domain",
    parentId: "dashboard-view",
  },
  {
    cubeKey: "insights_subdomain.subdomain_name",
    label: "Subdomain",
    parentId: "dashboard-view",
  },
];
