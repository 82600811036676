import React, { useState } from "react";

import { updateUserMeta } from "@actions";
import { gridSpacing, Table } from "@lucernahealth/lucerna-health-ui";
import { connect } from "react-redux";

import GroupedObjects from "./GroupedObjects";
import Header from "./Header";
import type { FilterProps } from "./helpers";
import { ContentWrapper, ObjectsContainer } from "./helpers";
import DashboardObject from "../DashboardObject";
import GroupObject from "../GroupObject";
import type { GroupProps } from "../helpers";
import { checkGroupPermission } from "../helpers";
import { dashboardColumns, groupColumns } from "../tableColumns";
import type {
  ListAnalyticDomain,
  ListInsightsDashboard,
  UserFavorites,
} from "~/src/types";
import { LEAP_INSIGHTS_URLS } from "~/src/urls";
import { includesIgnoreCase } from "~/src/utils/tsUtils";

interface FavoritesTabProps {
  filters: FilterProps;
  setFilters: (filters: FilterProps) => void;
  tableView: boolean;
  setTableView: (value: boolean) => void;
  insightsDashboards: ListInsightsDashboard[];
  setSelectedDashboard: (dashboard: ListInsightsDashboard) => void;
  updateUserMeta: (key: string, value: boolean) => void;
  leapInsightsFavoritesGroupsIsOpened: boolean;
  leapInsightsFavoritesDashboardsIsOpened: boolean;
  favoritesData: UserFavorites[];
  getFavorites: () => void;
  subjects: ListAnalyticDomain[];
  customerName: string;
}

const FavoritesTab = ({
  filters,
  setFilters,
  tableView,
  setTableView,
  insightsDashboards,
  setSelectedDashboard,
  updateUserMeta,
  leapInsightsFavoritesGroupsIsOpened,
  leapInsightsFavoritesDashboardsIsOpened,
  favoritesData,
  getFavorites,
  subjects,
  customerName,
}: FavoritesTabProps) => {
  const [favoritesGroupsIsOpened, setFavoritesGroupsIsOpened] = useState(
    leapInsightsFavoritesGroupsIsOpened ?? false,
  );
  const [favoritesDashboardsIsOpened, setFavoritesDashboardsIsOpened] =
    useState(leapInsightsFavoritesDashboardsIsOpened ?? false);

  const favoriteGroups: GroupProps[] = [];
  const favoriteDashboards: ListInsightsDashboard[] = [];

  subjects.forEach((subject) => {
    const subjectPath = subject.path;

    subject.subdomains.forEach((subdomain) => {
      const groupPath = `${subjectPath}/${subdomain.path}`;
      const groupFullPath = `${LEAP_INSIGHTS_URLS.baseUrl}/${groupPath}`;

      const foundFavorite = favoritesData.find(
        (favorite) =>
          favorite.object_id === subdomain.uuid &&
          favorite.object_type === "leap_insights_subdomain",
      );

      if (foundFavorite && checkGroupPermission(subjectPath, subdomain.path)) {
        favoriteGroups.push({
          ...subdomain,
          path: groupFullPath,
          created: foundFavorite.created ?? "",
        });
      }
    });
  });

  favoritesData.forEach((favorite) => {
    const foundDashboard = insightsDashboards.find(
      (dashboard) => dashboard.uuid === favorite.object_id,
    );

    if (foundDashboard) {
      favoriteDashboards.push({ ...foundDashboard });
    }
  });

  const filteredFavoriteGroups = favoriteGroups
    .filter(
      (group) =>
        includesIgnoreCase(group.name, filters.search) &&
        (!filters.analytic_domain ||
          subjects.find((s) =>
            s.subdomains.find((subdomain) => subdomain.name === group.name),
          )?.name === filters.analytic_domain) &&
        (filters.subdomain?.length === 0 ||
          (group.name && filters.subdomain?.includes(group.name))),
    )
    .sort(
      (a: { created: string }, b: { created: string }) =>
        new Date(b.created).getTime() - new Date(a.created).getTime(),
    );

  const filteredFavoriteDashboards = favoriteDashboards.filter(
    ({ subdomain, name, type, managed_by }) =>
      includesIgnoreCase(name, filters.search) &&
      (!filters.type || filters.type === type) &&
      (!filters.managed_by || filters.managed_by === managed_by) &&
      (!filters.analytic_domain ||
        subdomain.analytic_domain.name === filters.analytic_domain) &&
      (filters.subdomain?.length === 0 ||
        (subdomain.name && filters.subdomain?.includes(subdomain.name))),
  );

  const toggleFavoritesDashboards = () => {
    {
      setFavoritesDashboardsIsOpened(!favoritesDashboardsIsOpened);
      updateUserMeta(
        "leapInsightsFavoritesDashboardsIsOpened",
        !favoritesDashboardsIsOpened,
      );
    }
  };

  const toggleFavoritesGroups = () => {
    setFavoritesGroupsIsOpened(!favoritesGroupsIsOpened);
    updateUserMeta(
      "leapInsightsFavoritesGroupsIsOpened",
      !favoritesGroupsIsOpened,
    );
  };

  const renderGroupObjectContentSubdomain = () => {
    if (favoriteGroups.length === 0) {
      return "No favorite groups yet";
    }

    if (filteredFavoriteGroups.length > 0) {
      return filteredFavoriteGroups.map((_group: GroupProps) => (
        <GroupObject
          key={_group.path}
          subdomain={_group}
          insightsDashboards={insightsDashboards}
          favorites={favoritesData}
          getFavorites={getFavorites}
        />
      ));
    }
    return "No favorite groups found";
  };
  const renderGroupObjectContentDashboard = () => {
    if (favoriteDashboards.length === 0) {
      return "No favorite dashboards yet";
    }

    if (filteredFavoriteDashboards.length > 0) {
      return filteredFavoriteDashboards.map((_dashboard) => (
        <DashboardObject
          key={_dashboard.uuid}
          setSelectedDashboard={setSelectedDashboard}
          favorites={favoritesData}
          dashboard={_dashboard}
          getFavorites={getFavorites}
        />
      ));
    }

    return "No favorite dashboards found";
  };

  return (
    <>
      <Header
        filters={filters}
        setFilters={setFilters}
        tableView={tableView}
        setTableView={setTableView}
        subjects={subjects}
      />
      <ContentWrapper>
        <GroupedObjects
          isCollapsed={favoritesGroupsIsOpened}
          toggleGroup={toggleFavoritesGroups}
          icon="fa-regular fa-folder"
          title="Subdomains"
          childrenCount={filteredFavoriteGroups.length}
          iconStyle={{
            fontSize: 20,
            marginRight: gridSpacing[2],
          }}
          type="subdomain"
          content={
            tableView ? (
              <Table
                columns={groupColumns(favoritesData, getFavorites)}
                data={filteredFavoriteGroups}
              />
            ) : (
              <ObjectsContainer>
                {renderGroupObjectContentSubdomain()}
              </ObjectsContainer>
            )
          }
        />
        <GroupedObjects
          isCollapsed={favoritesDashboardsIsOpened}
          toggleGroup={toggleFavoritesDashboards}
          icon="fa-regular fa-table-layout"
          title="Dashboards"
          childrenCount={filteredFavoriteDashboards.length}
          iconStyle={{
            fontSize: 20,
            marginRight: gridSpacing[2],
          }}
          content={
            tableView ? (
              <Table
                columns={dashboardColumns(
                  setSelectedDashboard,
                  favoritesData,
                  getFavorites,
                  customerName,
                )}
                data={filteredFavoriteDashboards}
              />
            ) : (
              <ObjectsContainer>
                {renderGroupObjectContentDashboard()}
              </ObjectsContainer>
            )
          }
        />
      </ContentWrapper>
    </>
  );
};

const mapStateToProps = (state: {
  main: {
    user: {
      metadata: {
        leapInsightsFavoritesGroupsIsOpened: boolean;
        leapInsightsFavoritesDashboardsIsOpened: boolean;
      };
    };
  };
  SystemManagement: {
    management: {
      general: {
        result: {
          name: string;
        };
      };
    };
  };
}) => {
  return {
    leapInsightsFavoritesGroupsIsOpened:
      state.main.user.metadata?.leapInsightsFavoritesGroupsIsOpened,
    leapInsightsFavoritesDashboardsIsOpened:
      state.main.user.metadata?.leapInsightsFavoritesDashboardsIsOpened,
    customerName:
      state.SystemManagement?.management?.general?.result?.name || "Customer",
  };
};

export default connect(mapStateToProps, {
  updateUserMeta,
})(FavoritesTab);
