import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

interface ProviderDirectoryHealthPlanState {
  fetchList: boolean;
}

const initialState: ProviderDirectoryHealthPlanState = {
  fetchList: false,
};

const healthPlan = createSlice({
  name: "healthPlan",
  initialState,
  reducers: (create) => {
    return {
      setHealthPlanFetchListRedux: create.reducer(
        (state, action: PayloadAction<boolean>) => {
          state.fetchList = action.payload;
        },
      ),
    };
  },
});

export const { setHealthPlanFetchListRedux } = healthPlan.actions;

export default healthPlan.reducer;
