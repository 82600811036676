import { colorTheme } from "@utils";

import urlConfig from "./UrlConfig";

export const buildParams = (filters) => {
  const _params = new URLSearchParams();
  Object.keys(filters).forEach((filter) => {
    if (filters[filter]) {
      _params.append(filter, filters[filter]);
    }
  });
  return _params;
};

export const getActivityDetails = (activity) => {
  let text = activity;
  let type = null;
  let background = colorTheme("primary");
  let icon = null;

  if (activity === "delete") {
    text = "Deleted";
    type = "danger";
    background = colorTheme("danger");
    icon = "fa-regular fa-minus";
  } else if (activity === "update") {
    text = "Updated";
    type = "warning";
    background = colorTheme("warning");
    icon = "fa-solid fa-pencil";
  } else if (activity === "create") {
    text = "Created";
    type = "success";
    background = colorTheme("success");
    icon = "fa-regular fa-plus";
  }

  return { text, type, background, icon };
};

export const getUrl = (app, name, objectId) => {
  let config = urlConfig[app][name];
  if (config) {
    config = config.replace(":objectId", objectId);
  }
  return config;
};
