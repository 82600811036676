import React from "react";

import {
  Divider,
  gridSpacing,
  Loading,
  SlideOutPanel,
} from "@lucernahealth/lucerna-health-ui";
import PropTypes from "prop-types";
import { styled } from "styled-components";

import { colorTheme } from "@utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-wrap: anywhere;
`;

const Title = styled.b`
  color: ${colorTheme("neutralL1")};
  font-size: 12px;
  margin-bottom: ${gridSpacing[1]}px;
`;

const Content = styled.div`
  margin-bottom: ${gridSpacing[4]}px;
`;

const DetailSlideOut = ({
  headerText,
  data,
  isOpened,
  setIsOpened,
  size,
  closeCallback,
  loading,
  headerButtonProps,
}) => {
  const panelSize = size || 1;

  if (!isOpened) return null;

  return (
    <SlideOutPanel
      size={panelSize}
      isOpened={isOpened}
      setIsOpened={setIsOpened}
      title={headerText}
      closeCallback={closeCallback}
      headerButtonProps={headerButtonProps}
    >
      {loading ? (
        <Loading center />
      ) : (
        <Container>
          {data.map((data_array, i) => (
            <div key={i}>
              {data_array.map((data_obj, obj_i) => (
                <div key={obj_i}>
                  <Title>{data_obj.title}</Title>
                  <Content>{data_obj.content || "-"}</Content>
                </div>
              ))}
              {i + 1 < data.length && (
                <Divider style={{ marginBottom: gridSpacing[4] }} />
              )}
            </div>
          ))}
        </Container>
      )}
    </SlideOutPanel>
  );
};

DetailSlideOut.propTypes = {
  headerText: PropTypes.string,
  data: PropTypes.array,
  isOpened: PropTypes.bool,
  setIsOpened: PropTypes.func,
  size: PropTypes.oneOf([1, 2, 3]),
  closeCallback: PropTypes.func,
  loading: PropTypes.bool,
  headerButtonProps: PropTypes.object,
};

export default DetailSlideOut;
