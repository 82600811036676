const initialState = {
  metrics: {
    isFetching: true,
    error: false,
    result: [],
  },
  metricGroups: {
    isFetching: true,
    error: false,
    result: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "ATTRIBUTION_FETCH_METRICS": {
      return Object.assign({}, state, { metrics: action.payload });
    }

    case "ATTRIBUTION_METRIC_ADD": {
      return Object.assign({}, state, {
        metrics: {
          ...state.metrics,
          result: {
            ...state.metrics.result,
            count: state.metrics.result.count + 1,
            results: [...state.metrics.result.results, action.payload],
          },
        },
      });
    }

    case "ATTRIBUTION_METRIC_UPDATE": {
      let results = state.metrics.result.results.map((group) => {
        if (group.uuid === action.payload.uuid) {
          return action.payload;
        }
        return group;
      });
      return Object.assign({}, state, {
        metrics: {
          ...state.metrics,
          result: {
            ...state.metrics.result,
            results,
          },
        },
      });
    }

    case "ATTRIBUTION_METRIC_DELETE": {
      return Object.assign({}, state, {
        metrics: {
          ...state.metrics,
          result: {
            ...state.metrics.result,
            count: state.metrics.result.count - 1,
            results: state.metrics.result.results.filter(
              (metric) => metric.uuid !== action.payload.uuid,
            ),
          },
        },
      });
    }

    case "ATTRIBUTION_FETCH_METRIC_GROUPS": {
      return Object.assign({}, state, { metricGroups: action.payload });
    }

    case "ATTRIBUTION_METRIC_GROUPS_ADD": {
      return Object.assign({}, state, {
        metricGroups: {
          ...state.metricGroups,
          result: {
            ...state.metricGroups.result,
            count: state.metricGroups.result.count + 1,
            results: [...state.metricGroups.result.results, action.payload],
          },
        },
      });
    }

    case "ATTRIBUTION_METRIC_GROUPS_UPDATE": {
      let results = state.metricGroups.result.results.map((group) => {
        if (group.uuid === action.payload.uuid) {
          return action.payload;
        }
        return group;
      });
      return Object.assign({}, state, {
        metricGroups: {
          ...state.metricGroups,
          result: {
            ...state.metricGroups.result,
            results,
          },
        },
      });
    }

    case "ATTRIBUTION_METRIC_GROUPS_DELETE": {
      return Object.assign({}, state, {
        metricGroups: {
          ...state.metricGroups,
          result: {
            ...state.metricGroups.result,
            count: state.metricGroups.result.count - 1,
            results: state.metricGroups.result.results.filter(
              (group) => group.uuid !== action.payload.uuid,
            ),
          },
        },
      });
    }

    default:
      return state;
  }
}
