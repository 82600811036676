const initialState = {
  expandedObjects: [],
  columnDataTypes: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
  availableDataModels: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
  selectedObject: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: {},
  },
  selectedDataModel: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: {},
  },
  dataModelPublishing: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: {},
    hasDismissed: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "DATA_MODEL_EXPAND_OBJECT": {
      return Object.assign({}, state, {
        expandedObjects: [...state.expandedObjects, action.payload],
      });
    }

    case "DATA_MODEL_EXPAND_ALL": {
      return Object.assign({}, state, {
        expandedObjects: action.payload,
      });
    }

    case "DATA_MODEL_COLLAPSE_OBJECT": {
      return Object.assign({}, state, {
        expandedObjects: state.expandedObjects.filter(
          (obj) => obj !== action.payload,
        ),
      });
    }

    case "DATA_MODEL_COLLAPSE_ALL": {
      return Object.assign({}, state, {
        expandedObjects: [],
      });
    }

    case "DATA_MODEL_SELECT_OBJECT": {
      let result = action.payload.result;
      if (action.payload.isRefreshing) {
        result = state.selectedObject.result;
      }

      return Object.assign({}, state, {
        selectedObject: { ...action.payload, result },
      });
    }

    case "DATA_MODEL_SELECT_DATA_MODEL": {
      let result = action.payload.result;
      if (action.payload.isRefreshing) {
        result = state.selectedDataModel.result;
      }

      // Collect every object's relative identifier so we don't need to do this over and over in the code later
      result.domains?.forEach((domain) => {
        domain.relative_identifier = `${result.identifier}|${domain.identifier}`;
        domain.subject_areas.forEach((subjectArea) => {
          subjectArea.relative_identifier = `${result.identifier}|${domain.identifier}|${subjectArea.identifier}`;
          subjectArea.tables.forEach((table) => {
            table.relative_identifier = `${result.identifier}|${domain.identifier}|${subjectArea.identifier}|${table.identifier}`;
          });
        });
      });

      return Object.assign({}, state, {
        selectedDataModel: { ...action.payload, result },
      });
    }

    case "DATA_MODEL_FETCH_AVAILABLE_DATA_MODELS": {
      return Object.assign({}, state, {
        availableDataModels: action.payload,
      });
    }

    case "DATA_MODEL_FETCH_COLUMN_DATA_TYPES": {
      return Object.assign({}, state, {
        columnDataTypes: action.payload,
      });
    }

    case "DATA_MODEL_PUBLISHING": {
      let result = action.payload.result;
      if (action.payload.isRefreshing) {
        result = state.dataModelPublishing.result;
      }

      return Object.assign({}, state, {
        dataModelPublishing: {
          ...action.payload,
          hasDismissed: state.dataModelPublishing.hasDismissed,
          result: result,
        },
      });
    }

    case "DATA_MODEL_PUBLISHING_DISMISS": {
      return Object.assign({}, state, {
        dataModelPublishing: {
          ...state.dataModelPublishing,
          hasDismissed: true,
        },
      });
    }

    case "DATA_MODEL_PUBLISHING_DISMISS_CLEAR": {
      return Object.assign({}, state, {
        dataModelPublishing: {
          ...state.dataModelPublishing,
          hasDismissed: false,
        },
      });
    }

    case "DATA_MODEL_CLEAR_OBJECTS": {
      return Object.assign({}, state, {
        expandedObjects: [],
        availableDataModels: {
          isFetching: true,
          isRefreshing: false,
          error: false,
          result: [],
        },
        selectedObject: {
          isFetching: false,
          isRefreshing: false,
          error: false,
          result: [],
        },
        selectedDataModel: {
          isFetching: false,
          isRefreshing: false,
          error: false,
          result: [],
        },
      });
    }

    default:
      return state;
  }
}
