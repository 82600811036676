const initialState = {
  messages: {
    isFetching: true,
    error: false,
    result: {
      message_of_the_day: "",
      portal_description: "",
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "INSIGHTS_PORTAL_SET_MESSAGES": {
      return Object.assign({}, state, {
        messages: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    default:
      return state;
  }
}
