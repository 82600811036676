import React from "react";

import PropTypes from "prop-types";
import { useDrag } from "react-dnd";

function DragSource(props) {
  const [_x, drag] = useDrag({
    type: props.type,
    item: { id: props.id, ...props.data },
  });

  return (
    <div ref={drag} style={props.style}>
      {props.children}
    </div>
  );
}

DragSource.propTypes = {
  children: PropTypes.any,
  /** Unique id of the source */
  id: PropTypes.string.isRequired,
  /** The acceptance type of the source */
  type: PropTypes.string.isRequired,
  /** Custom styled object */
  style: PropTypes.object,
  /** Additional data to be sent with the source */
  data: PropTypes.object,
};

export default DragSource;
