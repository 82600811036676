export default [
  {
    isParent: true,
    label: "Consumer Demographics",
    id: "consumer",
  },
  {
    cubeKey: "assigned_consumer_detail.state",
    label: "State",
    parentId: "consumer",
  },
  {
    cubeKey: "assigned_consumer_detail.city",
    label: "City",
    parentId: "consumer",
  },
  {
    cubeKey: "assigned_consumer_detail.zip",
    label: "Zip",
    parentId: "consumer",
  },
  {
    cubeKey: "assigned_consumer_detail.gender",
    label: "Gender",
    parentId: "consumer",
  },
];
