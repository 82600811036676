import CONSUMER_360_URLS from "./urls";

export default [
  {
    appTitle: true,
    header: "Consumer 360",
    icon: "fa-kit fa-hdp-app-consumer-360",
    baseRoute: CONSUMER_360_URLS.baseRoute,
  },
  {
    header: "Consumers",
    children: [
      {
        name: "Profiles",
        route: CONSUMER_360_URLS.CONSUMER_PROFILES,
        permissions: ["entity_unification.view_consumer"],
      },
      {
        name: "Consumer Registry",
        route: CONSUMER_360_URLS.CONSUMER_REGISTRY,
        permissions: ["entity_unification.view_registry"],
      },
    ],
  },
  {
    header: "Configuration",
    children: [
      {
        name: "Sources",
        route: CONSUMER_360_URLS.CONFIGURATION_SOURCES,
        permissions: ["entity_unification.view_source"],
      },
      {
        name: "Entity Unification",
        route: CONSUMER_360_URLS.CONFIGURATION_ENTITY_UNIFICATION,
        permissions: ["entity_unification.view_ruleset"],
      },
      {
        name: "Golden Record",
        route: CONSUMER_360_URLS.CONFIGURATION_GOLDEN_RECORD,
        permissions: ["entity_unification.view_golden_record"],
      },
      {
        name: "Validators",
        route: CONSUMER_360_URLS.CONFIGURATION_FIELD_VALIDATOR,
        permissions: ["entity_unification.view_field_validator"],
      },
    ],
  },
  {
    header: "Operations",
    children: [
      {
        name: "Monitoring",
        route: CONSUMER_360_URLS.OPERATIONS_MONITORING,
        permissions: ["entity_unification.view_data_ingestion_job"],
      },
    ],
  },
  {
    header: "Engagement",
    children: [
      {
        name: "Output Mapping",
        route: CONSUMER_360_URLS.SURVEY_OUTPUT_MAPPING_SURVEYS,
        permissions: ["survey_output.view_survey"],
      },
      {
        name: "Target Models",
        route: CONSUMER_360_URLS.SURVEY_TARGET_MODEL_MANAGEMENT,
        permissions: ["survey_output.view_targetmodel"],
      },
    ],
  },
];
