import { combineReducers } from "redux";

import main from "./main";
import Consumer360 from "../App/ConsumerProfile/reducers";
import DataManagement from "../App/DataManagement/reducers";
import Insights from "../App/Insights/reducers";
import ProviderDirectory from "../App/ProviderDirectory/reducers";
import SystemManagement from "../App/SystemManagement/reducers";

export default combineReducers({
  main,
  SystemManagement,
  DataManagement,
  Insights,
  ProviderDirectory,
  Consumer360,
});
