import React, { useState, useCallback, useEffect } from "react";

import { updateUserMeta } from "@actions";
import {
  Button,
  ButtonGroup,
  SearchText,
  gridSpacing,
} from "@lucernahealth/lucerna-health-ui";
import { Resizable } from "re-resizable";
import { connect } from "react-redux";
import { styled } from "styled-components";

import { toggleInArray, DashboardFilter } from "./helpers";
import SubjectsList from "./SubjectsList";
import { checkGroupPermission } from "../helpers";
import type {
  ListAnalyticDomain,
  ListInsightsDashboard,
  UserFavorites,
} from "~/src/types";
import { colorTheme } from "~/src/utils";

const COLLAPSED_WIDTH = 16;
const MIN_WIDTH = 325;
const MAX_WIDTH = 580;
const DEFAULT_WIDTH = 370;

const ExpandButtonSideContainer = styled.div<{ $collapsed: boolean }>`
  width: ${({ $collapsed }) => ($collapsed ? "30px" : "10px")};
  height: 100vh;
  background: ${({ $collapsed }) =>
    colorTheme($collapsed ? "white" : "neutralL5")};
  border-left: ${({ $collapsed }) =>
    $collapsed ? "none" : `1px solid ${colorTheme("neutralL4")}`};
  border-right: ${({ $collapsed }) =>
    !$collapsed ? "none" : `1px solid ${colorTheme("neutralL4")}`};

  div {
    border-left: ${({ $collapsed }) =>
      $collapsed ? "none" : `1px solid ${colorTheme("neutralL4")}`};

    width: 18px;
    left: ${({ $collapsed }) => ($collapsed ? "4px" : "-10px")};
    top: ${gridSpacing[6]}px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 4px;
    background: ${({ $collapsed }) =>
      colorTheme($collapsed ? "white" : "neutralL5")};
    transition: left 0.15s ease-in-out;
  }

  &:hover {
    width: ${({ $collapsed }) => ($collapsed ? "30px" : "12px")};
    cursor: pointer;
    background: ${({ $collapsed }) =>
      colorTheme($collapsed ? "neutralL6" : "neutralL4")};

    // Make icon look like it is moving
    div {
      left: ${({ $collapsed }) => ($collapsed ? 11 : -11)}px;
      background: ${({ $collapsed }) =>
        colorTheme($collapsed ? "neutralL6" : "neutralL4")};
      border-right: ${({ $collapsed }) =>
        $collapsed ? `1px solid ${colorTheme("neutralL4")}` : "none"};
    }
  }
`;

const Container = styled.div<{ $collapsed: boolean }>`
  display: flex;
  flex-direction: column;

  border-right: ${({ $collapsed }) =>
    $collapsed ? "none" : `1px solid ${colorTheme("neutralL4")}`};
  height: calc(100vh - 82px); // 82px is the height of the top bar
  width: ${({ $collapsed }) => ($collapsed ? 0 : "calc(100% - 10px)")};
  background: ${colorTheme("white")};
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${gridSpacing[5]}px;
`;

const Divider = styled.div`
  border-bottom: 1px solid ${colorTheme("neutralL4")};
  margin: ${gridSpacing[4]}px ${gridSpacing[5]}px 0 ${gridSpacing[5]}px;
`;

type SidebarProps = {
  insightsDashboards: ListInsightsDashboard[];
  subjects: ListAnalyticDomain[];
  isLoading: boolean;
  updateUserMeta: (
    key: string,
    value: string | string[] | boolean | number,
  ) => void;
  leapInsightsSidebarTypeFilter: string;
  leapInsightsOpenedSubjects: string[];
  leapInsightsOpenedGroups: string[];
  customerName: string;
  leapInsightsSidebarWidth: number;
  favoritesData: UserFavorites[];
  selectedObject: string;
  setSelectedObject: (value: string) => void;
};

const Sidebar = ({
  insightsDashboards,
  subjects,
  isLoading,
  updateUserMeta,
  leapInsightsSidebarTypeFilter,
  leapInsightsOpenedSubjects,
  leapInsightsOpenedGroups,
  customerName,
  leapInsightsSidebarWidth,
  favoritesData,
  selectedObject,
  setSelectedObject,
}: SidebarProps) => {
  const [collapsed, setCollapsed] = useState(
    leapInsightsSidebarWidth === COLLAPSED_WIDTH,
  );
  const [width, setWidth] = useState(leapInsightsSidebarWidth ?? DEFAULT_WIDTH);
  const [filterText, setFilterText] = useState("");
  const [dashboardFilter, setDashboardFilter] = useState<string>(
    leapInsightsSidebarTypeFilter ?? DashboardFilter.All,
  );
  const [expandAll, setExpandAll] = useState<boolean>(false);
  const [openSubjects, setOpenedSubjects] = useState<string[]>(
    leapInsightsOpenedSubjects ?? [],
  );
  const [openGroups, setOpenGroups] = useState<string[]>(
    leapInsightsOpenedGroups ?? [],
  );

  useEffect(() => {
    const pathname = window.location.pathname;
    const hash = window.location.hash;
    setSelectedObject(`${pathname}${hash}`);
  }, []);

  const handleSubjectToggle = useCallback(
    (subjectUuid: string) => {
      const updatedSubjects = toggleInArray(openSubjects, subjectUuid);
      setOpenedSubjects(updatedSubjects);
      updateUserMeta("leapInsightsOpenedSubjects", updatedSubjects);
    },
    [openSubjects],
  );

  const handleExpandAll = () => {
    setExpandAll(true);
    const openedSubjects = [
      "favorites",
      ...subjects.map((subject) => subject.uuid),
    ];
    updateUserMeta("leapInsightsOpenedSubjects", openedSubjects);
    setOpenedSubjects(openedSubjects);

    const openedGroups = subjects.flatMap((subject) =>
      subject.subdomains
        .filter((subdomain) =>
          checkGroupPermission(subject.path, subdomain.path),
        )
        .map((group) => group.uuid),
    );
    setOpenGroups(openedGroups);
    updateUserMeta("leapInsightsOpenedGroups", openedGroups);

    // Timeout is to make expand all is false so the user can collapse groups
    setTimeout(() => {
      setExpandAll(false);
    }, 100);
  };

  const handleCollapseAll = () => {
    setExpandAll(false);
    setOpenedSubjects([]);
    setOpenGroups([]);
    updateUserMeta("leapInsightsOpenedSubjects", []);
    updateUserMeta("leapInsightsOpenedGroups", []);
  };

  useEffect(() => {
    if (filterText.toLowerCase() && !expandAll) {
      handleExpandAll();
    }
  }, [filterText]);

  return (
    <Resizable
      enable={{
        right: !collapsed,
      }}
      size={{ width: width, height: "100%" }}
      minWidth={collapsed ? 25 : MIN_WIDTH}
      maxWidth={MAX_WIDTH}
      onResizeStop={(e, direction, ref, d) => {
        const newWidth = width + d.width;
        setWidth(newWidth);
        updateUserMeta("leapInsightsSidebarWidth", newWidth);
      }}
    >
      <div style={{ display: "flex" }}>
        <Container $collapsed={collapsed} id="innerSidebar">
          <HeaderContainer>
            {!collapsed && (
              <div
                style={{
                  flex: 1,
                  paddingRight: gridSpacing[5],
                }}
              >
                <SearchText
                  fixedWidth
                  style={{ width: "100%" }}
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                />
              </div>
            )}
          </HeaderContainer>

          {!collapsed && (
            <>
              <div
                style={{
                  paddingLeft: gridSpacing[5],
                  paddingRight: gridSpacing[5],
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <ButtonGroup
                  small
                  value={dashboardFilter}
                  onChange={(e) => {
                    setDashboardFilter(e as string);
                    updateUserMeta(
                      "leapInsightsSidebarTypeFilter",
                      e as string,
                    );
                  }}
                >
                  <Button text="All" value="all" />
                  <Button text="Lucerna" value="lucerna" />
                  <Button text={customerName} value="customer" />
                </ButtonGroup>
                <ButtonGroup small>
                  <Button
                    icon="fa-maximize"
                    tooltip="Expand All"
                    onClick={handleExpandAll}
                    value="expandAll"
                  />
                  <Button
                    icon="fa-minimize"
                    tooltip="Collapse All"
                    onClick={handleCollapseAll}
                    value="collapseAll"
                  />
                </ButtonGroup>
              </div>
              <Divider />
              <SubjectsList
                filterValue={filterText.toLowerCase()}
                insightsDashboards={insightsDashboards}
                subjects={subjects}
                dashboardFilter={dashboardFilter}
                openGroups={openGroups}
                setOpenGroups={setOpenGroups}
                openSubjects={openSubjects}
                handleSubjectToggle={handleSubjectToggle}
                isLoading={isLoading}
                selectedObject={selectedObject}
                setSelectedObject={setSelectedObject}
                favoritesData={favoritesData}
              />
            </>
          )}
        </Container>
        <ExpandButtonSideContainer
          $collapsed={collapsed}
          onClick={() => {
            let newWidth = COLLAPSED_WIDTH;
            setCollapsed(!collapsed);
            if (collapsed) {
              newWidth = DEFAULT_WIDTH;
            }
            setWidth(newWidth);
            updateUserMeta("leapInsightsSidebarWidth", newWidth);
          }}
        >
          <div>
            <i
              style={{ fontSize: 14 }}
              className={`fa-regular fa-chevrons-${
                collapsed ? "right" : "left"
              }`}
            />
          </div>
        </ExpandButtonSideContainer>
      </div>
    </Resizable>
  );
};

const mapStateToProps = (state: {
  main: {
    user: {
      metadata: {
        leapInsightsSidebarTypeFilter: string;
        leapInsightsOpenedSubjects: string[];
        leapInsightsOpenedGroups: string[];
        leapInsightsSidebarWidth: number;
      };
    };
  };
  SystemManagement: {
    management: {
      general: {
        result: {
          name: string;
        };
      };
    };
  };
}) => {
  return {
    leapInsightsSidebarTypeFilter:
      state.main.user.metadata?.leapInsightsSidebarTypeFilter,
    leapInsightsOpenedSubjects:
      state.main.user.metadata?.leapInsightsOpenedSubjects,
    leapInsightsOpenedGroups:
      state.main.user.metadata?.leapInsightsOpenedGroups,
    customerName:
      state.SystemManagement?.management?.general?.result?.name || "Customer",
    leapInsightsSidebarWidth:
      state.main.user.metadata?.leapInsightsSidebarWidth,
  };
};

export default connect(mapStateToProps, {
  updateUserMeta,
})(Sidebar);
