import React from "react";

import { gridSpacing, TableLink } from "@lucernahealth/lucerna-health-ui";
import { styled } from "styled-components";

import { colorTheme } from "@utils";

const BreadCrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${gridSpacing[4]}px;
`;

const BreadCrumb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  i {
    color: ${colorTheme("neutralL3")};
    margin-right: ${gridSpacing[2]}px;
    font-size: 16px;
  }
`;

const Divider = styled.div`
  color: ${colorTheme("neutralL3")};
  font-size: 16px;
  font-weight: 500;
`;

type RouteProps = {
  icon?: string;
  iconStyle?: React.CSSProperties;
  breadcrumb: string;
  path?: string;
  onClick?: () => void;
};

type BreadCrumbsProps = {
  baseRoute: RouteProps;
  routes?: RouteProps[];
  style?: React.CSSProperties;
};

const BreadCrumbsV2 = ({ baseRoute, routes, style }: BreadCrumbsProps) => (
  <BreadCrumbContainer style={style}>
    {baseRoute && (
      <>
        <BreadCrumb>
          {baseRoute.icon && (
            <i style={baseRoute.iconStyle} className={baseRoute.icon} />
          )}
          <TableLink onClick={baseRoute?.onClick} url={baseRoute?.path}>
            {baseRoute.breadcrumb}
          </TableLink>
        </BreadCrumb>
        {routes && routes.length > 0 && <Divider>|</Divider>}
      </>
    )}
    {routes?.map(
      ({ icon, breadcrumb, path, onClick, iconStyle }, headerIndex) => (
        <React.Fragment key={headerIndex}>
          {headerIndex !== 0 && headerIndex !== routes.length && (
            <Divider>/</Divider>
          )}
          <BreadCrumb>
            {icon && <i style={iconStyle} className={icon} />}
            {headerIndex + 1 !== routes.length ? (
              <TableLink
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                onClick={onClick}
                url={path}
              >
                {breadcrumb}
              </TableLink>
            ) : (
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {breadcrumb}
              </div>
            )}
          </BreadCrumb>
        </React.Fragment>
      ),
    )}
  </BreadCrumbContainer>
);

export default BreadCrumbsV2;
