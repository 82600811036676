import type { Dispatch, SetStateAction } from "react";
import { useContext, useEffect } from "react";

import { DataViz } from "@commonComponents";
import type { Filter } from "@cubejs-client/core";

import type { DashboardAdvancedFilterProps } from "~/src/App/LeapInsights/Components/Portal/Portal";
import { PortalContext } from "~/src/App/LeapInsights/Components/Portal/Portal";
import type { AdvFilterProps } from "~/src/utils/tsUtils";
import { useCubeApiQuery } from "~/src/utils/tsUtils";

export type OrganizedDataProps = {
  key: string;
  label: string;
  name: string;
  options: string[];
}[];

export const convertFilters = (
  filters: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
): Filter[] => {
  const normalizedFilters: Record<string, string[]> = {};

  for (const [key, value] of Object.entries(filters)) {
    const normalizedKey = key.startsWith("?") ? key.substring(1) : key;

    if (!normalizedFilters[normalizedKey]) {
      normalizedFilters[normalizedKey] = [];
    }

    if (Array.isArray(value)) {
      normalizedFilters[normalizedKey] =
        normalizedFilters[normalizedKey].concat(value);
    } else {
      normalizedFilters[normalizedKey].push(value);
    }
  }

  return Object.keys(normalizedFilters).flatMap((cube) => {
    let member = cube.replace("?", "");

    // If filterOrder is provided, use the cubeKey from the filterOrder to set the member so any id can be used
    if (filterOrder) {
      const splitCube = cube.split(".");
      const cubeName = splitCube[0];
      const metricName = splitCube[splitCube.length - 1];

      const matchingCube = filterOrder.find(
        (o) => cubeName === o.id && o.isParent,
      );

      let foundFilter;
      if (matchingCube) {
        foundFilter = filterOrder.find(
          (o) =>
            !o.isParent &&
            o.parentId === matchingCube.id &&
            o.cubeKey?.split(".").at(-1) === metricName,
        );
      } else {
        foundFilter = filterOrder.find(
          (o) => metricName === o.cubeKey?.split(".").at(-1),
        );
      }

      if (foundFilter?.cubeKey) {
        member = foundFilter.cubeKey;
      }
    }

    return {
      member,
      operator: "equals",
      values: normalizedFilters[cube],
    };
  });
};

export const convertCubeResponseToAdvFilters = (
  data: Record<string, string>[],
  cube: string,
): OrganizedDataProps => {
  const organizedData: Record<string, string[]> = {};

  data.forEach((obj) => {
    const keys = Object.keys(obj);

    keys.forEach((key) => {
      const value = obj[key];

      if (value !== undefined) {
        if (organizedData[key]) {
          organizedData[key].push(value);
        } else {
          organizedData[key] = [value];
        }
      }
    });
  });

  const uniqueOrganizedData: Record<string, string[]> = {};

  Object.keys(organizedData).forEach((o) => {
    uniqueOrganizedData[o] = [...new Set(organizedData[o])].sort((a, b) =>
      a?.toString().localeCompare(b?.toString()),
    );
  });

  return Object.keys(uniqueOrganizedData).map((key) => {
    return {
      key,
      label: key.replace(`${cube}.`, "").replaceAll("_", " "),
      name: key.replace(`${cube}.`, ""),
      options: uniqueOrganizedData[key],
    };
  });
};

/**
 * This hook is used to get the filter options for specified dimensions from cube
 * @param groupedDimensions - cube dimensions to get filter options for
 * @param queryFilters - optional, used to filter the query used to get filter options from cube.
 * @param _setFilterOptions - optional, used to override the setFilterOptions function from PortalContext if the component is outside of the PortalContext
 */
export const useFilterOptions = (
  groupedDimensions: Record<
    string,
    { dimensions: string[]; chartTitles?: string[] }
  >,
  queryFilters: AdvFilterProps = {},
  _setFilterOptions?: Dispatch<SetStateAction<object>>,
) => {
  let setFilterOptions = useContext(PortalContext)["setFilterOptions"];
  if (_setFilterOptions) {
    setFilterOptions = _setFilterOptions;
  }

  const queries = Object.keys(groupedDimensions).map((key) =>
    useCubeApiQuery({
      dimensions: groupedDimensions[key]?.dimensions ?? [],
      filters: [
        ...(queryFilters && queryFilters[key]
          ? DataViz.filterUtils.convertFilters({ [key]: queryFilters[key] })
          : []),
      ],
    }),
  );

  const resultSet: Record<
    string,
    {
      key: string;
      label: string;
      name: string;
      options: string[];
      chartTitles?: string[];
    }[]
  > = {};

  Object.keys(groupedDimensions).forEach((key, index) => {
    resultSet[key] = [
      ...convertCubeResponseToAdvFilters(
        queries[index].resultSet?.rawData() ?? [],
        key,
      ),
    ].map((o) => {
      return {
        ...o,
        chartTitles: groupedDimensions[key]?.chartTitles,
      };
    });
  });

  const isLoading = queries.some((query) => query.isLoading);

  useEffect(() => {
    if (!isLoading) {
      setFilterOptions(resultSet);
    }
  }, [isLoading]);

  return {
    resultSet,
    isLoading,
    error: queries.some((query) => query.error),
  };
};

export const useFilterOptionsNormalized = ({
  dimensions,
  queryFilters,
  _setFilterOptions,
  nameMap,
}: {
  dimensions: string[];
  queryFilters?: AdvFilterProps;
  _setFilterOptions?: Dispatch<SetStateAction<object>>;
  nameMap?: Record<string, string>;
}) => {
  let setFilterOptions = useContext(PortalContext)["setFilterOptions"];
  if (_setFilterOptions) {
    setFilterOptions = _setFilterOptions;
  }

  const queries = dimensions.map((dimension) =>
    useCubeApiQuery({
      dimensions: [dimension],
      filters: [
        ...(queryFilters
          ? DataViz.filterUtils.convertFilters(queryFilters)
          : []),
      ],
    }),
  );

  const resultSet: Record<
    string,
    {
      key: string;
      label: string;
      name: string;
      options: string[];
      chartTitles?: string[];
    }[]
  > = {};

  dimensions.forEach((dimension, index) => {
    const cube = dimension.split(".")[0];
    resultSet[cube] = resultSet[cube] ?? [];
    resultSet[cube].push(
      ...convertCubeResponseToAdvFilters(
        queries[index].resultSet?.rawData() ?? [],
        cube,
      ).map((o) => {
        return {
          ...o,
          label: nameMap?.[o.name] ?? o.name,
        };
      }),
    );
  });

  const isLoading = queries.some((query) => query.isLoading);

  useEffect(() => {
    if (!isLoading) {
      setFilterOptions(resultSet);
    }
  }, [isLoading]);

  return {
    resultSet,
    isLoading,
    error: queries.some((query) => query.error),
  };
};
