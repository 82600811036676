import { DataViz } from "@commonComponents";

import type { AdvFilterProps } from "~/src/utils/tsUtils";

export const createdRecordsQuery = (filters?: AdvFilterProps) =>
  ({
    measures: ["cps_consumer_monitoring.created_consumer_count_90d"],
    filters: [...(filters ? DataViz.filterUtils.convertFilters(filters) : [])],
  }) as const;

export const mergedRecordsQuery = (filters?: AdvFilterProps) =>
  ({
    measures: ["consumer_merge_history.merged_consumer_count_90d"],
    filters: [...(filters ? DataViz.filterUtils.convertFilters(filters) : [])],
  }) as const;

export const archivedRecordsQuery = (filters?: AdvFilterProps) =>
  ({
    measures: ["cps_consumer_monitoring.deprecated_consumer_count_90d"],
    filters: [...(filters ? DataViz.filterUtils.convertFilters(filters) : [])],
  }) as const;

export const jobsProcessedPerMonthQuery = (filters?: AdvFilterProps) =>
  ({
    measures: ["cps_data_ingestion_jobs.jobs_processed_count"],
    timeDimensions: [
      {
        dimension: "cps_data_ingestion_jobs.created",
        granularity: "month",
      },
    ],
    order: {
      "cps_data_ingestion_jobs.created": "asc",
    },
    filters: [...(filters ? DataViz.filterUtils.convertFilters(filters) : [])],
  }) as const;

export const incomingRecordsPerMonthQuery = (filters?: AdvFilterProps) =>
  ({
    measures: ["cps_data_ingestion_jobs.ingested_record_sum"],
    timeDimensions: [
      {
        dimension: "cps_data_ingestion_jobs.created",
        granularity: "month",
      },
    ],
    order: {
      "cps_data_ingestion_jobs.created": "asc",
    },
    filters: [...(filters ? DataViz.filterUtils.convertFilters(filters) : [])],
  }) as const;

export const recordsInsertedPerMonthQuery = (filters?: AdvFilterProps) =>
  ({
    measures: ["cps_data_ingestion_jobs.inserted_record_sum"],
    timeDimensions: [
      {
        dimension: "cps_data_ingestion_jobs.created",
        granularity: "month",
      },
    ],
    order: {
      "cps_data_ingestion_jobs.created": "asc",
    },
    filters: [...(filters ? DataViz.filterUtils.convertFilters(filters) : [])],
  }) as const;

export const recordsInsertedPerMonthPerSourceQuery = (
  filters?: AdvFilterProps,
) =>
  ({
    measures: ["cps_data_ingestion_jobs.inserted_record_sum"],
    timeDimensions: [
      {
        dimension: "cps_data_ingestion_jobs.created",
        granularity: "month",
      },
    ],
    order: {
      "cps_data_ingestion_jobs.inserted_record_sum": "desc",
    },
    dimensions: ["cps_data_ingestion_jobs.source_name"],
    filters: [...(filters ? DataViz.filterUtils.convertFilters(filters) : [])],
  }) as const;
