import { combineReducers } from "redux";

import management from "./management";
import notifications from "./notifications";
import quicksight from "./quicksight";
import valueLists from "./valueLists";

export default combineReducers({
  management,
  notifications,
  quicksight,
  valueLists,
});
