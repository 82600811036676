import type { MouseEvent } from "react";
import React from "react";

import { TableLink, gridSpacing } from "@lucernahealth/lucerna-health-ui";
import { styled } from "styled-components";

import { colorTheme } from "~/src/utils";

const NavLink = styled(TableLink)<{
  $selected: boolean;
  $hasPermission: boolean;
}>`
  color: ${({ $selected, $hasPermission }) =>
    colorTheme(
      !$hasPermission ? "neutralL2" : $selected ? "white" : "neutral",
    )} !important;

  background-color: ${({ $selected, $hasPermission }) =>
    colorTheme($selected && $hasPermission ? "primaryL1" : "white")} !important;
  padding: ${gridSpacing[2]}px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 8px;
  font-size: 14px;
  cursor: ${({ $hasPermission }) => !$hasPermission && "not-allowed"};

  i {
    color: ${({ $selected, $hasPermission }) =>
      colorTheme($selected && $hasPermission && "white")} !important;
  }

  &:hover {
    background-color: ${({ $selected }) =>
      $selected ? colorTheme("primary") : colorTheme("primaryL4")} !important;
  }
`;

const ToggleIcon = styled.i<{ $selected: boolean; $hasPermission: boolean }>`
  padding: ${gridSpacing[1]}px;
  border-radius: 4px;

  margin-right: ${gridSpacing[2]}px;

  &:hover {
    background-color: ${({ $selected, $hasPermission }) =>
      colorTheme(
        !$hasPermission ? "primaryL4" : $selected ? "primaryL1" : "primaryL3",
      )};
  }
`;

type IconProps = {
  className: string;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  path?: string;
  collapsed?: boolean;
  toggleCollapsed?: () => void;
  style?: React.CSSProperties;
};

const Icon = ({ className, onClick, style }: IconProps) => (
  <i
    className={className}
    style={{
      marginRight: gridSpacing[2],
      ...style,
    }}
    onClick={onClick}
  />
);

type ItemProps = {
  icon?: string;
  text: string;
  paddingLeft?: number;
  children?: React.ReactNode;
  path: string;
  collapsed?: boolean;
  toggleCollapsed?: () => void;
  hasPermission?: boolean;
  style?: React.CSSProperties;
  selectedObject: string;
  setSelectedObject: (value: string) => void;
};

const Item = ({
  icon,
  text,
  children,
  paddingLeft = gridSpacing[2],
  path,
  collapsed,
  toggleCollapsed,
  hasPermission = true,
  style,
  selectedObject,
  setSelectedObject,
}: ItemProps) => {
  const selected = selectedObject === path;
  return (
    <NavLink
      noUnderline
      $hasPermission={hasPermission}
      $selected={selected}
      url={path}
      style={{ paddingLeft }}
      onClick={() => {
        collapsed && toggleCollapsed && toggleCollapsed();
        setSelectedObject(path);
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {toggleCollapsed && (
          <ToggleIcon
            $hasPermission={hasPermission}
            $selected={selected}
            className={`${
              !hasPermission
                ? "fa-regular fa-lock"
                : collapsed
                  ? "fa-solid fa-plus"
                  : "fa-solid fa-minus"
            }`}
            onClick={(e: MouseEvent<HTMLElement>) => {
              if (hasPermission) {
                e.preventDefault();
                e.stopPropagation();
                toggleCollapsed();
              }
            }}
          />
        )}
        {icon && <Icon style={style} className={icon} />}
        {text}
        {children}
      </div>
    </NavLink>
  );
};

export default Item;
