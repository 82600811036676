import React from "react";

import { ToolTip } from "@lucernahealth/lucerna-health-ui";
import { styled } from "styled-components";

import { colorTheme } from "@utils";

type GetColorProps = (progress: number, isForeground: boolean) => string;

type ProgressBarProps = {
  width?: number;
  progress: number;
  getColor?: GetColorProps;
  tooltipSuffix?: string;
};

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const ProgressBackground = styled.div<{
  $getColor?: GetColorProps;
  $progress: number;
}>`
  width: 100%;
  border-radius: 99px;
  height: 8px;
  background-color: ${(props) =>
    props.$getColor
      ? props.$getColor(props.$progress, false)
      : colorTheme("neutralL4")};
`;

const Progress = styled.div<{
  $width: number;
  $progress: number;
  $getColor?: GetColorProps;
}>`
  position: absolute;
  border-radius: 99px;
  height: 8px;
  background-color: ${(props) =>
    props.$getColor
      ? props.$getColor(props.$progress, true)
      : colorTheme("primary")};
  width: ${(props) => props.$width}%;
`;

const ProgressBarV2 = ({
  width,
  progress,
  getColor,
  tooltipSuffix = "complete",
}: ProgressBarProps) => {
  const progressFloat = parseFloat(`${progress}`);

  let _width = progress * 100;
  if (_width > 100) {
    _width = 100;
  }

  return (
    <div style={{ width: width ?? "100%" }}>
      <ToolTip
        text={`${(progressFloat * 100).toFixed(2)}% ${tooltipSuffix}`}
        style={{ width: "100%" }}
      >
        <Container>
          <Progress
            $width={_width}
            $progress={progressFloat}
            $getColor={getColor}
          />
          <ProgressBackground $progress={progressFloat} $getColor={getColor} />
        </Container>
      </ToolTip>
    </div>
  );
};

export default ProgressBarV2;
