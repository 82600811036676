import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useDrop } from "react-dnd";
import { styled } from "styled-components";

const Container = styled.div`
  background: ${({
    $isOver,
    $canDrop,
    $acceptColor,
    $defaultColor,
    $rejectColor,
  }) =>
    $isOver && !$canDrop
      ? $rejectColor
      : $isOver
        ? $acceptColor
        : $defaultColor};
`;

function DragTarget({
  children,
  accept,
  onDrop,
  onHover,
  filterSource,
  acceptColor = "#8BC34A",
  rejectColor = "#ff8482",
  defaultColor = "#fff",
  style = {},
}) {
  const [canDrop, setCanDrop] = useState(true);
  const _onDrop = (obj) => {
    if (filterSource && !filterSource(obj)) return;
    onDrop(obj);
  };

  const [{ item, isOver }, drop] = useDrop({
    accept: accept,
    drop: _onDrop,
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        item: monitor.getItem(),
      };
    },
  });

  useEffect(() => {
    if (isOver) {
      if (onHover) {
        onHover();
      }

      if (filterSource) {
        setCanDrop(filterSource(item));
      }
    }
  }, [isOver]);

  return (
    <Container
      $isOver={isOver}
      $acceptColor={acceptColor}
      $rejectColor={rejectColor}
      $defaultColor={defaultColor}
      $canDrop={canDrop}
      ref={drop}
      style={style}
    >
      {children}
    </Container>
  );
}

DragTarget.propTypes = {
  children: PropTypes.any,
  /** Array of strings for the acceptance of targets */
  accept: PropTypes.array.isRequired,
  /** Callback when a source is dropped, returns an object of the source */
  onDrop: PropTypes.func.isRequired,
  /** Callback when a something is hovering over the target */
  onHover: PropTypes.func,
  /** Custom function called when source is dragged over target, passes the source object and expects a boolean in return */
  filterSource: PropTypes.func,
  /** Background color of the target when source is dragged over and can be accepted */
  acceptColor: PropTypes.string,
  /** Default background color of the target */
  defaultColor: PropTypes.string,
  /** Background color of the target when source is dragged over and is rejected */
  rejectColor: PropTypes.string,
  /** Custom styled object */
  style: PropTypes.object,
};

export default DragTarget;
