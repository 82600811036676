import { DataViz } from "@commonComponents";
import moment from "moment/moment";

import type { DashboardAdvancedFilterProps } from "../../Portal/Portal";
import type { AdvFilterProps } from "~/src/utils/tsUtils";

const getCommonFilters = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) => (filters ? DataViz.filterUtils.convertFilters(filters, filterOrder) : []);

export const uniqueNumberOfUsersQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["user.total_users"],
    order: {
      "user.total_users": "desc",
    },
    timeDimensions: [
      {
        dimension: "insights_dashboard_views.created",
        dateRange: "Last 30 days",
      },
    ],
    filters: getCommonFilters(filters, filterOrder),
  }) as const;

export const uniqueNumberOfUsersLastThirtyQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["user.total_users"],
    order: {
      "user.total_users": "desc",
    },
    timeDimensions: [
      {
        dimension: "insights_dashboard_views.created",
        dateRange: [
          moment().subtract(60, "days").format("YYYY-MM-DD"),
          moment().subtract(30, "days").format("YYYY-MM-DD"),
        ],
      },
    ],
    filters: getCommonFilters(filters, filterOrder),
  }) as const;

export const averageViewsPerUserQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: [
      "user.total_users",
      "insights_dashboard_views.total_insights_views",
    ],
    order: {
      "user.total_users": "desc",
    },
    timeDimensions: [
      {
        dimension: "insights_dashboard_views.created",
        granularity: "day",
        dateRange: "Last 30 days",
      },
    ],
    filters: getCommonFilters(filters, filterOrder),
  }) as const;

export const uniqueNumberOfGroupsQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["group.total_groups"],
    order: {
      "user.total_users": "desc",
    },
    timeDimensions: [
      {
        dimension: "insights_dashboard_views.created",
        dateRange: "Last 30 days",
      },
    ],
    filters: getCommonFilters(filters, filterOrder),
  }) as const;

export const uniqueNumberOfGroupsLastThirtyQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["group.total_groups"],
    order: {
      "user.total_users": "desc",
    },
    timeDimensions: [
      {
        dimension: "insights_dashboard_views.created",
        dateRange: [
          moment().subtract(60, "days").format("YYYY-MM-DD"),
          moment().subtract(30, "days").format("YYYY-MM-DD"),
        ],
      },
    ],
    filters: getCommonFilters(filters, filterOrder),
  }) as const;

export const usersTrendQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    order: {
      "user.created": "asc",
    },
    measures: ["user.total_users"],
    timeDimensions: [
      {
        dimension: "insights_dashboard_views.created",
        granularity: "month",
      },
    ],
    filters: getCommonFilters(filters, filterOrder),
  }) as const;

export const viewsPerUserGroupQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    order: {
      "insights_dashboard_views.total_pages": "desc",
    },
    dimensions: ["group.group_name"],
    timeDimensions: [
      {
        dimension: "insights_dashboard_views.created",
        dateRange: "Last year",
      },
    ],
    measures: [
      "insights_dashboard_views.total_pages",
      "insights_dashboard_views.total_insights_views",
    ],
    filters: getCommonFilters(filters, filterOrder),
  }) as const;
