const initialState = {
  datasets: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "DATASET_FETCH_ALL": {
      let result = action.payload.result;
      if (action.payload.isRefreshing === true) {
        result = state.datasets.result;
      }
      return Object.assign({}, state, {
        datasets: { ...action.payload, result: result },
      });
    }
    case "INITIAL_REPOPULATE_QUICKSIGHT_DATASET": {
      const repopulatedDatasets = action.payload.repopulatedDatasets;
      // Mock old datasets with initialized data
      const result = state.datasets.result.map((dataset) => {
        const foundDataset = repopulatedDatasets.find(
          (obj) => obj.uuid === dataset.uuid,
        );

        if (foundDataset) {
          dataset.ingestion_status = "INITIALIZED";
          dataset.ingestion_size = null;
        }
        return dataset;
      });
      return Object.assign({}, state, {
        datasets: { ...state.datasets, result: result },
      });
    }

    case "REPOPULATE_QUICKSIGHT_DATASET": {
      const repopulatedDatasets = action.payload.repopulatedDatasets;

      // Replace old datasets with the new repopulated datasets
      const result = state.datasets.result.map((dataset) => {
        const foundDataset = repopulatedDatasets.find(
          (obj) => obj.uuid === dataset.uuid,
        );

        if (foundDataset) {
          return foundDataset;
        }
        return dataset;
      });

      return Object.assign({}, state, {
        datasets: { ...state.datasets, result: result },
      });
    }

    default:
      return state;
  }
}
