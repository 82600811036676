import React from "react";

import { Button } from "@lucernahealth/lucerna-health-ui";
import PropTypes from "prop-types";

import { checkPermissions } from "@utils";

const ActivityStreamSlideOutButton = ({ setSlideOutIsOpened, style }) => {
  if (checkPermissions("auditlog.view_logentry")) {
    return (
      <Button
        icon="fa-history"
        onClick={() => setSlideOutIsOpened(true)}
        type="secondary"
        style={style}
        tooltip="Activity Stream"
      />
    );
  }

  return null;
};

ActivityStreamSlideOutButton.propTypes = {
  setSlideOutIsOpened: PropTypes.func,
  style: PropTypes.object,
};

export default ActivityStreamSlideOutButton;
