const initialState = {
  isFetching: true,
  error: false,
  result: [],
  simpleCohorts: {
    isFetching: true,
    error: false,
    result: [],
  },
  complexCohorts: {
    isFetching: true,
    error: false,
    result: [],
  },
  availableSymbols: {
    isFetching: true,
    error: false,
    result: {
      attributes: {},
      symbols: {
        fields: {},
        objects: {},
      },
    },
  },
  availableCohortSymbols: {
    isFetching: true,
    error: false,
    result: {
      attributes: {},
      symbols: {
        fields: {},
        objects: {},
      },
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "ATTRIBUTION_FETCH_PANELS": {
      return Object.assign({}, state, { ...state, ...action.payload });
    }

    case "ATTRIBUTION_PANEL_ADD": {
      return Object.assign({}, state, {
        result: {
          ...state.result,
          count: state.result.count + 1,
          results: [...state.result.results, action.payload],
        },
      });
    }

    case "ATTRIBUTION_PANEL_UPDATE": {
      let results = state.result.results.map((panel) => {
        if (panel.uuid === action.payload.uuid) {
          return action.payload;
        }
        return panel;
      });
      return Object.assign({}, state, {
        ...state,
        result: {
          ...state.result,
          results,
        },
      });
    }

    case "ATTRIBUTION_PANEL_DELETE": {
      return Object.assign({}, state, {
        ...state,
        result: {
          ...state.result,
          count: state.result.count - 1,
          results: state.result.results.filter(
            (metric) => metric.uuid !== action.payload.uuid,
          ),
        },
      });
    }

    case "ATTRIBUTION_PANEL_SIMPLE_COHORTS_FETCH_ALL": {
      return Object.assign({}, state, {
        simpleCohorts: action.payload,
      });
    }

    case "ATTRIBUTION_PANEL_COMPLEX_COHORTS_FETCH_ALL": {
      return Object.assign({}, state, {
        complexCohorts: action.payload,
      });
    }

    case "ATTRIBUTION_PANEL_AVAILABLE_SYMBOLS_FETCH_ALL": {
      return Object.assign({}, state, {
        availableSymbols: action.payload,
      });
    }

    default:
      return state;
  }
}
