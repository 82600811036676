import React from "react";

import { gridSpacing } from "@lucernahealth/lucerna-health-ui";
import { styled } from "styled-components";

export interface AnalyticsWidgetProps {
  ratios: number[];
  children: React.ReactNode;
}

type DynamicFlexGrowthProps = {
  ratios: number[];
};

const DynamicFlexGrowth = styled.div<DynamicFlexGrowthProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 230px;

  // Dynamic generation of flex values for each child
  ${(props) =>
    props.ratios.map(
      (ratio, index) => `
    > :nth-child(${index + 1}) {
      flex: ${ratio} 0 0;
    }
  `,
    )}

  // Add margin to all children except the last one
  > :not(:last-child) {
    margin-right: ${gridSpacing[5]}px;
  }
`;

const AnalyticsWidgetGroup = ({ ratios, children }: AnalyticsWidgetProps) => {
  if (ratios.length !== React.Children.count(children)) {
    throw new Error(
      "AnalyticsWidgetGroup - Children and ratios must be the same length",
    );
  }
  return <DynamicFlexGrowth ratios={ratios}>{children}</DynamicFlexGrowth>;
};

export default AnalyticsWidgetGroup;
