import { DataViz } from "@commonComponents";
import moment from "moment";

import type { DashboardAdvancedFilterProps } from "../../Portal/Portal";
import type { AdvFilterProps } from "~/src/utils/tsUtils";

const yearmoLast24Months = () => {
  const today = moment();
  const years = [];
  for (let i = 0; i < 24; i++) {
    years.push(today.subtract(1, "month").format("YYYYMM"));
  }

  return years;
};

export const payerTrendQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: ["roster_volume.current_yearmo", "roster_volume.payer_name"],
    measures: ["roster_volume.total_member_count"],
    filters: [
      {
        member: "roster_volume.current_yearmo",
        operator: "equals",
        values: yearmoLast24Months(),
      },
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const segmentTrendQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: [
      "roster_volume.current_yearmo",
      "roster_volume.market_segment",
    ],
    measures: ["roster_volume.total_member_count"],
    filters: [
      {
        member: "roster_volume.current_yearmo",
        operator: "equals",
        values: yearmoLast24Months(),
      },
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const subSegmentTrendQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: [
      "roster_volume.current_yearmo",
      "roster_volume.market_sub_segment",
    ],
    measures: ["roster_volume.total_member_count"],
    filters: [
      {
        member: "roster_volume.current_yearmo",
        operator: "equals",
        values: yearmoLast24Months(),
      },
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const payerBreakdownFilterDimensions: string[] = [
  "roster_volume.plan_state",
  "roster_volume.payer_name",
  "roster_volume.market_segment",
  "roster_volume.market_sub_segment",
];
