import React from "react";

import { gridSpacing, Loading } from "@lucernahealth/lucerna-health-ui";
import { styled } from "styled-components";

import FavoriteGroup from "./FavoriteGroup";
import { DashboardFilter } from "./helpers";
import SubjectItem from "./SubjectItem";
import type {
  ListAnalyticDomain,
  ListInsightsDashboard,
  UserFavorites,
} from "~/src/types";

const SubjectListContainer = styled.div`
  padding-left: ${gridSpacing[5]}px;
  padding-top: ${gridSpacing[2]}px;
  padding-right: ${gridSpacing[2]}px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

type SubjectsListProps = {
  filterValue: string;
  insightsDashboards: ListInsightsDashboard[];
  subjects: ListAnalyticDomain[];
  dashboardFilter: string;
  openGroups: string[];
  setOpenGroups: (value: string[]) => void;
  openSubjects: string[];
  handleSubjectToggle: (subjectPath: string) => void;
  isLoading: boolean;
  selectedObject: string;
  setSelectedObject: (value: string) => void;
  favoritesData: UserFavorites[];
};

const SubjectsList = ({
  filterValue,
  insightsDashboards,
  subjects,
  dashboardFilter,
  openGroups,
  setOpenGroups,
  openSubjects,
  handleSubjectToggle,
  isLoading,
  selectedObject,
  setSelectedObject,
  favoritesData,
}: SubjectsListProps) => {
  const filteredDashboards = insightsDashboards.filter(
    (dashboard) =>
      (dashboard.name.toLowerCase().includes(filterValue) ||
        dashboard.subdomain.name.toLowerCase().includes(filterValue) ||
        dashboard.subdomain.analytic_domain.name
          .toLowerCase()
          .includes(filterValue)) &&
      (dashboardFilter === dashboard.managed_by ||
        dashboardFilter === DashboardFilter.All),
  );

  const filteredSubjects = subjects
    .filter((subject) => {
      // Return all subjects if no filter is applied
      if (!filterValue && dashboardFilter === DashboardFilter.All) {
        return true;
      }

      // Return subjects if their name includes filterValue
      if (filterValue && subject.name.toLowerCase().includes(filterValue)) {
        return true;
      }

      // Check if any subdomain matches the filter criteria
      return subject.subdomains.some((subdomain) => {
        if (filterValue && subdomain.name.toLowerCase().includes(filterValue)) {
          return true;
        }

        return filteredDashboards.some(
          (dashboard) => dashboard.subdomain.uuid === subdomain.uuid,
        );
      });
    })
    .map((subject) => {
      const filteredSubdomains = subject.subdomains
        .filter((subdomain) => {
          // Return all subdomains if no filter is applied
          if (!filterValue && dashboardFilter === DashboardFilter.All) {
            return true;
          }

          // Return subdomains if their name includes filterValue or subject name includes filterValue
          if (
            filterValue &&
            (subject.name.toLowerCase().includes(filterValue) ||
              subdomain.name.toLowerCase().includes(filterValue))
          ) {
            return true;
          }

          // Check if any dashboard within the subdomain matches the criteria
          return filteredDashboards.some(
            (dashboard) => dashboard.subdomain.uuid === subdomain.uuid,
          );
        })
        .map((subdomain) => {
          return {
            ...subdomain,
            dashboards: filteredDashboards.filter(
              (dashboard) => dashboard.subdomain.uuid === subdomain.uuid,
            ),
          };
        })
        // Ensure only subdomains with matching dashboards are included when a filter is applied
        .filter((subdomain) => {
          if (filterValue || dashboardFilter !== DashboardFilter.All) {
            return subdomain.dashboards.length > 0;
          }
          return true;
        });

      return { ...subject, subdomains: filteredSubdomains };
    })
    // Ensure only subjects with matching subdomains are included when a filter is applied
    .filter((subject) => {
      if (filterValue || dashboardFilter !== DashboardFilter.All) {
        return subject.subdomains.length > 0;
      }
      return true;
    })
    .sort((a, b) => a.rank - b.rank);

  return (
    <SubjectListContainer>
      {!isLoading ? (
        <>
          <FavoriteGroup
            key="favorites"
            openSubjects={openSubjects}
            toggleCollapsed={() => handleSubjectToggle("favorites")}
            selectedObject={selectedObject}
            setSelectedObject={setSelectedObject}
            favoritesData={favoritesData}
            filteredSubjects={filteredSubjects}
            filteredDashboards={filteredDashboards}
            dashboardFilter={dashboardFilter}
            filterValue={filterValue}
          />
          {filteredSubjects.map((subject) => (
            <SubjectItem
              key={subject.uuid}
              subject={subject}
              collapsed={!openSubjects.includes(subject.uuid)}
              toggleCollapsed={() => handleSubjectToggle(subject.uuid)}
              insightsDashboards={filteredDashboards}
              openGroups={openGroups}
              setOpenGroups={setOpenGroups}
              isLoading={isLoading}
              selectedObject={selectedObject}
              setSelectedObject={setSelectedObject}
            />
          ))}
        </>
      ) : (
        <Loading center />
      )}
    </SubjectListContainer>
  );
};

export default SubjectsList;
