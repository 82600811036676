export default [
  {
    isParent: true,
    label: "Provider",
    id: "provider",
  },
  {
    cubeKey:
      "schedule_availability_provider_summary_appt_type.schedule_provider_full_name",
    label: "Full Name",
    parentId: "provider",
  },
  {
    cubeKey:
      "schedule_availability_provider_summary_appt_type.schedule_provider_specialty",
    label: "Specialty",
    parentId: "provider",
  },
  {
    cubeKey:
      "schedule_availability_provider_facility_summary_appt_type.schedule_appointment_type",
    label: "Appointment Type",
    parentId: "provider",
  },
];
