const initialState = {
  sources: {
    isFetching: true,
    error: false,
    result: [],
  },
  targets: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "CONSUMER_SET_SOURCES": {
      return Object.assign({}, state, {
        sources: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    case "CONSUMER_ADD_SOURCE": {
      return Object.assign({}, state, {
        sources: {
          ...state.sources,
          result: [...state.sources.result, action.payload],
        },
      });
    }

    case "CONSUMER_UPDATE_SOURCE": {
      let _sources = state.sources.result.map((ruleSet) => {
        if (ruleSet.identifier === action.payload.identifier) {
          return action.payload;
        }
        return ruleSet;
      });
      return Object.assign({}, state, {
        sources: {
          ...state.sources,
          result: _sources,
        },
      });
    }

    case "CONSUMER_DELETE_SOURCE": {
      let newSources = [...state.sources.result].filter(
        (source) => source.identifier !== action.payload.sourceId,
      );

      return Object.assign({}, state, {
        sources: { ...state.sources, result: newSources },
      });
    }

    case "CONSUMER_SOURCE_SET_TARGETS": {
      return Object.assign({}, state, {
        targets: action.payload,
      });
    }

    case "CONSUMER_SOURCE_UPDATE_TARGET": {
      return Object.assign({}, state, {
        targets: state.targets.map((target) => {
          if (target.uuid === action.payload.uuid) {
            return action.payload;
          }

          return target;
        }),
      });
    }

    case "CONSUMER_SOURCE_ADD_SOURCE_TO_TARGET": {
      return Object.assign({}, state, {
        targets: state.targets.map((target) => {
          if (
            target.uuid === action.payload.targetId &&
            !target.incoming_names.includes(action.payload.source)
          ) {
            target.incoming_names = [
              ...target.incoming_names,
              action.payload.source,
            ];
          }

          return target;
        }),
      });
    }

    default:
      return state;
  }
}
