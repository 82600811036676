import React, { useState } from "react";

import { Slider } from "@commonComponents";
import {
  SingleDatePicker,
  gridSpacing,
  Select,
  Switch,
  SearchText,
  Text,
  Button,
} from "@lucernahealth/lucerna-health-ui";
import moment from "moment";
import PropTypes from "prop-types";
import { styled } from "styled-components";

import { colorTheme } from "@utils";

const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${gridSpacing[3]}px;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    background: ${colorTheme("primaryL4")};
  }
`;

const OptionName = styled.div`
  margin-left: 5px;
`;

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
const OptionWrapper = styled.div`
  max-height: 260px;
  overflow: auto;
  mask: ${({ fade }) => (fade ? "linear-gradient(#000, #000, #0000)" : null)};
`;

const Options = ({
  name,
  identifier,
  type,
  options,
  gt_suffix,
  lt_suffix,
  setValue,
  values,
  filters,
  setFilters,
}) => {
  const [textFilter, setTextFilter] = useState("");
  const [fade, setFade] = useState(options?.length > 5);

  const filteredOptions = options
    ?.filter((option) =>
      option.name.toLowerCase().includes(textFilter.toLowerCase()),
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  if (type === "MULTI_OPTION") {
    return (
      <div>
        <div style={{ display: "flex" }}>
          <SearchText
            fixedWidth
            placeholder={`Search ${name.toLowerCase()}`}
            onChange={(e) => {
              setTextFilter(e.target.value);
            }}
            value={textFilter}
            style={{
              width: "100%",
              marginBottom: gridSpacing[2],
              marginRight: gridSpacing[3],
            }}
          />
          <Button
            text="Select All"
            type="secondary"
            onClick={() =>
              setFilters(options.map((option) => option.identifier))
            }
          />
          <Button
            ml
            text="Deselect All"
            type="secondary"
            onClick={() => setFilters([])}
            disabled={filters.length === 0}
          />
        </div>
        <OptionWrapper
          fade={fade && filteredOptions.length > 5}
          onScroll={(e) => {
            const scrollHeight = e.target.scrollHeight - e.target.scrollTop;
            if (scrollHeight > 320) {
              setFade(true);
            } else {
              setFade(false);
            }
          }}
        >
          {filteredOptions?.length > 0 ? (
            filteredOptions.map((option, i) => (
              <OptionItem
                key={i}
                onClick={(e) => {
                  e.preventDefault();

                  if (filters.includes(option.identifier)) {
                    setFilters(
                      filters.filter((obj) => obj !== option.identifier),
                    );
                  } else {
                    setFilters([...filters, option.identifier]);
                  }
                }}
              >
                <Switch checked={filters.includes(option.identifier)} />
                <OptionName>{option.name}</OptionName>
              </OptionItem>
            ))
          ) : (
            <div style={{ padding: gridSpacing[3] }}>No options available</div>
          )}
        </OptionWrapper>
      </div>
    );
  } else if (type === "SINGLE_OPTION") {
    return (
      <Select
        emptyOption
        placeholder={`Select ${name}`}
        value={filters}
        onChange={(e) => setFilters(e)}
        options={options.map((option) => {
          return {
            value: option.identifier,
            label: option.name,
          };
        })}
      />
    );
  } else if (type === "AGE_BEFORE_AFTER") {
    const gtDate = moment(values[`${identifier}${gt_suffix}`]);
    const ltDate = moment(values[`${identifier}${lt_suffix}`]);
    const gtAge = moment().diff(gtDate, "years") || 100;
    const ltAge = moment().diff(ltDate, "years") || 0;
    const getAgeDate = (age) =>
      moment()
        .startOf("year")
        .subtract(age, "years")
        .toISOString()
        .split("T")[0];
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: gridSpacing[3],
          }}
        >
          <p>{ltAge}</p>
          <p>-</p>
          <p>{gtAge}</p>
        </div>
        <Slider
          range
          value={[ltAge, gtAge]}
          onChange={(e) => {
            setValue(`${identifier}${lt_suffix}`, getAgeDate(e[0]));
            setValue(`${identifier}${gt_suffix}`, getAgeDate(e[1]));
          }}
          count={1}
          min={0}
          max={100}
          step={1}
          allowCross={false}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <SingleDatePicker
            label="Before"
            onChange={(e) => setValue(`${identifier}${lt_suffix}`, e)}
            value={values[`${identifier}${lt_suffix}`]}
            style={{ flexGrow: 1, marginRight: gridSpacing[2] }}
          />
          <SingleDatePicker
            label="After"
            onChange={(e) => setValue(`${identifier}${gt_suffix}`, e)}
            value={values[`${identifier}${gt_suffix}`]}
            style={{ flexGrow: 1 }}
          />
        </div>
      </>
    );
  } else if (type === "DATE_BEFORE_AFTER") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <SingleDatePicker
          label="After"
          onChange={(e) => setValue(`${identifier}${gt_suffix}`, e)}
          value={values[`${identifier}${gt_suffix}`]}
          style={{ flexGrow: 1, marginRight: gridSpacing[2] }}
        />
        <SingleDatePicker
          label="Before"
          onChange={(e) => setValue(`${identifier}${lt_suffix}`, e)}
          value={values[`${identifier}${lt_suffix}`]}
          style={{ flexGrow: 1 }}
        />
      </div>
    );
  } else if (type === "NUMERIC_RANGE") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Text
          placeholder="Minimum (optional)"
          type="number"
          min={0}
          onChange={(e) =>
            setValue(`${identifier}${gt_suffix}`, e.target.value)
          }
          value={values[`${identifier}${gt_suffix}`]}
          style={{ flexGrow: 1, marginRight: gridSpacing[2] }}
        />
        <Text
          placeholder="Maximum (optional)"
          type="number"
          min={0}
          onChange={(e) =>
            setValue(`${identifier}${lt_suffix}`, e.target.value)
          }
          value={values[`${identifier}${lt_suffix}`]}
          style={{ flexGrow: 1 }}
        />
      </div>
    );
  } else if (type === "BOOLEAN") {
    return (
      <Select
        emptyOption
        placeholder="Select True False or No Filter"
        value={filters}
        onChange={(e) => setFilters(e)}
        options={[
          {
            value: "True",
            label: "True",
          },
          {
            value: "False",
            label: "False",
          },
        ]}
      />
    );
  } else if (type === "FREEFORM") {
    return (
      <Text
        placeholder="Enter text to search this field"
        onChange={(e) => setValue(identifier, e.target.value)}
        value={values[identifier]}
      />
    );
  } else if (type === "DATE") {
    return (
      <SingleDatePicker
        label="Date"
        onChange={(e) => setValue(identifier, e)}
        value={values[identifier]}
      />
    );
  }

  return null;
};

Options.propTypes = {
  name: PropTypes.string,
  identifier: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.array,
  gt_suffix: PropTypes.string,
  lt_suffix: PropTypes.string,
  setValue: PropTypes.func,
  values: PropTypes.string,
  filters: PropTypes.string,
  setFilters: PropTypes.func,
};

export default Options;
