import ConsumerProfileRoutes from "../App/ConsumerProfile/sidebarRoutes";
import DataManagementSidebarRoutes from "../App/DataManagement/sidebarRoutes";
import InsightsSidebarRoutes from "../App/Insights/sidebarRoutes";
import ProviderDirectoryRoutes from "../App/ProviderDirectory/sidebarRoutes";
import SystemManagementSidebarRoutes from "../App/SystemManagement/sidebarRoutes";

// This is an array that holds options for the application dropdown and permission mapper
export default [
  {
    key: "LeapInsights",
    displayName: "Leap Insights",
    icon: "fa-kit fa-hdp-app-insights-portal",
    permission: "accounts.leap_insights_access",
    routes: [
      {
        header: "",
        children: [
          {
            name: "",
            route: "/leap-insights#all-published-dashboards",
            permissions: null,
          },
        ],
      },
    ],
    url: "leap-insights",
    color: "#008244",
    group: "Data Platform",
  },
  {
    key: "InsightsStudio",
    displayName: "Insights Studio",
    icon: "fa-kit fa-hdp-app-insights-studio",
    permission: "accounts.insights_studio_access",
    routes: InsightsSidebarRoutes,
    url: "insights-studio",
    color: "#F9BE28",
    group: "Data Platform",
  },
  {
    key: "DataHub",
    displayName: "Data Hub",
    icon: "fa-kit fa-hdp-app-data-hub",
    permission: "accounts.data_hub_access",
    routes: DataManagementSidebarRoutes,
    url: "data-hub",
    color: "#ED8F1C",
    group: "Data Platform",
  },
  {
    key: "Provider360",
    displayName: "Provider 360",
    icon: "fa-kit fa-hdp-app-provider",
    permission: "accounts.provider_360_access",
    routes: ProviderDirectoryRoutes,
    url: "provider-360",
    color: "#23A6F9",
    group: "Engagement",
  },
  {
    key: "Consumer360",
    displayName: "Consumer 360",
    icon: "fa-kit fa-hdp-app-consumer-360",
    permission: "accounts.consumer_360_access",
    routes: ConsumerProfileRoutes,
    url: "consumer-360",
    color: "#864CCC",
    group: "Engagement",
  },
  {
    key: "System",
    displayName: "System",
    icon: "fa-kit fa-hdp-app-settings",
    permission: "accounts.system_management_access",
    routes: SystemManagementSidebarRoutes,
    url: "system",
  },
];
