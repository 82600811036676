const initialState = {
  files: {
    isFetching: true,
    isRefreshing: false,
    isAutoFetching: false,
    error: false,
    result: [],
  },
  cohortRunOutcomes: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
  metricRunOutcomes: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
  attributionRunOutcomes: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
  recodingRunOutcomes: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
  dataModelPublishRunOutcomes: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
  otherEvents: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "MONITORING_SET_FILES": {
      return Object.assign({}, state, {
        files: {
          ...state.files,
          isFetching: action.payload.isFetching,
          isRefreshing: action.payload.isRefreshing,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    case "MONITORING_REFRESH_FILES": {
      return Object.assign({}, state, {
        files: {
          ...state.files,
          isFetching: action.payload.isFetching,
          isRefreshing: action.payload.isRefreshing,
          error: action.payload.error,
        },
      });
    }

    case "MONITORING_SET_FILES_AUTO_FETCHING": {
      return Object.assign({}, state, {
        files: {
          ...state.files,
          isAutoFetching: action.payload.value,
        },
      });
    }

    case "MONITORING_FETCH_COHORT_RUN_OUTCOMES": {
      let result = action.payload.result;
      if (action.payload.isRefreshing) {
        result = state.cohortRunOutcomes.result;
      }

      return Object.assign({}, state, {
        cohortRunOutcomes: {
          ...action.payload,
          result: result,
        },
      });
    }

    case "MONITORING_FETCH_METRIC_RUN_OUTCOMES": {
      let result = action.payload.result;
      if (action.payload.isRefreshing) {
        result = state.metricRunOutcomes.result;
      }

      return Object.assign({}, state, {
        metricRunOutcomes: {
          ...action.payload,
          result: result,
        },
      });
    }

    case "MONITORING_FETCH_ATTRIBUTION_RUN_OUTCOMES": {
      let result = action.payload.result;
      if (action.payload.isRefreshing) {
        result = state.attributionRunOutcomes.result;
      }

      return Object.assign({}, state, {
        attributionRunOutcomes: {
          ...action.payload,
          result: result,
        },
      });
    }

    case "MONITORING_FETCH_RECODING_RUN_OUTCOMES": {
      let result = action.payload.result;
      if (action.payload.isRefreshing) {
        result = state.recodingRunOutcomes.result;
      }

      return Object.assign({}, state, {
        recodingRunOutcomes: {
          ...action.payload,
          result: result,
        },
      });
    }

    case "MONITORING_FETCH_DATA_MODEL_PUBLISH_RUN_OUTCOMES": {
      let result = action.payload.result;
      if (action.payload.isRefreshing) {
        result = state.dataModelPublishRunOutcomes.result;
      }

      return Object.assign({}, state, {
        dataModelPublishRunOutcomes: {
          ...action.payload,
          result: result,
        },
      });
    }

    case "MONITORING_FETCH_OTHER_EVENTS": {
      let result = action.payload.result;
      if (action.payload.isRefreshing) {
        result = state.otherEvents.result;
      }

      return Object.assign({}, state, {
        otherEvents: {
          ...action.payload,
          result: result,
        },
      });
    }

    default:
      return state;
  }
}
