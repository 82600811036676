const baseUrl = "/insights-studio";

export default {
  baseUrl,
  COHORT_GROUPS: `${baseUrl}/cohort/groups`,
  COHORT_COMPLEX_RULES: `${baseUrl}/cohort/complex-rules`,
  COHORT_SIMPLE_RULES: `${baseUrl}/cohort/simple-rules`,
  INSIGHTS_CONSOLE: `${baseUrl}/insights/console`,
  INSIGHTS_DATASET: `${baseUrl}/insights/dataset`,
  INSIGHTS_PORTAL_CONFIGURATION: `${baseUrl}/insights/portal-configuration`,
};
