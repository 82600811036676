import type { panelStatisticsQuery } from "~/src/App/LeapInsights/Components/Dashboards/CapacityAnalysis/Queries";
import type {
  CubeQueryFunc,
  CubeQueryResultRawDataKeyType,
  CubeQueryResultRawDataType,
} from "~/src/utils/tsUtils";

export const getStackedBarChartData = <TQuery extends CubeQueryFunc>(
  data: CubeQueryResultRawDataType<TQuery>,
  optionsKey: keyof CubeQueryResultRawDataKeyType<TQuery>,
  stackKey: keyof CubeQueryResultRawDataKeyType<TQuery>,
  countKey: keyof CubeQueryResultRawDataKeyType<TQuery>,
  format: "percentage" | "number" = "percentage",
  stackKeyMap?: Record<string, string>,
) => {
  const options = [...new Set(data.map((obj) => obj[optionsKey]))];

  const objectData: Record<string, number[]> = {};

  data.forEach((obj) => {
    const stateIndex = options.indexOf(obj[optionsKey]);

    if (objectData[obj[stackKey]]) {
      objectData[obj[stackKey]][stateIndex] = parseInt(obj[countKey]);
    } else {
      const stackedData = new Array(options.length).fill(0);

      stackedData[stateIndex] = parseInt(obj[countKey]);

      objectData[obj[stackKey]] = stackedData;
    }
  });

  const totals: number[] = [];
  Object.keys(objectData).forEach((key) => {
    objectData[key].forEach((value, index) => {
      if (totals[index]) {
        totals[index] += value;
      } else {
        totals[index] = value;
      }
    });
  });

  if (stackKeyMap) {
    Object.keys(objectData).forEach((key) => {
      objectData[stackKeyMap[key]] = objectData[key];
      delete objectData[key];
    });
  }

  return {
    options,
    data: Object.keys(objectData).map((key) => {
      return {
        name: key,
        data:
          format === "percentage"
            ? objectData[key].map((value, index) =>
                parseFloat(((value / totals[index]) * 100).toFixed(2)),
              )
            : objectData[key],
      };
    }),
  };
};

export const rollUpProvidersToPanels = (
  panelStatisticResult: CubeQueryResultRawDataType<typeof panelStatisticsQuery>,
) => {
  const data = panelStatisticResult.filter(
    (x) => x["assigned_drilldown.panel_id"],
  );

  const uniquePanels = [
    ...new Set(data.map((d) => d["assigned_drilldown.panel_id"])),
  ];
  return uniquePanels.map((panelId) => {
    const panel = data.filter(
      (d) => d["assigned_drilldown.panel_id"] === panelId,
    );
    return {
      "assigned_drilldown.panel_id": panelId,
      "assigned_drilldown.panel_name":
        panel[0]["assigned_drilldown.panel_name"],
      "assigned_drilldown.total_assigned": panel
        .map((d) => parseFloat(d["assigned_drilldown.total_assigned"]) || 0)
        .reduce((acc, curr) => acc + curr, 0),
      "assigned_drilldown.total_base_capacity": panel
        .map(
          (d) => parseFloat(d["assigned_drilldown.total_base_capacity"]) || 0,
        )
        .reduce((acc, curr) => acc + curr, 0),
      "assigned_drilldown.total_max_capacity": panel
        .map((d) => parseFloat(d["assigned_drilldown.total_max_capacity"]) || 0)
        .reduce((acc, curr) => acc + curr, 0),
      "assigned_drilldown.total_assigned_540_day_flag": panel
        .map(
          (d) =>
            parseFloat(d["assigned_drilldown.total_assigned_540_day_flag"]) ||
            0,
        )
        .reduce((acc, curr) => acc + curr, 0),
      "assigned_drilldown.total_any_540_day_flag": panel
        .map(
          (d) =>
            parseFloat(d["assigned_drilldown.total_any_540_day_flag"]) || 0,
        )
        .reduce((acc, curr) => acc + curr, 0),
    };
  });
};

export const assignedDrillDownAdvFilters = [
  {
    cubeKey: "assigned_drilldown.roster_market_segment_current",
    label: "Roster Market Segment",
  },
  {
    cubeKey: "assigned_drilldown.roster_payer_name_current",
    label: "Roster Payer Name",
  },
  {
    cubeKey: "assigned_drilldown.panel_name",
    label: "Panel Name",
  },
  {
    cubeKey: "assigned_drilldown.provider_first_name",
    label: "Provider First Name",
  },
  {
    cubeKey: "assigned_drilldown.provider_last_name",
    label: "Provider Last Name",
  },
  {
    cubeKey: "assigned_drilldown.provider_npi",
    label: "Provider NPI",
  },
  {
    cubeKey: "assigned_drilldown.facility_state",
    label: "Facility State",
  },
  {
    cubeKey: "assigned_drilldown.facility_zip",
    label: "Facility Zip",
  },
  {
    cubeKey: "assigned_drilldown.facility_county",
    label: "Facility County",
  },
  {
    cubeKey: "assigned_drilldown.facility_region",
    label: "Facility Region",
  },
];
