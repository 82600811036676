import React, { useContext } from "react";

import { DataViz } from "@commonComponents";
import moment from "moment";

import {
  averageTenureQuery,
  growthTrendQuery,
  populationVolumeQuery,
  summaryFilterDimensions,
  volumeByMarketSegmentByStateQuery,
  volumeByMarketSegmentQuery,
  volumeByPayerQuery,
} from "./Queries";
import { PortalContext } from "../../Portal/Portal";
import type { CubeQueryResultRawDataType } from "~/src/utils/tsUtils";
import { useCubeApiQuery } from "~/src/utils/tsUtils";

const RosterSummary = () => {
  const { filters, filterOrder } = useContext(PortalContext);

  DataViz.filterUtils.useFilterOptionsNormalized({
    dimensions: summaryFilterDimensions,
  });

  const {
    resultSet: populationVolumeResult,
    error: populationVolumeError,
    isLoading: populationVolumeLoading,
  } = useCubeApiQuery(populationVolumeQuery(filters, filterOrder));

  const {
    resultSet: averageTenureResult,
    error: averageTenureError,
    isLoading: averageTenureLoading,
  } = useCubeApiQuery(averageTenureQuery(filters, filterOrder));

  const {
    resultSet: volumeByMarketSegmentResult,
    error: volumeByMarketSegmentError,
    isLoading: volumeByMarketSegmentLoading,
  } = useCubeApiQuery(volumeByMarketSegmentQuery(filters, filterOrder));

  const {
    resultSet: volumeByMarketSegmentByStateResult,
    error: volumeByMarketSegmentByStateError,
    isLoading: volumeByMarketSegmentByStateLoading,
  } = useCubeApiQuery(volumeByMarketSegmentByStateQuery(filters, filterOrder));

  const {
    resultSet: volumeByPayerQueryResult,
    error: volumeByPayerQueryError,
    isLoading: volumeByPayerQueryLoading,
  } = useCubeApiQuery(volumeByPayerQuery(filters, filterOrder));

  const {
    resultSet: growthTrendResult,
    error: growthTrendError,
    isLoading: growthTrendLoading,
  } = useCubeApiQuery(growthTrendQuery(filters, filterOrder));

  const getPopulationVolume = (
    data: CubeQueryResultRawDataType<typeof populationVolumeQuery>,
  ) => {
    let totalMemberCount = 0;
    let lastMonthMemberCount = 0;

    data.forEach((obj) => {
      totalMemberCount += parseInt(obj["roster_volume.total_member_count"]);
      lastMonthMemberCount += parseInt(
        obj["roster_volume.total_last_month_member_count"],
      );
    });

    const lastMonthDif = totalMemberCount - lastMonthMemberCount;
    const trend = parseFloat(
      ((lastMonthDif / lastMonthMemberCount) * 100).toFixed(2),
    );

    return {
      total: totalMemberCount,
      trend,
      lastMonthMemberDifference: lastMonthDif,
    };
  };

  const {
    total: totalPopulationVolume,
    trend: populationVolumeTrend,
    lastMonthMemberDifference: populationLastMonthPopulationDif,
  } = getPopulationVolume(populationVolumeResult?.rawData() ?? []);

  const getAverageTenure = (data: { "roster_volume.avg_tenure": string }[]) => {
    let total = 0;
    let count = 0;
    data.forEach((obj) => {
      if (obj["roster_volume.avg_tenure"] !== null) {
        total += parseInt(obj["roster_volume.avg_tenure"]);
        count++;
      }
    });

    return Math.trunc((total / count) * 100) / 100;
  };

  const getMarketSegmentBarChartData = (
    data: CubeQueryResultRawDataType<typeof volumeByMarketSegmentByStateQuery>,
    type: "percent" | "number",
  ) => {
    const marketSegmentStates = [
      ...new Set(data.map((obj) => obj["roster_volume.plan_state"])),
    ];

    const volumeByMarketSegmentBySegmentData: Record<string, number[]> = {};

    data.forEach((obj) => {
      const stateIndex = marketSegmentStates.indexOf(
        obj["roster_volume.plan_state"],
      );

      if (
        volumeByMarketSegmentBySegmentData[obj["roster_volume.market_segment"]]
      ) {
        volumeByMarketSegmentBySegmentData[obj["roster_volume.market_segment"]][
          stateIndex
        ] = parseInt(obj["roster_volume.total_member_count"]);
      } else {
        const stackedData = new Array(marketSegmentStates.length).fill(0);

        stackedData[stateIndex] = parseInt(
          obj["roster_volume.total_member_count"],
        );

        volumeByMarketSegmentBySegmentData[
          obj["roster_volume.market_segment"]
        ] = stackedData;
      }
    });

    const totals: number[] = [];
    Object.keys(volumeByMarketSegmentBySegmentData).forEach((key) => {
      volumeByMarketSegmentBySegmentData[key].forEach((value, index) => {
        if (totals[index]) {
          totals[index] += value;
        } else {
          totals[index] = value;
        }
      });
    });

    const chartData = Object.keys(volumeByMarketSegmentBySegmentData).map(
      (key) => {
        return {
          name: key,
          data: volumeByMarketSegmentBySegmentData[key].map((value, index) => {
            if (type === "percent") {
              return parseFloat(((value / totals[index]) * 100).toFixed(2));
            } else {
              return value;
            }
          }),
        };
      },
    );

    return {
      data: chartData,
      options: marketSegmentStates,
    };
  };

  const getPayerPieChartData = () => {
    const volumeByPayer: CubeQueryResultRawDataType<typeof volumeByPayerQuery> =
      volumeByPayerQueryResult?.rawData() ?? [];

    return (
      volumeByPayer.map((data) => {
        return {
          amount: parseInt(data["roster_volume.total_member_count"]),
          name: data["roster_volume.payer_name"],
        };
      }) ?? []
    );
  };

  const getMarketSegmentPieChartData = () => {
    const volumeByMarketSegment: CubeQueryResultRawDataType<
      typeof volumeByMarketSegmentQuery
    > = volumeByMarketSegmentResult?.rawData() ?? [];

    return (
      volumeByMarketSegment.map((data) => {
        return {
          amount: parseInt(data["roster_volume.total_member_count"]),
          name: data["roster_volume.market_segment"],
        };
      }) ?? []
    );
  };

  const getGrowthLineTrend = () => {
    const growthTrend: CubeQueryResultRawDataType<typeof growthTrendQuery> =
      growthTrendResult?.rawData() ?? [];

    const years = [
      ...new Set(
        growthTrend.map((data) => data["roster_volume.current_yearmo_year"]),
      ),
    ].sort((a, b) => parseInt(a) - parseInt(b));

    const data: {
      name: string;
      data: number[];
    }[] = [];
    years.forEach((year) => {
      const monthData = new Array(12).fill(null);

      growthTrend
        .filter((obj) => obj["roster_volume.current_yearmo_year"] === year)
        .forEach((obj) => {
          monthData[parseInt(obj["roster_volume.current_yearmo_month"]) - 1] =
            parseInt(obj["roster_volume.total_member_count"]);
        });

      data.push({
        name: year,
        data: monthData,
      });
    });

    return {
      data,
      options: [
        ...new Array(12)
          .fill("")
          .map((_, i) => moment(i + 1, "M").format("MMM")),
      ],
    };
  };

  return (
    <>
      <DataViz.Row>
        <DataViz.NumberChart
          loading={populationVolumeLoading}
          error={populationVolumeError && "Something went wrong"}
          type="metric"
          toolTip="Total number of individuals currently on payer rosters"
          title="Total Roster Volume"
          unit="members"
          numerator={totalPopulationVolume}
        />
        <DataViz.NumberChart
          loading={populationVolumeLoading}
          error={populationVolumeError && "Something went wrong"}
          toolTip="Change in roster size compared to last month period"
          type="metric"
          title="Roster Volume Change"
          unit="members"
          numerator={populationLastMonthPopulationDif}
          trendDirection={populationVolumeTrend > 0 ? "up" : "down"}
          trendPercentage={populationVolumeTrend}
          trendToolTip="Since last month"
        />
        <DataViz.NumberChart
          loading={averageTenureLoading}
          error={averageTenureError && "Something went wrong"}
          toolTip="Average roster duration of individuals on current roster"
          type="metric"
          title="Average Roster Tenure"
          unit="months"
          numerator={getAverageTenure(averageTenureResult?.rawData() ?? [])}
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.BarChart
          title="Roster Volume Distribution by Market Segment"
          toolTip="Volume distribution across market segment for each state"
          subtitle="Click on a legend to hide the corresponding series from the chart"
          loading={volumeByMarketSegmentByStateLoading}
          error={volumeByMarketSegmentByStateError && "Something went wrong"}
          type="stacked"
          yAxisLabel="Members"
          {...getMarketSegmentBarChartData(
            volumeByMarketSegmentByStateResult?.rawData() ?? [],
            "number",
          )}
        />
        <DataViz.BarChart
          title="Roster Volume Distribution by Market Segment"
          toolTip="Volume distribution across market segment for each state"
          subtitle="Click on a legend to hide the corresponding series from the chart"
          loading={volumeByMarketSegmentByStateLoading}
          error={volumeByMarketSegmentByStateError && "Something went wrong"}
          type="stacked"
          yAxisLabel="% of Market Segment"
          highlightTooltipSuffix="%"
          maxNumber={100}
          {...getMarketSegmentBarChartData(
            volumeByMarketSegmentByStateResult?.rawData() ?? [],
            "percent",
          )}
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.PieChart
          title="Roster Volume by Payer"
          toolTip="Current volume breakdown grouped by payer"
          loading={volumeByPayerQueryLoading}
          error={volumeByPayerQueryError && "Something went wrong"}
          data={getPayerPieChartData()}
          label="Members"
        />
        <DataViz.PieChart
          title="Roster Volume by Market Segment"
          toolTip="Volume distribution across market segments"
          error={volumeByMarketSegmentError && "Something went wrong"}
          data={getMarketSegmentPieChartData()}
          loading={volumeByMarketSegmentLoading}
          label="Members"
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.LineChart
          title="Roster Growth Trend"
          toolTip="Analysis of annual changes in roster size"
          subtitle="Click on a legend to hide the corresponding series from the chart"
          loading={growthTrendLoading}
          error={growthTrendError && "Something went wrong"}
          yAxisLabel="Total Members"
          {...getGrowthLineTrend()}
        />
      </DataViz.Row>
    </>
  );
};

export default RosterSummary;
