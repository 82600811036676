import React from "react";

import PropTypes from "prop-types";
import { styled } from "styled-components";

import { getActivityDetails } from "./helpers";

const ExpandedDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ExpandedBar = styled.div`
  width: 3px;
  background: ${(props) => props.color};
  margin-left: 15px;
`;

const ExpandedDetails = styled.div`
  margin-right: 30px;
  width: 100%;
  padding: 10px;
`;

const LogDetails = ({ data }) => {
  const renderExpandedDetails = () => {
    const { changes, changes_friendly, action } = data;
    if (!data.access_restricted) {
      if (action === "create") {
        return Object.keys(changes).map((key) => (
          <div style={{ whiteSpace: "normal" }} key={key}>
            <b>{changes[key].label}</b>: {changes[key].new_value_label}
          </div>
        ));
      } else if (action === "delete") {
        return Object.keys(changes).map((key) => (
          <div style={{ whiteSpace: "normal" }} key={key}>
            <b>{changes[key].label}</b>:{" "}
            <del>{changes[key].original_value_label}</del>
          </div>
        ));
      } else {
        return (
          <ul style={{ marginLeft: -25 }}>
            {changes_friendly.map((change, index) => (
              <li style={{ whiteSpace: "normal" }} key={index}>
                {change}
              </li>
            ))}
          </ul>
        );
      }
    } else {
      return <div>You do not have permission to view the changes</div>;
    }
  };

  const activityDetails = getActivityDetails(data.action);

  return (
    <ExpandedDetailsContainer>
      <ExpandedBar color={activityDetails.background} />
      <ExpandedDetails>{renderExpandedDetails()}</ExpandedDetails>
    </ExpandedDetailsContainer>
  );
};

LogDetails.propTypes = {
  data: PropTypes.object,
};

export default LogDetails;
