import React from "react";

import PropTypes from "prop-types";
import Slider from "rc-slider";
import { styled } from "styled-components";

import { colorTheme } from "@utils";
import "rc-slider/assets/index.css";

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  b {
    font-weight: 500;
    margin-right: 10px;
  }

  .rc-slider-handle-dragging {
    box-shadow: 0 0 0 5px
      ${(props) => (props.error ? colorTheme("danger") : colorTheme("primary"))} !important;
  }

  .rc-slider-handle {
    opacity: 1;
  }
`;

const SliderComponent = (props) => {
  const { color, error } = props;

  return (
    <Wrapper theme={colorTheme} error={error}>
      <Slider
        handleStyle={{
          borderColor: error
            ? colorTheme("danger")
            : color || colorTheme("primary"),
        }}
        trackStyle={{
          background: error
            ? colorTheme("danger")
            : color || colorTheme("primary"),
        }}
        {...props}
      />
    </Wrapper>
  );
};

SliderComponent.propTypes = {
  color: PropTypes.string,
  error: PropTypes.bool,
};

export default SliderComponent;
