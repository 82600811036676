export default [
  {
    isParent: true,
    label: "Attribution",
    id: "attribution",
  },
  {
    cubeKey: "assigned_consumer_appointments.attribution_panel_name",
    label: "Panel Name",
    parentId: "attribution",
  },
  {
    cubeKey: "assigned_consumer_appointments.attribution_facility_name",
    label: "Facility Name",
    parentId: "attribution",
  },
  {
    cubeKey: "assigned_consumer_appointments.attribution_facility_state",
    label: "Facility State",
    parentId: "attribution",
  },
  {
    cubeKey: "assigned_consumer_appointments.attribution_facility_zip",
    label: "Facility Zip",
    parentId: "attribution",
  },
  {
    isParent: true,
    label: "Appointment",
    id: "appointment",
  },
  {
    cubeKey: "assigned_consumer_appointments.appointment_category",
    label: "Appointment Category",
    parentId: "appointment",
  },
  {
    cubeKey: "assigned_consumer_appointments.appointment_type",
    label: "Appointment Type",
    parentId: "appointment",
  },
  {
    cubeKey: "assigned_consumer_appointments.appointment_provider_npi",
    label: "Provider NPI",
    parentId: "appointment",
  },
  {
    isParent: true,
    label: "Consumer Demographics",
    id: "consumer-demographics",
  },
  {
    cubeKey: "consumer.enrich_gender",
    label: "Gender",
    parentId: "consumer-demographics",
  },
  {
    cubeKey: "consumer.enrich_state",
    label: "State",
    parentId: "consumer-demographics",
  },
  {
    cubeKey: "consumer.enrich_city",
    label: "City",
    parentId: "consumer-demographics",
  },
  {
    cubeKey: "consumer.enrich_zip",
    label: "Zip",
    parentId: "consumer-demographics",
  },
  {
    isParent: true,
    label: "Active Roster",
    id: "active-roster",
  },
  {
    cubeKey: "roster_active.plan_state",
    label: "Plan State",
    parentId: "active-roster",
  },
  {
    cubeKey: "roster_active.payer_name",
    label: "Payer Name",
    parentId: "active-roster",
  },
  {
    cubeKey: "roster_active.market_segment",
    label: "Market Segment",
    parentId: "active-roster",
  },
  {
    cubeKey: "roster_active.market_sub_segment",
    label: "Market Sub Segment",
    parentId: "active-roster",
  },
];
