import React, { useContext } from "react";

import { DataViz } from "@commonComponents";

import {
  marketSegmentBreakdownQuery,
  payerBreakdownDimensions,
  payerBreakdownQuery,
} from "./Queries";
import { PortalContext } from "../../Portal/Portal";
import { getStackedBarChartData } from "../Common/attributionCommon";
import { useCubeApiQuery } from "~/src/utils/tsUtils";

const PayerBreakdown = () => {
  const { filters, filterOrder } = useContext(PortalContext);

  DataViz.filterUtils.useFilterOptionsNormalized({
    dimensions: payerBreakdownDimensions,
  });

  const {
    resultSet: payerBreakdownResult,
    error: payerBreakdownError,
    isLoading: payerBreakdownLoading,
  } = useCubeApiQuery(payerBreakdownQuery(filters, filterOrder));

  const {
    resultSet: marketSegmentBreakdownResult,
    error: marketSegmentBreakdownError,
    isLoading: marketSegmentBreakdownLoading,
  } = useCubeApiQuery(marketSegmentBreakdownQuery(filters, filterOrder));

  return (
    <>
      <DataViz.Row>
        <DataViz.BarChart
          title="Payer Volume"
          toolTip="Breakdown of roster information of assigned consumers at the payer level for each panel"
          subtitle="Click on a legend to hide the corresponding series from the chart"
          loading={payerBreakdownLoading}
          error={payerBreakdownError && "Something went wrong"}
          type="stacked"
          yAxisLabel="Assigned Members"
          {...getStackedBarChartData<typeof payerBreakdownQuery>(
            payerBreakdownResult?.rawData() ?? [],
            "assigned_consumer_detail.panel_name",
            "roster_active.payer_name",
            "assigned_consumer_detail.total_luc_id_count",
            "number",
          )}
          formatter={function () {
            return `${this.series.name}: ${this.percentage?.toFixed(2)}%`;
          }}
        />
        <DataViz.BarChart
          title="Payer Distribution"
          toolTip="Breakdown of roster information of assigned individual as percentage at the payer level for each panel"
          subtitle="Click on a legend to hide the corresponding series from the chart"
          loading={payerBreakdownLoading}
          error={payerBreakdownError && "Something went wrong"}
          type="stacked"
          yAxisLabel="% of Panel"
          highlightTooltipSuffix="%"
          maxNumber={100}
          {...getStackedBarChartData<typeof payerBreakdownQuery>(
            payerBreakdownResult?.rawData() ?? [],
            "assigned_consumer_detail.panel_name",
            "roster_active.payer_name",
            "assigned_consumer_detail.total_luc_id_count",
          )}
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.BarChart
          title="Market Segment Volume"
          toolTip="Breakdown of roster information of assigned consumers at the market"
          subtitle="Click on a legend to hide the corresponding series from the chart"
          loading={marketSegmentBreakdownLoading}
          error={marketSegmentBreakdownError && "Something went wrong"}
          type="stacked"
          yAxisLabel="Assigned Members"
          {...getStackedBarChartData<typeof marketSegmentBreakdownQuery>(
            marketSegmentBreakdownResult?.rawData() ?? [],
            "assigned_consumer_detail.panel_name",
            "roster_active.market_segment",
            "assigned_consumer_detail.total_luc_id_count",
            "number",
          )}
          formatter={function () {
            return `${this.series.name}: ${this.percentage?.toFixed(2)}%`;
          }}
        />
        <DataViz.BarChart
          title="Market Segment Distribution"
          toolTip="Breakdown of roster information of assigned individual as percentage at the market segment level for each panel"
          subtitle="Click on a legend to hide the corresponding series from the chart"
          loading={marketSegmentBreakdownLoading}
          error={marketSegmentBreakdownError && "Something went wrong"}
          type="stacked"
          yAxisLabel="% of Panel"
          highlightTooltipSuffix="%"
          maxNumber={100}
          {...getStackedBarChartData<typeof marketSegmentBreakdownQuery>(
            marketSegmentBreakdownResult?.rawData() ?? [],
            "assigned_consumer_detail.panel_name",
            "roster_active.market_segment",
            "assigned_consumer_detail.total_luc_id_count",
          )}
        />
      </DataViz.Row>
    </>
  );
};

export default PayerBreakdown;
