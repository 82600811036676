import React, { useContext, useState } from "react";

import type { TQueryOrderArray } from "@cubejs-client/core";
import { TableLink, PivotTable } from "@lucernahealth/lucerna-health-ui";
import type { ColumnDef, GroupingState } from "@tanstack/react-table";
import { PROVIDER_360_URLS } from "@urls";

import { numberWithCommas } from "@utils";

import { PortalContext } from "../../Portal/Portal";
import {
  topFacilityStatisticsQuery,
  topProviderStatisticsQuery,
} from "../CapacityAnalysis/Queries";
import { DataViz } from "~/src/CommonComponents";
import Header from "~/src/CommonComponents/DataVisualization/Common/Header";
import type { ColumnMapItem } from "~/src/CommonComponents/TableExport/TableExport";
import { setTableExport } from "~/src/CommonComponents/TableExport/TableExport";
import { useCubeJsApi } from "~/src/utils/CubeJsApiContext";
import { parseToFixed } from "~/src/utils/stringUtils";
import type {
  CubeQueryResultRawDataType,
  CubePagination,
} from "~/src/utils/tsUtils";
import { useCubeApiQuery, cubeFetch } from "~/src/utils/tsUtils";

type TopFacilityStatisticsRawData = CubeQueryResultRawDataType<
  typeof topFacilityStatisticsQuery
>;

type TopProviderStatisticsRawData = CubeQueryResultRawDataType<
  typeof topProviderStatisticsQuery
>;

type ProviderFacilityRow = TopFacilityStatisticsRawData[number] &
  TopProviderStatisticsRawData[number];

const topFacilityColumns: ColumnDef<ProviderFacilityRow>[] = [
  {
    header: "Facility Name",
    id: "facility_name",
    getGroupingValue: (row) => row["assigned_drilldown.facility_id"],
    size: 200,
    cell: (props) => (
      <TableLink
        newTab
        url={`${PROVIDER_360_URLS.PROVIDER_DIRECTORY_FACILITIES}/view/${props.row.original["assigned_drilldown.facility_id"]}`}
        style={{ display: "inline" }}
      >
        {props.row.original["assigned_drilldown.facility_name"]}
      </TableLink>
    ),
  },
  {
    header: "Provider Name",
    id: "provider_full_name",
    cell: (props) => (
      <TableLink
        newTab
        url={`${PROVIDER_360_URLS.PROVIDER_DIRECTORY_PROVIDERS}/view/${props.row.original["assigned_drilldown.provider_id"]}`}
      >
        {props.row.original["assigned_drilldown.provider_first_name"]}{" "}
        {props.row.original["assigned_drilldown.provider_last_name"]}
      </TableLink>
    ),
  },
  {
    header: "Panel Name",
    id: "panel_name",
    cell: (props) => (
      <TableLink
        newTab
        url={`${PROVIDER_360_URLS.ATTRIBUTION_PANELS}/view/${props.row.original["assigned_drilldown.panel_id"]}`}
      >
        {props.row.original["assigned_drilldown.panel_name"]}
      </TableLink>
    ),
  },
  {
    header: "Total Assigned",
    accessorFn: (row) => parseInt(row["assigned_drilldown.total_assigned"]),
    id: "total_assigned",
    cell: (props) =>
      numberWithCommas(props.row.original["assigned_drilldown.total_assigned"]),
  },
  {
    header: "Base Capacity",
    accessorFn: (row) =>
      parseInt(row["assigned_drilldown.total_base_capacity"]),
    id: "base_capacity",
    cell: ({ getValue }) => numberWithCommas(getValue<number>()),
  },
  {
    header: "Base Capacity Ratio",
    accessorFn: (row) =>
      (parseInt(row["assigned_drilldown.total_assigned"]) /
        parseInt(row["assigned_drilldown.total_base_capacity"])) *
      100,
    id: "base_capacity_ratio",
    cell: ({ getValue }) =>
      parseToFixed({ input: getValue<number>(), postFix: "%" }),
  },
  {
    header: "Total Capacity",
    accessorFn: (row) => parseInt(row["assigned_drilldown.total_max_capacity"]),
    id: "total_capacity",
    cell: ({ getValue }) => numberWithCommas(getValue<number>()),
  },
  {
    header: "Total Capacity Ratio",
    accessorFn: (row) =>
      (parseInt(row["assigned_drilldown.total_assigned"]) /
        parseInt(row["assigned_drilldown.total_max_capacity"])) *
      100,
    id: "total_capacity_ratio",
    cell: ({ getValue }) =>
      parseToFixed({ input: getValue<number>(), postFix: "%" }),
  },
  {
    header: "Assigned Provider Seen",
    id: "all_time_assigned_seen_lucid_ratio",
    cell: (props) =>
      parseToFixed({
        input:
          parseFloat(
            props.row.original[
              "assigned_drilldown.total_assigned_540_day_flag"
            ],
          ) /
          parseFloat(props.row.original["assigned_drilldown.total_assigned"]),
        multiplier: 100,
        postFix: "%",
      }),
  },
  {
    header: "Any Provider Seen",
    id: "all_time_any_provider_seen_lucid_ratio",
    cell: (props) =>
      parseToFixed({
        input:
          parseFloat(
            props.row.original["assigned_drilldown.total_any_540_day_flag"],
          ) /
          parseFloat(props.row.original["assigned_drilldown.total_assigned"]),
        postFix: "%",
        multiplier: 100,
      }),
  },
];

const dimensions = [
  "assigned_drilldown.roster_market_segment_current",
  "assigned_drilldown.roster_payer_name_current",
  "assigned_drilldown.panel_name",
  "assigned_drilldown.provider_npi",
  "assigned_drilldown.provider_last_name",
  "assigned_drilldown.provider_first_name",
  "assigned_drilldown.facility_state",
  "assigned_drilldown.facility_zip",
  "assigned_drilldown.facility_county",
  "assigned_drilldown.facility_region",
];

const pageSize = 25;

export const csvDownloadColumnMap: ColumnMapItem[] = [
  {
    csvColumn: "facility_name",
    jsColumn: "assigned_drilldown.facility_name",
  },
  {
    csvColumn: "facility_id",
    jsColumn: "assigned_drilldown.facility_id",
  },
  {
    csvColumn: "total_assigned",
    jsColumn: (row) =>
      numberWithCommas(row["assigned_drilldown.total_assigned"]),
  },
  {
    csvColumn: "base_capacity",
    jsColumn: (row) =>
      numberWithCommas(row["assigned_drilldown.total_base_capacity"]),
  },
  {
    csvColumn: "base_capacity_ratio",
    jsColumn: (row) =>
      parseToFixed({
        input:
          (parseInt(row["assigned_drilldown.total_assigned"]) /
            parseInt(row["assigned_drilldown.total_base_capacity"])) *
          100,
        postFix: "%",
      }),
  },
  {
    csvColumn: "total_capacity",
    jsColumn: (row) =>
      numberWithCommas(row["assigned_drilldown.total_max_capacity"]),
  },
  {
    csvColumn: "total_capacity_ratio",
    jsColumn: (row) =>
      parseToFixed({
        input:
          (parseInt(row["assigned_drilldown.total_assigned"]) /
            parseInt(row["assigned_drilldown.total_max_capacity"])) *
          100,
        postFix: "%",
      }),
  },
  {
    csvColumn: "assigned_provider_seen",
    jsColumn: (row) =>
      parseToFixed({
        input:
          parseFloat(row["assigned_drilldown.total_assigned_540_day_flag"]) /
          parseFloat(row["assigned_drilldown.total_assigned"]),
        multiplier: 100,
        postFix: "%",
      }),
  },
  {
    csvColumn: "any_provider_seen",
    jsColumn: (row) =>
      parseToFixed({
        input:
          parseFloat(row["assigned_drilldown.total_any_540_day_flag"]) /
          parseFloat(row["assigned_drilldown.total_assigned"]),
        postFix: "%",
        multiplier: 100,
      }),
  },
];

const FacilityAnalysis = () => {
  const { filters, filterOrder } = useContext(PortalContext);
  const [sorting, setSorting] = useState<TQueryOrderArray>([]);
  const [grouping, setGrouping] = useState<GroupingState>(["facility_name"]);
  const [pagination, setPagination] = useState<CubePagination>({
    limit: pageSize,
    offset: 0,
  });

  const { cubejsApi } = useCubeJsApi();

  DataViz.filterUtils.useFilterOptionsNormalized({
    dimensions,
  });

  const {
    resultSet: topFacilityStatisticsResult,
    isLoading: topFacilityStatisticsLoading,
    error: topFacilityStatisticsError,
  } = useCubeApiQuery(
    topFacilityStatisticsQuery(filters, undefined, filterOrder),
  );

  const { resultSet: tableFacilityStatisticsResult } = useCubeApiQuery(
    topFacilityStatisticsQuery(filters, sorting, filterOrder),
  );

  const getTopFacilityData = (
    rawData?: CubeQueryResultRawDataType<typeof topFacilityStatisticsQuery>,
  ) => {
    if (!rawData)
      return {
        topFacilityData: [],
        totalBase: 0,
        totalCapacity: 0,
        totalAssigned: 0,
      };
    const topFacilityData =
      rawData.filter(
        (obj) =>
          obj["assigned_drilldown.total_assigned"] &&
          obj["assigned_drilldown.panel_id"],
      ) ?? [];
    const uniqueFacilities = [
      ...new Set<string>(
        topFacilityData.map((o) => o["assigned_drilldown.facility_id"]),
      ),
    ];

    let totalBase = 0;
    let totalCapacity = 0;
    let totalAssigned = 0;

    const uniqueFacilityData: typeof topFacilityData = [];

    uniqueFacilities.forEach((o) => {
      const facilities = topFacilityData.filter(
        (obj) => obj["assigned_drilldown.facility_id"] === o,
      );

      let facilitiesTotalBase = 0;
      let facilitiesTotalCapacity = 0;
      let facilitiesTotalAssigned = 0;
      let facilitiesTotalAssigned540DayFlag = 0;
      let facilitiesTotalAny540DayFlag = 0;
      let facilitiesTotalProviderCount = 0;
      const uniqueFacilityEntry: (typeof uniqueFacilityData)[number] = {
        "assigned_drilldown.facility_id": o,
        "assigned_drilldown.facility_name":
          facilities[0]["assigned_drilldown.facility_name"],
        "assigned_drilldown.total_assigned": "0",
        "assigned_drilldown.total_base_capacity": "0",
        "assigned_drilldown.total_max_capacity": "0",
        "assigned_drilldown.total_assigned_540_day_flag": "0",
        "assigned_drilldown.total_any_540_day_flag": "0",
        "assigned_drilldown.total_provider_count": "0",
        "assigned_drilldown.panel_id":
          facilities[0]["assigned_drilldown.panel_id"],
        "assigned_drilldown.provider_facility_id":
          facilities[0]["assigned_drilldown.provider_facility_id"],
      };

      facilities.forEach((o) => {
        facilitiesTotalBase +=
          parseInt(o["assigned_drilldown.total_base_capacity"]) || 0;
        facilitiesTotalCapacity +=
          parseInt(o["assigned_drilldown.total_max_capacity"]) || 0;
        facilitiesTotalAssigned +=
          parseInt(o["assigned_drilldown.total_assigned"]) || 0;

        facilitiesTotalAssigned540DayFlag +=
          parseInt(o["assigned_drilldown.total_assigned_540_day_flag"]) || 0;
        facilitiesTotalAny540DayFlag +=
          parseInt(o["assigned_drilldown.total_any_540_day_flag"]) || 0;
        facilitiesTotalProviderCount +=
          parseInt(o["assigned_drilldown.total_provider_count"]) || 0;
      });

      uniqueFacilityEntry["assigned_drilldown.total_assigned"] =
        `${facilitiesTotalAssigned}`;
      uniqueFacilityEntry["assigned_drilldown.total_base_capacity"] =
        `${facilitiesTotalBase}`;
      uniqueFacilityEntry["assigned_drilldown.total_max_capacity"] =
        `${facilitiesTotalCapacity}`;
      uniqueFacilityEntry["assigned_drilldown.total_assigned_540_day_flag"] =
        `${facilitiesTotalAssigned540DayFlag}`;
      uniqueFacilityEntry["assigned_drilldown.total_any_540_day_flag"] =
        `${facilitiesTotalAny540DayFlag}`;
      uniqueFacilityEntry["assigned_drilldown.total_provider_count"] =
        `${facilitiesTotalProviderCount}`;

      totalBase += facilitiesTotalBase;
      totalCapacity += facilitiesTotalCapacity;
      totalAssigned += facilitiesTotalAssigned;

      uniqueFacilityData.push(uniqueFacilityEntry);
    });

    totalBase = parseInt(`${totalBase / uniqueFacilities.length}`);
    totalCapacity = parseInt(`${totalCapacity / uniqueFacilities.length}`);
    totalAssigned = parseInt(`${totalAssigned / uniqueFacilities.length}`);

    return {
      uniqueFacilityData,
      totalBase,
      totalCapacity,
      totalAssigned,
    };
  };

  const { totalBase, totalCapacity, totalAssigned } = getTopFacilityData(
    topFacilityStatisticsResult?.rawData(),
  );
  const { uniqueFacilityData } = getTopFacilityData(
    tableFacilityStatisticsResult?.rawData(),
  );

  const totalRows = uniqueFacilityData?.length ?? 0;

  return (
    <>
      <DataViz.Row>
        <DataViz.Gauge
          loading={topFacilityStatisticsLoading}
          error={topFacilityStatisticsError && "Something went wrong"}
          title="Average Base Capacity Ratio"
          toolTip="Average number of individuals assigned to a facility compared to the average of facility base capacity"
          numeratorTitle="Assigned"
          numerator={totalAssigned}
          denominatorTitle="Base"
          denominator={totalBase}
        />
        <DataViz.Gauge
          loading={topFacilityStatisticsLoading}
          error={topFacilityStatisticsError && "Something went wrong"}
          title="Average Total Capacity Ratio"
          toolTip="Average number of individuals assigned to a facility compared to the average of facility total capacity (base + flex)"
          numeratorTitle="Assigned"
          numerator={totalAssigned}
          denominatorTitle="Capacity"
          denominator={totalCapacity}
        />
      </DataViz.Row>
      <div style={{ width: "100%" }}>
        <Header
          title="Facility Statistics"
          toolTip="Key top level metrics of facilities"
        />
        <PivotTable
          columns={topFacilityColumns}
          data={
            (uniqueFacilityData ?? []).slice(
              pagination.offset,
              pagination.offset + pagination.limit,
            ) as ProviderFacilityRow[]
          }
          pinnedColumns={["facility_name"]}
          grouping={grouping}
          setGrouping={setGrouping}
          getSubRows={async (row) => {
            if (row.depth > 0) return [];

            const res = await cubeFetch(
              cubejsApi,
              topProviderStatisticsQuery({
                "assigned_drilldown.facility_name":
                  row.original["assigned_drilldown.facility_name"],
              }),
            ).then((res) => res.rawData());

            return res as ProviderFacilityRow[];
          }}
          onSortingChange={(newSorting) => {
            setSorting(
              newSorting.map((sortObj) => [
                `assigned_drilldown.${sortObj.id}`,
                sortObj.desc ? "desc" : "asc",
              ]),
            );
          }}
          pagination={{
            pageSize: pagination.limit,
            rowOffset: pagination.offset,
            totalRows: totalRows ?? 0,
          }}
          onPaginationChange={(newPagination) => {
            setPagination({
              ...pagination,
              offset: newPagination.rowOffset,
            });
          }}
          childCountProp="assigned_drilldown.total_provider_count"
          exportAction={() =>
            setTableExport({
              data: uniqueFacilityData,
              columnMap: csvDownloadColumnMap,
              exportName: "Facility Statistics",
            })
          }
        />
      </div>
    </>
  );
};

export default FacilityAnalysis;
