import React from "react";

import { DataViz } from "@commonComponents";

import { getAvailabilityColorIcon } from "~/src/App/LeapInsights/Components/Dashboards/ProviderFacilityScheduleAnalysis/common";
import { scheduleAvailabilityKPIQuery } from "~/src/App/LeapInsights/Components/Dashboards/ProviderFacilityScheduleAnalysis/FacilityScheduleAnalysis/Queries";
import type { DashboardAdvancedFilterProps } from "~/src/App/LeapInsights/Components/Portal/Portal";
import { parseToFixed } from "~/src/utils/stringUtils";
import type { AdvFilterProps } from "~/src/utils/tsUtils";
import { useCubeApiQuery } from "~/src/utils/tsUtils";

interface FacilityAvailabilityKPIProps {
  filters?: AdvFilterProps;
  filterOrder?: DashboardAdvancedFilterProps[];
}

const FacilityAvailabilityKpi = ({
  filters,
  filterOrder,
}: FacilityAvailabilityKPIProps) => {
  const {
    resultSet: scheduleAvailabilityKPIResult,
    error: scheduleAvailabilityKPIError,
    isLoading: scheduleAvailabilityKPILoading,
  } = useCubeApiQuery(scheduleAvailabilityKPIQuery(filters, filterOrder));

  return (
    <DataViz.Row>
      <DataViz.NumberChart
        {...getAvailabilityColorIcon(
          +parseToFixed({
            input:
              scheduleAvailabilityKPIResult?.rawData()[0]?.[
                "schedule_availability_facility_summary_appt_type.average_next_available"
              ],
          }),
        )}
        // eslint-disable-next-line max-len
        toolTip="The average number of days until the next available appointment for all facilities and appointment types. Refer to the schedule analysis table below for details on color coding."
        loading={scheduleAvailabilityKPILoading}
        error={scheduleAvailabilityKPIError && "Something went wrong"}
        type="metric"
        unit="Days"
        numerator={
          +parseToFixed({
            input:
              scheduleAvailabilityKPIResult?.rawData()[0]?.[
                "schedule_availability_facility_summary_appt_type.average_next_available"
              ],
          })
        }
        title="Average Next Available"
      />
      <DataViz.NumberChart
        {...getAvailabilityColorIcon(
          +parseToFixed({
            input:
              scheduleAvailabilityKPIResult?.rawData()[0]?.[
                "schedule_availability_facility_summary_appt_type.average_second_next_available"
              ],
          }),
        )}
        // eslint-disable-next-line max-len
        toolTip="The average number of days until the 2nd next available appointment for all facilities and appointment types. Refer to the schedule analysis table below for details on color coding."
        loading={scheduleAvailabilityKPILoading}
        error={scheduleAvailabilityKPIError && "Something went wrong"}
        type="metric"
        unit="Days"
        numerator={
          +parseToFixed({
            input:
              scheduleAvailabilityKPIResult?.rawData()[0]?.[
                "schedule_availability_facility_summary_appt_type.average_second_next_available"
              ],
          })
        }
        title="Average 2nd Next Available"
      />
      <DataViz.NumberChart
        {...getAvailabilityColorIcon(
          +parseToFixed({
            input:
              scheduleAvailabilityKPIResult?.rawData()[0]?.[
                "schedule_availability_facility_summary_appt_type.average_third_next_available"
              ],
          }),
        )}
        // eslint-disable-next-line max-len
        toolTip="The average number of days until the 3rd next available appointment for all facilities and appointment types. Refer to the schedule analysis table below for details on color coding."
        loading={scheduleAvailabilityKPILoading}
        error={scheduleAvailabilityKPIError && "Something went wrong"}
        type="metric"
        unit="Days"
        numerator={
          +parseToFixed({
            input:
              scheduleAvailabilityKPIResult?.rawData()[0]?.[
                "schedule_availability_facility_summary_appt_type.average_third_next_available"
              ],
          })
        }
        title="Average 3rd Next Available"
      />
      <DataViz.NumberChart
        {...getAvailabilityColorIcon(
          +parseToFixed({
            input:
              scheduleAvailabilityKPIResult?.rawData()[0]?.[
                "schedule_availability_facility_summary_appt_type.average_fourth_next_available"
              ],
          }),
        )}
        // eslint-disable-next-line max-len
        toolTip="The average number of days until the 4th next available appointment for all facilities and appointment types. Refer to the schedule analysis table below for details on color coding."
        loading={scheduleAvailabilityKPILoading}
        error={scheduleAvailabilityKPIError && "Something went wrong"}
        type="metric"
        unit="Days"
        numerator={
          +parseToFixed({
            input:
              scheduleAvailabilityKPIResult?.rawData()[0]?.[
                "schedule_availability_facility_summary_appt_type.average_fourth_next_available"
              ],
          })
        }
        title="Average 4th Next Available"
      />
    </DataViz.Row>
  );
};

export default FacilityAvailabilityKpi;
