interface ParseToFixedProps {
  input: string | number | null | undefined | boolean;
  decimalPlaces?: number;
  invalidOutput?: string;
  multiplier?: number;
  postFix?: string;
  prefix?: string;
}

export const parseToFixed = ({
  input,
  decimalPlaces = 2,
  invalidOutput = "-",
  multiplier = 1,
  postFix = "",
  prefix = "",
}: ParseToFixedProps): string => {
  if (!input) return invalidOutput;

  // Remove any non-numeric characters except for the decimal point and minus sign
  const numericValue =
    typeof input === "string" ? input.replace(/[^\d.-]/g, "") : input;

  // Parse the cleaned string to a float
  let parsed = parseFloat(`${numericValue}`);

  // If the parsed value is a number, return it to the specified number of decimal places
  if (isFinite(parsed)) {
    parsed *= multiplier;
    const strParsed = parsed.toFixed(decimalPlaces);
    return `${prefix}${strParsed}${postFix}`;
  } else {
    return invalidOutput;
  }
};
