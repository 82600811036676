import { combineReducers } from "redux";

import customTables from "./customTables";
import dataMapping from "./dataMapping";
import dataModel from "./dataModel";
import dataOrchestration from "./dataOrchestration";
import dataTest from "./dataTest";
import monitoring from "./monitoring";
import recoding from "./recoding";

export default combineReducers({
  customTables,
  dataMapping,
  dataModel,
  dataOrchestration,
  dataTest,
  monitoring,
  recoding,
});
