import { v4 as uuidv4 } from "uuid";

const initialState = {
  complexCohort: {
    name: "",
    key: "",
    description: "",
    category: [],
    data: {
      collections: [],
    },
    categories: [],
  },
  editCollections: [],
  complexCohorts: {
    isFetching: true,
    error: false,
    result: [],
  },
  simpleCohorts: {
    isFetching: true,
    error: false,
    result: [],
  },
  groups: {
    isFetching: true,
    error: false,
    result: [],
  },
  itemDragging: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // Complex Cohorts
    case "COHORT_SIMPLE_COHORTS_FETCH_ALL": {
      return Object.assign({}, state, {
        simpleCohorts: action.payload,
      });
    }

    case "COHORT_COMPLEX_COHORTS_FETCH_ALL": {
      return Object.assign({}, state, {
        complexCohorts: action.payload,
      });
    }

    case "COHORT_CREATE_COMPLEX_COHORT": {
      return Object.assign({}, state, {
        complexCohorts: {
          ...state.complexCohorts,
          result: [...state.complexCohorts.result, action.payload],
        },
      });
    }

    case "COHORT_UPDATE_COMPLEX_COHORT": {
      return Object.assign({}, state, {
        complexCohorts: {
          ...state.complexCohorts,
          result: state.complexCohorts.result.map((cohort) => {
            if (cohort.identifier === action.payload.identifier) {
              return action.payload;
            }

            return cohort;
          }),
        },
      });
    }

    case "COHORT_COMPLEX_COHORT_CHANGE_STATE": {
      let _complexCohort = { ...state.complexCohort };
      _complexCohort.state = action.payload.state;

      return Object.assign({}, state, {
        complexCohort: _complexCohort,
        complexCohorts: {
          ...state.complexCohorts,
          result: state.complexCohorts.result.map((cohort) => {
            if (cohort.identifier === action.payload.identifier) {
              return {
                ...cohort,
                state: action.payload.state,
              };
            }

            return cohort;
          }),
        },
      });
    }

    case "COHORT_DELETE_COMPLEX_COHORT": {
      return Object.assign({}, state, {
        complexCohorts: {
          ...state.complexCohorts,
          result: state.complexCohorts.result.filter(
            (cohort) => cohort.identifier !== action.payload.identifier,
          ),
        },
      });
    }

    case "COHORT_COMPLEX_RESET": {
      return Object.assign({}, state, {
        complexCohort: {
          name: "",
          key: "",
          description: "",
          data: { collections: [] },
        },
      });
    }

    case "COHORT_COMPLEX_SET": {
      return Object.assign({}, state, {
        complexCohort: action.payload,
      });
    }

    case "COHORT_COMPLEX_UPDATE_KEY": {
      return Object.assign({}, state, {
        complexCohort: {
          ...state.complexCohort,
          [action.payload.key]: action.payload.value,
        },
      });
    }

    case "COHORT_COMPLEX_CREATE_COLLECTION": {
      const collection = {
        uuid: uuidv4(),
        name: `Collection ${state.complexCohort.data.collections.length + 1}`,
        root_cohort_name: action.payload.name,
        root_cohort_reference: `${action.payload.identifier}:latest`,
        cohorts: [],
      };

      return Object.assign({}, state, {
        complexCohort: {
          ...state.complexCohort,
          data: {
            ...state.complexCohort.data,
            collections: [...state.complexCohort.data.collections, collection],
          },
        },
      });
    }

    case "COHORT_COMPLEX_UPDATE_COLLECTION": {
      return Object.assign({}, state, {
        complexCohort: {
          ...state.complexCohort,
          collection_set: state.complexCohort.collection_set.map(
            (collection) => {
              if (collection.uuid === action.payload.uuid) {
                return action.payload;
              }

              return collection;
            },
          ),
        },
      });
    }

    case "COHORT_COMPLEX_ADD_COHORT_TO_COLLECTION": {
      let parentPath = action.payload.parentPath?.split("~") || [];
      parentPath.shift();

      const updateCohorts = (cohorts, i = 0) => {
        if (parentPath.length === 0) {
          cohorts.push({
            name: action.payload.cohort.name,
            cohort_reference: `${action.payload.cohort.identifier}:latest`,
            cohorts: [],
          });
        } else {
          cohorts.forEach((cohort) => {
            if (cohort.cohort_reference === parentPath[i]) {
              if (parentPath.length - 1 > i) {
                cohort.cohorts = updateCohorts(cohort.cohorts, i + 1);
              } else {
                cohort.cohorts.push({
                  name: action.payload.cohort.name,
                  cohort_reference: `${action.payload.cohort.identifier}:latest`,
                  cohorts: [],
                });
              }
            }
          });
        }

        return cohorts;
      };

      const cohorts = state.complexCohort.data.collections.map((collection) => {
        if (collection.uuid === action.payload.collectionId) {
          return {
            ...collection,
            cohorts: updateCohorts(collection.cohorts),
          };
        }

        return collection;
      });

      const collections = state.complexCohort.data.collections.map(
        (collection) => {
          if (collection.uuid === action.payload.collection) {
            return {
              ...collection,
              cohorts,
            };
          }

          return collection;
        },
      );

      return Object.assign({}, state, {
        complexCohort: {
          ...state.complexCohort,
          data: { collections },
        },
      });
    }

    case "COHORT_COMPLEX_ADD_COHORT_PARENT": {
      return Object.assign({}, state, {
        complexCohort: {
          ...state.complexCohort,
          collection_set: state.complexCohort.collection_set.map(
            (collection) => {
              if (collection.uuid === action.payload.collection) {
                return {
                  ...collection,
                  cohorts: collection.cohorts.map((cohort) => {
                    if (cohort.identifier === action.payload.cohort) {
                      cohort.parent = action.payload.parent;
                    }

                    return cohort;
                  }),
                };
              }

              return collection;
            },
          ),
        },
      });
    }

    case "COHORT_COMPLEX_COHORT_UPDATE_COLLECTION_BY_KEY":
      return Object.assign({}, state, {
        complexCohort: {
          ...state.complexCohort,
          data: {
            collections: state.complexCohort.data.collections.map((obj) => {
              if (obj.uuid === action.payload.collectionId) {
                obj[action.payload.key] = action.payload.value;
              }

              return obj;
            }),
          },
        },
      });

    case "COHORT_COMPLEX_COHORT_UPDATE_COLLECTION_SET_OPERATION":
      return Object.assign({}, state, {
        complexCohort: {
          ...state.complexCohort,
          data: {
            collections: state.complexCohort.data.collections.map((obj) => {
              if (obj.uuid === action.payload.collectionId) {
                obj.set_operation = action.payload.setOperation;
              }

              return obj;
            }),
          },
        },
      });

    case "COHORT_COMPLEX_COHORT_DELETE_EDIT_COLLECTION":
      return Object.assign({}, state, {
        complexCohort: {
          ...state.complexCohort,
          data: {
            collections: state.complexCohort.data.collections.filter(
              (obj) => obj.uuid !== action.payload,
            ),
          },
        },
      });

    case "COHORT_COMPLEX_DELETE_EDGE": {
      let path = action.payload.path.split("~") || [];
      path.shift();

      const updateCohorts = (cohorts, i = 0) => {
        let _cohorts = [];

        cohorts.forEach((cohort) => {
          if (cohort.cohort_reference !== path[i]) {
            _cohorts.push(cohort);
          } else if (path.length - 1 > i) {
            cohort.cohorts = updateCohorts(cohort.cohorts, i + 1);
            _cohorts.push(cohort);
          }
        });

        return _cohorts;
      };

      return Object.assign({}, state, {
        complexCohort: {
          ...state.complexCohort,
          data: {
            collections: state.complexCohort.data.collections.map(
              (collection) => {
                if (collection.uuid === action.payload.collection) {
                  return {
                    ...collection,
                    cohorts: updateCohorts(collection.cohorts),
                  };
                }

                return collection;
              },
            ),
          },
        },
      });
    }

    case "COHORT_COMPLEX_DELETE_COLLECTION": {
      return Object.assign({}, state, {
        complexCohort: {
          ...state.complexCohort,
          collection_set: state.complexCohort.collection_set.filter(
            (collection) => collection.uuid !== action.payload.uuid,
          ),
        },
      });
    }

    // Simple Cohorts
    case "COHORT_CREATE_SIMPLE_COHORT": {
      return Object.assign({}, state, {
        simpleCohorts: {
          ...state.simpleCohorts,
          result: [...state.simpleCohorts.result, action.payload],
        },
      });
    }

    case "COHORT_UPDATE_SIMPLE_COHORT": {
      return Object.assign({}, state, {
        simpleCohorts: {
          ...state.simpleCohorts,
          result: state.simpleCohorts.result.map((collection) => {
            if (collection.identifier === action.payload.identifier) {
              return action.payload;
            }

            return collection;
          }),
        },
      });
    }

    case "COHORT_SIMPLE_COHORT_CHANGE_STATE": {
      return Object.assign({}, state, {
        simpleCohorts: {
          ...state.simpleCohorts,
          result: state.simpleCohorts.result.map((collection) => {
            if (collection.identifier === action.payload.identifier) {
              return {
                ...collection,
                state: action.payload.state,
              };
            }

            return collection;
          }),
        },
      });
    }

    case "COHORT_DELETE_SIMPLE_COHORT": {
      return Object.assign({}, state, {
        simpleCohorts: {
          ...state.simpleCohorts,
          result: state.simpleCohorts.result.filter(
            (obj) => obj.identifier !== action.payload.identifier,
          ),
        },
      });
    }

    case "COHORT_TOGGLE_STATUS": {
      return Object.assign({}, state, {
        simpleCohorts: {
          ...state.simpleCohorts,
          result: state.simpleCohorts.result.map((collection) => {
            if (collection.identifier === action.payload) {
              if (collection.state === "ACTIVE") {
                collection.state = "INACTIVE";
              } else {
                collection.state = "ACTIVE";
              }
            }

            return collection;
          }),
        },
      });
    }

    case "COHORT_SET_COLLECTIONS": {
      return Object.assign({}, state, {
        collections: action.payload.map((obj) => {
          return {
            ...obj,
            uuid: uuidv4(),
          };
        }),
      });
    }

    case "COHORT_ADD_COLLECTION_GROUP": {
      const collection = {
        uuid: uuidv4(),
        name: `Collection ${state.collections.length + 1}`,
        condition: "AND",
        groups: [action.payload],
      };

      let _collections = [...state.collections];

      // If group is coming from another collection, remove it from that collection
      if (action.payload.parentId) {
        _collections = _collections
          .map((obj) => {
            if (obj.uuid === action.payload.parentId) {
              obj.groups = obj.groups.filter(
                (group) => group.uuid !== action.payload.uuid,
              );
            }

            return obj;
          })
          .filter((obj) => obj.groups.length > 0);
      }

      _collections.push(collection);

      return Object.assign({}, state, {
        collections: _collections,
      });
    }

    case "COHORT_SIMPLE_COHORT_UPDATE_COLLECTION_BY_KEY":
      return Object.assign({}, state, {
        collections: state.collections.map((obj) => {
          if (obj.uuid === action.payload.collectionId) {
            obj[action.payload.key] = action.payload.value;
          }

          return obj;
        }),
      });

    case "COHORT_SIMPLE_COHORT_UPDATE_COLLECTION_CONDITION":
      return Object.assign({}, state, {
        collections: state.collections.map((obj) => {
          if (obj.uuid === action.payload.collectionId) {
            obj.condition = action.payload.condition;
          }

          return obj;
        }),
      });

    case "COHORT_SIMPLE_COHORT_DELETE_EDIT_COLLECTION":
      return Object.assign({}, state, {
        collections: state.collections.filter(
          (obj) => obj.uuid !== action.payload,
        ),
      });

    case "COHORT_ADD_GROUP_TO_COLLECTION": {
      return Object.assign({}, state, {
        collections: state.collections
          .map((collection) => {
            // If group is coming from another collection, remove it from that collection
            if (collection.uuid === action.payload.group.parentId) {
              collection.groups = collection.groups.filter(
                (group) => group.uuid !== action.payload.group.uuid,
              );
            } else if (collection.uuid === action.payload.collectionId) {
              // Add group to collection
              collection.groups.push(action.payload.group);
            }

            return collection;
          })
          .filter((collection) => collection.groups.length > 0),
      });
    }

    case "COHORT_REMOVE_GROUP_FROM_COLLECTION": {
      return Object.assign({}, state, {
        collections: state.collections
          .map((collection) => {
            if (collection.uuid === action.payload.collectionId) {
              collection.groups = collection.groups.filter(
                (group) => group.uuid !== action.payload.groupId,
              );
            }

            return collection;
          })
          .filter((collection) => collection.groups.length > 0),
      });
    }

    case "COHORT_SET_NEGATION_GROUP_COLLECTION": {
      return Object.assign({}, state, {
        collections: state.collections
          .map((collection) => {
            if (collection.uuid === action.payload.collectionId) {
              if (!collection.negated_groups) {
                collection.negated_groups = [action.payload.groupId];
              } else if (
                collection.negated_groups.includes(action.payload.groupId)
              ) {
                collection.negated_groups = collection.negated_groups.filter(
                  (group) => group !== action.payload.groupId,
                );
              } else {
                collection.negated_groups.push(action.payload.groupId);
              }
            }

            return collection;
          })
          .filter((collection) => collection.groups.length > 0),
      });
    }

    case "COHORT_ITEM_DRAGGING": {
      return Object.assign({}, state, {
        itemDragging: action.payload,
      });
    }

    case "COHORT_GROUPS_FETCH_ALL": {
      return Object.assign({}, state, {
        groups: action.payload,
      });
    }

    case "COHORT_CREATE_GROUP": {
      return Object.assign({}, state, {
        groups: {
          ...state.groups,
          result: [...state.groups.result, action.payload],
        },
      });
    }

    case "COHORT_UPDATE_GROUP": {
      return Object.assign({}, state, {
        groups: {
          ...state.groups,
          result: state.groups.result.map((group) => {
            if (group.identifier === action.payload.identifier) {
              return action.payload;
            }

            return group;
          }),
        },
      });
    }

    case "COHORT_DELETE_GROUP": {
      return Object.assign({}, state, {
        groups: {
          ...state.groups,
          result: state.groups.result.filter(
            (group) => group.identifier !== action.payload,
          ),
        },
      });
    }

    default:
      return state;
  }
}
