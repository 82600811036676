import { DataViz } from "@commonComponents";

import type { DashboardAdvancedFilterProps } from "../../Portal/Portal";
import type { AdvFilterProps } from "~/src/utils/tsUtils";

export const populationVolumeQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: [
      "roster_volume.percentage_change_member_count",
      "roster_volume.total_last_month_member_count",
      "roster_volume.total_member_count",
    ],
    dimensions: ["roster_volume.plan_state", "roster_volume.yearmo"],
    filters: [
      {
        member: "roster_volume.yearmo_rank",
        operator: "equals",
        values: ["1"],
      },
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const averageTenureQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["roster_volume.avg_tenure"],
    dimensions: [
      "roster_volume.plan_state",
      "roster_volume.yearmo",
      "roster_volume.payer_name",
    ],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;

export const volumeByMarketSegmentQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["roster_volume.total_member_count"],
    dimensions: ["roster_volume.market_segment"],
    filters: [
      {
        member: "roster_volume.yearmo_rank",
        operator: "equals",
        values: ["1"],
      },
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const volumeByMarketSegmentByStateQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["roster_volume.total_member_count"],
    dimensions: [
      "roster_volume.yearmo",
      "roster_volume.market_segment",
      "roster_volume.plan_state",
    ],
    filters: [
      {
        member: "roster_volume.yearmo_rank",
        operator: "equals",
        values: ["1"],
      },
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const volumeByPayerQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["roster_volume.total_member_count"],
    dimensions: ["roster_volume.payer_name", "roster_volume.yearmo"],
    filters: [
      {
        member: "roster_volume.yearmo_rank",
        operator: "equals",
        values: ["1"],
      },
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const growthTrendQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["roster_volume.total_member_count"],
    dimensions: [
      "roster_volume.current_yearmo_month",
      "roster_volume.current_yearmo_year",
    ],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;

export const summaryFilterDimensions: string[] = [
  "roster_volume.plan_state",
  "roster_volume.payer_name",
  "roster_volume.market_segment",
  "roster_volume.market_sub_segment",
  "roster_volume.yearmo",
];
