import React, { memo, useState } from "react";

import {
  Button,
  ButtonGroup,
  gridSpacing,
} from "@lucernahealth/lucerna-health-ui";
import equal from "fast-deep-equal/es6";
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more.js";
import heatmap from "highcharts/modules/heatmap.js";
import HighchartsReact from "highcharts-react-official";

import { colorTheme } from "@utils";

import Error from "./Common/Error";
import Header from "./Common/Header";
import Loading from "./Common/Loading";
import { Wrapper } from "./Common/styles";

highchartsMore(Highcharts);
heatmap(Highcharts);

type HeatMapProps = {
  options: string[];
  data: {
    name: string;
    value: number;
    total: number;
  }[];
  toolTip?: string;
  xAxisLabel?: string;
  yAxisLabel?: string;
  xAxisPercentLabel?: string;
  xAxisCountLabel?: string;
  percentTitle: string;
  countTitle: string;
  minColor?: string;
  maxColor?: string;
  error?: string | null;
  loading?: boolean;
};

const HeatMap = memo(
  ({
    options,
    data,
    toolTip,
    xAxisLabel,
    yAxisLabel,
    xAxisPercentLabel,
    xAxisCountLabel,
    percentTitle,
    countTitle,
    minColor,
    maxColor,
    error,
    loading,
  }: HeatMapProps) => {
    const [showPercent, setShowPercent] = useState(true);

    const seriesDataPercent: Highcharts.PointOptionsObject[] = data.map(
      (item, i) => [i, 0, Math.round((item.value / item.total) * 100)],
    );

    const seriesDataCount: Highcharts.PointOptionsObject[] = data.map(
      (item, i) => [i, 0, item.value],
    );

    const renderChart = () => (
      <HighchartsReact
        containerProps={{ style: { width: "100%" } }}
        highcharts={Highcharts}
        options={{
          chart: {
            type: "heatmap",
          },
          xAxis: {
            categories: options,
            title: {
              text: xAxisLabel ?? "",
            },
          },
          yAxis: {
            title: {
              text: yAxisLabel ?? "",
            },
            labels: {
              enabled: false,
            },
          },
          colorAxis: {
            min: 0,
            // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
            minColor: minColor ?? "#E9F4FF",
            // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
            maxColor: maxColor ?? "#043A6B",
          },
          legend: {
            align: "right",
            layout: "vertical",
            marginLeft: 10,
            verticalAlign: "top",
            symbolHeight: 230,
            y: -10,
          },
          tooltip: {
            format: showPercent
              ? `{series.xAxis.categories.(point.x)} - <b>{point.value}% ${
                  xAxisPercentLabel ?? ""
                }</b>`
              : `{series.xAxis.categories.(point.x)} - <b>{point.value}</b> ${
                  xAxisCountLabel ?? ""
                }`,
          },
          series: [
            {
              name: "Consumers per Facility",
              borderWidth: 1,
              borderColor: colorTheme("neutralL5"),
              data: showPercent ? seriesDataPercent : seriesDataCount,
              dataLabels: {
                enabled: true,
                style: {
                  textOutline: "none",
                },
              },
            },
          ],
          title: null,
          credits: {
            enabled: false,
          },
        }}
      />
    );

    if (error) {
      return <Error errorMessage={error} />;
    }

    if (loading) {
      return <Loading />;
    }

    return (
      <Wrapper>
        <Header
          title={showPercent ? percentTitle : countTitle}
          toolTip={toolTip}
        />
        <ButtonGroup
          style={{
            marginBottom: gridSpacing[3],
          }}
          value={showPercent}
          onChange={(e) => setShowPercent(Boolean(e))}
        >
          <Button text="Show Count" value={false} />
          <Button value text="Show Percentage" />
        </ButtonGroup>
        {renderChart()}
      </Wrapper>
    );
  },
  (oldProps, newProps) => equal(oldProps, newProps),
);

export default HeatMap;
