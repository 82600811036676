import React from "react";

import { ToastContainer } from "react-toastify";
import { styled } from "styled-components";

import { colorTheme } from "@utils";
import "react-toastify/dist/ReactToastify.css";

const StyledContainer = styled(ToastContainer)`
  // SUCCESS
  .Toastify__toast {
    cursor: default;
  }

  .Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: ${colorTheme("successL5")};
    color: ${colorTheme("success")};
  }

  .Toastify__progress-bar--success {
    background-color: ${colorTheme("success")};
  }

  .Toastify__toast--success > .Toastify__close-button {
    color: ${colorTheme("success")};
  }

  // ERROR
  .Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: ${colorTheme("dangerL5")};
    color: ${colorTheme("danger")};
  }

  .Toastify__progress-bar--error {
    background-color: ${colorTheme("danger")};
  }

  .Toastify__toast--error > .Toastify__close-button {
    color: ${colorTheme("danger")};
  }

  // WARNING
  .Toastify__toast-theme--colored.Toastify__toast--warning {
    background-color: ${colorTheme("warningL5")};
    color: ${colorTheme("warning")};
  }

  .Toastify__progress-bar--warning {
    background-color: ${colorTheme("warning")};
  }

  .Toastify__toast--warning > .Toastify__close-button {
    color: ${colorTheme("warning")};
  }

  // DEFAULT
  .Toastify__toast-theme--colored.Toastify__toast--default {
    background-color: ${colorTheme("infoL3")};
    color: ${colorTheme("info")};
  }

  .Toastify__progress-bar--default {
    background-color: ${colorTheme("info")};
  }

  .Toastify__toast--default > .Toastify__close-button {
    color: ${colorTheme("info")};
  }

  .Toastify__toast-icon {
    justify-content: flex-start;
  }
`;

const ToastContainerComponent = () => (
  // We have 60px here because we want to move it out of the way of the CTA
  <StyledContainer newestOnTop style={{ marginTop: 60 }} />
);
export default ToastContainerComponent;
