import React from "react";

import { DataViz } from "@commonComponents";
import moment from "moment";

import {
  archivedRecordsQuery,
  createdRecordsQuery,
  incomingRecordsPerMonthQuery,
  jobsProcessedPerMonthQuery,
  mergedRecordsQuery,
  recordsInsertedPerMonthPerSourceQuery,
  recordsInsertedPerMonthQuery,
} from "./Queries";
import { useCubeApiQuery } from "~/src/utils/tsUtils";

const CpsOperations = () => {
  const filters = {};

  const {
    resultSet: createdRecordsResult,
    error: createdRecordsError,
    isLoading: createdRecordsLoading,
  } = useCubeApiQuery(createdRecordsQuery(filters));

  const {
    resultSet: mergedRecordsResult,
    error: mergedRecordsError,
    isLoading: mergedRecordsLoading,
  } = useCubeApiQuery(mergedRecordsQuery(filters));

  const {
    resultSet: archivedRecordsResult,
    error: archivedRecordsError,
    isLoading: archivedRecordsLoading,
  } = useCubeApiQuery(archivedRecordsQuery(filters));

  const {
    resultSet: jobsProcessedPerMonthResult,
    error: jobsProcessedPerMonthError,
    isLoading: jobsProcessedPerMonthLoading,
  } = useCubeApiQuery(jobsProcessedPerMonthQuery(filters));

  const {
    resultSet: incomingRecordsPerMonthResult,
    error: incomingRecordsPerMonthError,
    isLoading: incomingRecordsPerMonthLoading,
  } = useCubeApiQuery(incomingRecordsPerMonthQuery(filters));

  const {
    resultSet: recordsInsertedPerMonthResult,
    error: recordsInsertedPerMonthError,
    isLoading: recordsInsertedPerMonthLoading,
  } = useCubeApiQuery(recordsInsertedPerMonthQuery(filters));

  const {
    resultSet: recordsInsertedPerMonthPerSourceResult,
    error: recordsInsertedPerMonthPerSourceError,
    isLoading: recordsInsertedPerMonthPerSourceLoading,
  } = useCubeApiQuery(recordsInsertedPerMonthPerSourceQuery(filters));

  const currentDate = moment();
  const last24Months = Array.from({ length: 24 }, () =>
    currentDate.subtract(1, "month").format("YYYYMM"),
  ).reverse();

  const obj24Months: Record<
    string,
    Record<string, number>
  > = last24Months.reduce(
    (obj: Record<string, Record<string, number>>, month) => {
      obj[month] = {};
      return obj;
    },
    {},
  );

  const getCpsOperationTrendLine = () => {
    const jobProcessedData = jobsProcessedPerMonthResult?.rawData();
    const recordsIncomingData = incomingRecordsPerMonthResult?.rawData();
    const recordsInsertedData = recordsInsertedPerMonthResult?.rawData();

    if (!jobProcessedData || !recordsIncomingData || !recordsInsertedData) {
      return { data: [], options: [] };
    }

    const trendLineData = { ...obj24Months };

    const processData = (
      data: Record<string, string>[],
      name: string,
      sumKey: string,
    ) => {
      data.forEach((item) => {
        const yearmo = moment(
          item["cps_data_ingestion_jobs.created.month"],
        ).format("YYYYMM");

        const count = item[sumKey];
        if (yearmo && count && trendLineData?.[yearmo]) {
          trendLineData[yearmo][name] = parseInt(count);
        }
      });
    };

    processData(
      jobProcessedData,
      "jobs_processed_count",
      "cps_data_ingestion_jobs.jobs_processed_count",
    );
    processData(
      recordsIncomingData,
      "ingested_record_sum",
      "cps_data_ingestion_jobs.ingested_record_sum",
    );
    processData(
      recordsInsertedData,
      "inserted_record_sum",
      "cps_data_ingestion_jobs.inserted_record_sum",
    );

    return {
      data: [
        {
          name: "Jobs Processed",
          data: Object.keys(trendLineData).map((o) =>
            trendLineData[o]["jobs_processed_count"]
              ? parseInt(trendLineData[o]["jobs_processed_count"].toString())
              : null,
          ),
        },
        {
          name: "Records Incoming",
          data: Object.keys(trendLineData).map((o) =>
            trendLineData[o]["ingested_record_sum"]
              ? parseInt(trendLineData[o]["ingested_record_sum"].toString())
              : null,
          ),
        },
        {
          name: "Records Inserted",
          data: Object.keys(trendLineData).map((o) =>
            trendLineData[o]["ingested_record_sum"]
              ? parseInt(trendLineData[o]["inserted_record_sum"].toString())
              : null,
          ),
        },
      ],
      options: last24Months.map((month) =>
        moment(month, "YYYYMM").format("MM/YYYY"),
      ),
    };
  };

  const getRecordsPerSourceTrendLine = () => {
    const trendData = recordsInsertedPerMonthPerSourceResult?.rawData();

    if (!trendData) {
      return { data: [], options: [] };
    }

    const names = [
      ...new Set(
        trendData.map((data) => data["cps_data_ingestion_jobs.source_name"]),
      ),
    ];

    const trendLineData = { ...obj24Months };

    names.forEach((name) => {
      const data = trendData.filter(
        (o) => o["cps_data_ingestion_jobs.source_name"] === name,
      );
      data.forEach((item) => {
        const yearmo = moment(
          item["cps_data_ingestion_jobs.created.month"],
        ).format("YYYYMM");

        const count = item["cps_data_ingestion_jobs.inserted_record_sum"];
        if (yearmo && count && trendLineData?.[yearmo]) {
          trendLineData[yearmo][name] = parseInt(count);
        }
      });
    });

    const data = names.map((name) => {
      return {
        name,
        data: Object.keys(trendLineData).map((o) =>
          trendLineData[o][name]
            ? parseInt(trendLineData[o][name].toString())
            : null,
        ),
      };
    });

    return {
      data,
      options: last24Months.map((month) =>
        moment(month, "YYYYMM").format("MM/YYYY"),
      ),
    };
  };

  return (
    <>
      <DataViz.Row>
        <DataViz.NumberChart
          title="Number of New Consumers Created"
          toolTip="Number of new consumers created in the last 90 days"
          subTitle="Last 90 days"
          numerator={parseInt(
            `${
              createdRecordsResult?.rawData()[0]?.[
                "cps_consumer_monitoring.created_consumer_count_90d"
              ]
            }`,
          )}
          type="metric"
          loading={createdRecordsLoading}
          error={createdRecordsError && "Something went wrong"}
        />
        <DataViz.NumberChart
          title="Number of Manually Merged Records"
          toolTip="Number of consumer records that were manually merged in the last 90 days"
          subTitle="Last 90 days"
          numerator={parseInt(
            `${
              mergedRecordsResult?.rawData()[0]?.[
                "consumer_merge_history.merged_consumer_count_90d"
              ]
            }`,
          )}
          type="metric"
          loading={mergedRecordsLoading}
          error={mergedRecordsError && "Something went wrong"}
        />
        <DataViz.NumberChart
          title="Number of Archived Records"
          toolTip="Number of consumer records that were archived in the last 90 days "
          subTitle="Last 90 days"
          numerator={parseInt(
            `${
              archivedRecordsResult?.rawData()[0]?.[
                "cps_consumer_monitoring.deprecated_consumer_count_90d"
              ]
            }`,
          )}
          type="metric"
          loading={archivedRecordsLoading}
          error={archivedRecordsError && "Something went wrong"}
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.LineChart
          title="CPS Operations Trend"
          toolTip="Number of jobs processed and the number of incoming records and inserted records per job, per month"
          loading={
            jobsProcessedPerMonthLoading ||
            incomingRecordsPerMonthLoading ||
            recordsInsertedPerMonthLoading
          }
          error={
            (jobsProcessedPerMonthError ||
              incomingRecordsPerMonthError ||
              recordsInsertedPerMonthError) &&
            "Something went wrong"
          }
          yAxisLabel="Count"
          {...getCpsOperationTrendLine()}
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.LineChart
          title="Records per Source"
          toolTip="Number of records inserted per data source per month "
          loading={recordsInsertedPerMonthPerSourceLoading}
          error={
            recordsInsertedPerMonthPerSourceError && "Something went wrong"
          }
          yAxisLabel="Records"
          {...getRecordsPerSourceTrendLine()}
        />
      </DataViz.Row>
    </>
  );
};

export default CpsOperations;
