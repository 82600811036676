import React, { useEffect, useRef, useState } from "react";

import {
  GraphicCallout,
  Loading,
  Tabs,
  gridSpacing,
} from "@lucernahealth/lucerna-health-ui";
import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import axios from "axios";

import { colorTheme } from "@utils";

import type { QuicksightProps } from "./helpers";
import type { ListInsightsDashboard } from "~/src/types";

type QuicksightDashboardProps = {
  dashboard: ListInsightsDashboard | QuicksightProps;
};

type ErrorsProp = {
  requestError?: string;
  notFound?: string;
};

type SheetOptionsType = {
  label: string;
  value: string;
};

type EmbeddedDashboardTypes = {
  getSheets: (
    callback: (e: { sheets: { name: string; sheetId: string }[] }) => void,
  ) => void;
  navigateToSheet: (e: string) => void;
  on: (e: string, callback: (e: string) => void) => void;
};

const QuicksightDashboard = ({ dashboard }: QuicksightDashboardProps) => {
  const dashboardRef = useRef(null);
  const [dashboardUrl, setDashboardUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<ErrorsProp>({});
  const [currentSheet, setCurrentSheet] = useState<string>("");
  const [sheetOptions, setSheetOptions] = useState<SheetOptionsType[]>([]);
  const [embeddedDashboard, setEmbeddedDashboard] =
    useState<EmbeddedDashboardTypes | null>(null);

  const resizeIframe = () => {
    // Hack to make height 100% to screen
    const embeddingContainer = document.getElementById("embeddingContainer");
    if (embeddingContainer?.children[0]) {
      setTimeout(() => {
        const iframe = embeddingContainer.children[0] as HTMLIFrameElement;
        iframe.height = "15000";
      }, 100);
    }
  };

  const onDashboardLoad = () => {
    setLoading(false);

    if (embeddedDashboard) {
      (embeddedDashboard as EmbeddedDashboardTypes).getSheets(
        (e: { sheets: { name: string; sheetId: string }[] }) => {
          setCurrentSheet(e.sheets[0]?.sheetId);
          setSheetOptions(
            e.sheets.map((option: { name: string; sheetId: string }) => {
              return {
                label: option.name,
                value: option.sheetId,
              };
            }),
          );
        },
      );
    }
  };

  const onError = () => {
    const _error = "Something went wrong loading the dashboard";

    setErrors({
      ...errors,
      requestError: _error,
    });
  };

  const renderDashboard = () => {
    const embeddingContainer = document.getElementById("embeddingContainer");
    if (embeddingContainer) {
      embeddingContainer.innerHTML = "";
    }

    const options = {
      url: dashboardUrl,
      container: "#embeddingContainer",
      scrolling: "no",
      height: "AutoFit",
      iframeResizeOnSheetChange: true,
      width: "100%",
      locale: "en-US",
      footerPaddingEnabled: true,
      sheetTabsDisabled: true,
      printEnabled: true,
      undoRedoDisabled: false,
      resetDisabled: false,
    };

    setEmbeddedDashboard(embedDashboard(options));
  };

  useEffect(() => {
    setLoading(true);
    setSheetOptions([]);
    axios
      .get(
        `quicksight/embed/${
          "embedded_details" in dashboard &&
          "dashboard_uuid" in dashboard.embedded_details
            ? dashboard.embedded_details.dashboard_uuid
            : dashboard.uuid
        }`,
      )
      .then((res) => {
        setDashboardUrl(res.data.result);
      })
      .catch((err) => {
        console.error(err);
        let errorMessage = "Something went wrong loading dashboard";
        if (err?.response?.status === 403) {
          errorMessage = "You do not have permission to access the dashboard";
        }
        setErrors({
          ...errors,
          requestError: errorMessage,
        });
      });
  }, [dashboard]);

  useEffect(() => {
    if (dashboardRef.current && dashboardUrl) {
      renderDashboard();
    }
  }, [dashboardRef.current, dashboardUrl]);

  useEffect(() => {
    if (embeddedDashboard) {
      embeddedDashboard.on("error", onError);
      embeddedDashboard.on("load", onDashboardLoad);
    }
  }, [embeddedDashboard]);

  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (e.data.eventName === "RESIZE_EVENT") {
        if (e.data.payload.height < 250 && window.innerHeight > 250) {
          resizeIframe();
        }
      }
    });
  }, []);

  const renderHeader = () => (
    <div>
      {sheetOptions.length > 1 && (
        <Tabs
          currentTab={currentSheet}
          onSelect={(e) => {
            setCurrentSheet(e);
            if (embeddedDashboard) {
              embeddedDashboard.navigateToSheet(e);
            }
            resizeIframe();
          }}
        >
          {sheetOptions.map((option) => (
            <Tabs.Content
              key={option.value}
              title={option.label}
              tabKey={option.value}
            />
          ))}
        </Tabs>
      )}
    </div>
  );

  const renderBody = () => {
    if (errors.requestError || errors.notFound) {
      return (
        <GraphicCallout
          error="issue"
          header={errors.requestError || "Dashboard not found"}
        />
      );
    }

    const height = `calc(100vh - ${sheetOptions.length > 0 ? 230 : 185}px)`;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: colorTheme("white"),
          minHeight: height,
          maxHeight: height,
        }}
      >
        {loading && <Loading center style={{ marginTop: gridSpacing[11] }} />}
        <div
          ref={dashboardRef}
          style={{
            display: loading ? "none" : "flex",
            flexDirection: "column",
            flexGrow: 1,
            width: "100%",
            zIndex: 1,
            maxHeight: height,
          }}
          id="embeddingContainer"
        />
      </div>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderBody()}
    </>
  );
};

export default QuicksightDashboard;
