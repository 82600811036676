// Note: Leave null if you do not want to use the url
export default {
  recode: {
    ruleset: null,
    rule: null,
    valuemapping:
      "/data-hub/transformation/recoding/value-mapping/view/:objectId",
    valuemappingforrule: null,
    valuemappingrule: null,
  },
  orchestration: {
    dataobject: null,
  },
  pipeline: {
    pipelinestep: null,
  },
  provider_directory: {
    organization: "/provider-360/provider-directory/organizations",
    tablecolumn: "/provider-360/provider-directory/options",
    validationrule: null,
    servicetype: "/provider-360/provider-directory/options",
    servicehours: null,
    service: null,
    specialty: "/provider-360/provider-directory/options",
    identifier: "/provider-360/provider-directory/options",
    image: "/provider-360/provider-directory/options",
    contactattributes: "/provider-360/provider-directory/options",
    webproperties: "/provider-360/provider-directory/options",
    providervisitcategory: "/provider-360/provider-directory/options",
    facilitycategory: "/provider-360/provider-directory/options",
    companycategory: "/provider-360/provider-directory/options",
    providernameattributes: "/provider-360/provider-directory/options",
    healthplanattributes: "/provider-360/provider-directory/options",
    healthplanproductattributes: "/provider-360/provider-directory/options",
    company: `/provider-360/provider-directory/companies/view/:objectId`,
    providercultureattributes: null,
    ProviderPracticeType: "/provider-360/provider-directory/options",
    providerpracticestatus: "/provider-360/provider-directory/options",
    provideremploymenttype: "/provider-360/provider-directory/options",
    provider: "/provider-360/provider-directory/providers/view/:objectId",
    providerspecialty: null,
    region: "/provider-360/provider-directory/options",
    healthplan: "/provider-360/provider-directory/health-plans/view/:objectId",
    healthplanproduct: null,
    careteamtype: "/provider-360/provider-directory/options",
    careteam: "/provider-360/provider-directory/care-teams/view/:objectId",
    facilitytype: "/provider-360/provider-directory/options",
    location: null,
    facility: "/provider-360/provider-directory/facilities/view/:objectId",
    facilitycareteam: null,
    facilityhealthplan: null,
    providerfacility: null,
    activitysummaryemailconfig: null,
  },
};
