import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

interface ProviderDirectoryOrganizationState {
  fetchList: boolean;
}

const initialState: ProviderDirectoryOrganizationState = {
  fetchList: false,
};

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: (create) => {
    return {
      setOrganizationFetchListRedux: create.reducer(
        (state, action: PayloadAction<boolean>) => {
          state.fetchList = action.payload;
        },
      ),
    };
  },
});

export const { setOrganizationFetchListRedux } = organizationSlice.actions;

export default organizationSlice.reducer;
