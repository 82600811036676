export const getStackedBarChartData = (
  data: Record<string, string>[],
  optionsKey: string,
  stackKey: string,
  countKey: string,
) => {
  const options = [...new Set(data.map((obj) => obj[optionsKey]))];

  const objectData: Record<string, number[]> = {};

  data.forEach((obj) => {
    const stateIndex = options.indexOf(obj[optionsKey]);

    if (objectData[obj[stackKey]]) {
      objectData[obj[stackKey]][stateIndex] = parseInt(obj[countKey]);
    } else {
      const stackedData = new Array(options.length).fill(0);

      stackedData[stateIndex] = parseInt(obj[countKey]);

      objectData[obj[stackKey]] = stackedData;
    }
  });

  const totals: number[] = [];
  Object.keys(objectData).forEach((key) => {
    objectData[key].forEach((value, index) => {
      if (totals[index]) {
        totals[index] += value;
      } else {
        totals[index] = value;
      }
    });
  });

  return {
    options,
    data: Object.keys(objectData).map((key) => {
      return {
        name: key,
        data: objectData[key].map((value, index) =>
          parseFloat(((value / totals[index]) * 100).toFixed(2)),
        ),
      };
    }),
  };
};

export const roundToTwoDecimals = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100;

export const viewActivityFilterDimensions: string[] = [
  "insights_dashboard_views.page_name",
  "insights_dashboard_views.object_type",
];

export const userFilterDimensions: string[] = ["user.user_name"];

export const groupFilterDimensions: string[] = ["group.group_name"];
