const initialState = {
  valueLists: {
    isFetching: true,
    error: false,
    result: [],
  },
  valueListClone: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_VALUE_LISTS": {
      return Object.assign({}, state, {
        valueLists: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    case "ADD_VALUE_LIST": {
      return Object.assign({}, state, {
        valueLists: {
          ...state.valueLists,
          result: [...state.valueLists.result, action.payload],
        },
      });
    }

    case "REPLACE_VALUE_LIST": {
      const result = state.valueLists.result.map((list) => {
        if (list.identifier === action.payload.identifier) {
          return action.payload;
        }
        return list;
      });

      return Object.assign({}, state, {
        valueLists: {
          ...state.valueLists,
          result: result,
        },
      });
    }

    case "DELETE_VALUE_LIST": {
      const result = state.valueLists.result.filter(
        (list) => list.identifier !== action.payload.identifier,
      );

      return Object.assign({}, state, {
        valueLists: {
          ...state.valueLists,
          result: result,
        },
      });
    }

    case "ADD_VALUE_LIST_CLONE": {
      return Object.assign({}, state, {
        valueListClone: action.payload.clonedData,
      });
    }

    case "DELETE_VALUE_LIST_CLONE": {
      return Object.assign({}, state, {
        valueListClone: null,
      });
    }

    default:
      return state;
  }
}
