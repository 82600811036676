import React from "react";

import { styled } from "styled-components";

import { colorTheme } from "@utils";

import { parseToFixed } from "~/src/utils/stringUtils";

const ColoredContainer = styled.div<{
  color: string;
}>`
  background-color: ${({ color }) => color};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1px;
  left: 1px;
  right: 0;
  bottom: 0;
`;

const styleMapping = {
  success: {
    background: colorTheme("successL4"),
    color: colorTheme("success"),
    icon: "fa-solid fa-circle",
  },
  successLight: {
    background: colorTheme("successL5"),
    color: colorTheme("successL2"),
    icon: "fa-solid fa-circle-half-stroke",
  },
  warning: {
    background: colorTheme("warningL5"),
    color: colorTheme("warningL2"),
    icon: "fa-solid fa-octagon",
  },
  warningDark: {
    background: colorTheme("warningL3"),
    color: colorTheme("warning"),
    icon: "fa-solid fa-triangle",
  },
  danger: {
    background: colorTheme("dangerL2"),
    color: colorTheme("danger"),
    icon: "fa-solid fa-diamond",
  },
} as const;
type StyleKey = keyof typeof styleMapping;

export const getUtilizationColor = (value: number) => {
  let styleKey: StyleKey = "danger";
  if (value > 20 && value <= 40) {
    styleKey = "warningDark";
  } else if (value > 40 && value <= 60) {
    styleKey = "warning";
  } else if (value > 60 && value <= 80) {
    styleKey = "successLight";
  } else if (value > 80) {
    styleKey = "success";
  }
  return styleMapping[styleKey];
};
export const getAvailabiltyStyling = (value: number) => {
  let styleKey: StyleKey = "danger";
  if (value <= 5) {
    styleKey = "success";
  } else if (value > 5 && value <= 10) {
    styleKey = "successLight";
  } else if (value > 10 && value <= 15) {
    styleKey = "warning";
  } else if (value > 15 && value <= 20) {
    styleKey = "warningDark";
  }
  return styleMapping[styleKey];
};

export const getUtilizationColorSquare = (valueString: string) => {
  const value = +parseToFixed({
    multiplier: 100,
    input: valueString,
  });

  if (isNaN(value)) {
    return (
      <ColoredContainer color={colorTheme("neutralL5")}>-</ColoredContainer>
    );
  }

  const { background } = getUtilizationColor(value);

  return <ColoredContainer color={background}>{value}%</ColoredContainer>;
};

export const getAvailabilityColorSquare = (valueString: string) => {
  const value = +parseToFixed({
    input: valueString,
  });

  if (isNaN(value)) {
    return (
      <ColoredContainer color={colorTheme("neutralL4")}>
        {isNaN(value) ? "-" : value}
      </ColoredContainer>
    );
  }

  const { background } = getAvailabiltyStyling(value);

  return <ColoredContainer color={background}>{value}</ColoredContainer>;
};

export const getAvailabilityColorIcon = (value: number) => {
  const styling = getAvailabiltyStyling(value);
  return { iconColor: styling.color, icon: styling.icon };
};

export const colorLegend = (
  <>
    <span>
      Color coding for Next Available Days: Dark Green: greater than 80%; Light
      Green: greater than 60% to 80%; Yellow: greater than 40% to 60%; Orange:
      greater than 20% to 40%; Red: 20% or less
    </span>
    <br />
    <span>
      Color coding for Available Slot %: Dark Green: Up to 5 days; Light Green:
      greater than 5 to 10 days; Yellow: greater than 10 to 15 days; Orange:
      greater than 15 to 20 days; Red: greater than 20 days.
    </span>
  </>
);
export const nullFallback = (val: string | null | undefined) => {
  if (val === null) {
    return "-";
  }
  return val;
};
