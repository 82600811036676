import React, { memo } from "react";

import equal from "fast-deep-equal/es6";
import * as Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown.js";
import HighchartsReact from "highcharts-react-official";

import { colorTheme } from "@utils";

import Error from "./Common/Error";
import Header from "./Common/Header";
import Loading from "./Common/Loading";
import { Wrapper } from "./Common/styles";

type Series = {
  name: string;
  id?: string;
  data: number[] | [string, number][] | Record<string, string | number>[];
  showInLegend?: boolean;
}[];

type BarChartProps = {
  title: string;
  subtitle?: string;
  type?: "bar" | "stacked" | "histogram";
  yAxisLabel: string;
  xAxisLabel?: string;
  maxNumber?: number;
  options?: string[];
  highlightTooltipSuffix?: string;
  data: Series;
  linkUrl?: string;
  linkText?: string;
  toolTip?: string;
  loading?: boolean;
  error?: string | null;
  drillDown?: Series;
  formatter?: (this: Highcharts.TooltipFormatterContextObject) => string;
  hideXAxisLabels?: boolean;
};

const BarChart = memo(
  ({
    title,
    subtitle,
    type = "bar",
    yAxisLabel,
    xAxisLabel,
    options,
    maxNumber,
    highlightTooltipSuffix,
    data,
    linkUrl,
    linkText,
    toolTip,
    loading,
    error,
    drillDown,
    formatter,
    hideXAxisLabels,
  }: BarChartProps) => {
    const renderBarChart = () => {
      const barOptions: Highcharts.Options = {
        chart: {
          type: "column",
        },
        yAxis: {
          min: 0,
          max: maxNumber ?? undefined,
          title: {
            text: yAxisLabel,
          },
        },
        tooltip: {
          valueSuffix: highlightTooltipSuffix,
          formatter,
        },
        plotOptions: {
          column: {
            pointPadding: type === "histogram" ? 0 : 0.05,
            groupPadding: type === "histogram" ? 0 : 0.1,
            stacking: type === "stacked" ? "normal" : undefined,
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: `{point.y:,.0f}${highlightTooltipSuffix ?? ""}`,
              style: {
                fontSize: "13px",
              },
            },
          },
        },
        series: data as Highcharts.SeriesBarOptions[],
        legend: {
          align: "left",
        },
        title: { text: undefined },
        credits: {
          enabled: false,
        },
      };

      if (options) {
        barOptions.xAxis = { categories: options, crosshair: true };
      } else {
        barOptions.xAxis = { type: "category", crosshair: true };
      }
      if (hideXAxisLabels) {
        barOptions.xAxis.labels = {
          enabled: false,
        };
      }

      if (xAxisLabel) {
        barOptions.xAxis.title = {
          text: xAxisLabel,
        };
      }

      if (drillDown) {
        drilldown(Highcharts);
        barOptions.drilldown = {
          series: drillDown as Highcharts.SeriesBarOptions[],
          activeAxisLabelStyle: {
            color: colorTheme("neutralL1"),
          },
          activeDataLabelStyle: {
            color: colorTheme("neutralL1"),
            textDecoration: "none",
          },
        };
      }

      return <HighchartsReact highcharts={Highcharts} options={barOptions} />;
    };

    if (error) {
      return <Error errorMessage={error} />;
    }

    if (loading) {
      return <Loading />;
    }

    return (
      <Wrapper>
        <Header
          title={title}
          subtitle={subtitle}
          toolTip={toolTip}
          linkUrl={linkUrl}
          linkText={linkText}
        />
        {renderBarChart()}
      </Wrapper>
    );
  },
  (oldProps, newProps) => equal(oldProps, newProps),
);

export default BarChart;
