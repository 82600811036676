import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

interface ProviderDirectoryProviderState {
  fetchList: boolean;
}

const initialState: ProviderDirectoryProviderState = {
  fetchList: false,
};

const providerSlice = createSlice({
  name: "provider",
  initialState,
  reducers: (create) => {
    return {
      setProviderFetchListRedux: create.reducer(
        (state, action: PayloadAction<boolean>) => {
          state.fetchList = action.payload;
        },
      ),
    };
  },
});

export const { setProviderFetchListRedux } = providerSlice.actions;

export default providerSlice.reducer;
