import { combineReducers } from "redux";

import entityUnification from "./entityUnification";
import goldenRecord from "./goldenRecord";
import source from "./source";
import validator from "./validator";

export default combineReducers({
  source,
  goldenRecord,
  entityUnification,
  validator,
});
