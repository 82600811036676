import { DataViz } from "@commonComponents";

import type { DashboardAdvancedFilterProps } from "../../Portal/Portal";
import type { AdvFilterProps } from "~/src/utils/tsUtils";

// TOOD: after advanced filters, figure out how to filter these to show different timeframes, they are measures
export const appointmentsByFacilityQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: [
      "appointment_completed.appointment_category",
      "appointment_completed.facility_name",
      "facility.facility_id",
    ],
    measures: ["appointment_completed.total_appt_count_last_12_month"],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const appointmentsByProviderQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: [
      "appointment_completed.appointment_category",
      "appointment_completed.provider_full_name",
      "provider.provider_id",
    ],
    measures: ["appointment_completed.total_appt_count_last_12_month"],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const appointmentsFilterDimensions: string[] = [
  "roster_active.plan_state",
  "roster_active.payer_name",
  "roster_active.market_segment",
  "roster_active.market_sub_segment",
  "roster_active.active_flag",
  "assigned_consumer_detail.panel_name",
  "assigned_consumer_detail.provider_name_npi",
  "assigned_consumer_detail.provider_practice_type_name",
  "assigned_consumer_detail.facility_name",
  "assigned_consumer_detail.facility_state",
  "assigned_consumer_detail.facility_zip",
  "appointment_completed.appointment_category",
  "appointment_completed.appointment_type",
  "assigned_consumer_detail.state",
  "assigned_consumer_detail.city",
  "assigned_consumer_detail.zip",
  "assigned_consumer_detail.gender",
];
