const baseUrl = "/system";

export default {
  ACCOUNT_SETTINGS_SLACK_NOTIFICATIONS: `${baseUrl}/account-settings/slack-notifications`,
  USER_MANAGEMENT_USERS: `${baseUrl}/user-management/users`,
  USER_MANAGEMENT_GROUPS: `${baseUrl}/user-management/teams`,
  USER_MANAGEMENT_QUICKSIGHT_GROUPS: `${baseUrl}/user-management/quicksight-groups`,
  DOCUMENTATION_ACCOUNT: `${baseUrl}/documentation/account`,
  DOCUMENTATION_OVERVIEW: `${baseUrl}/documentation/overview`,
  DOCUMENTATION_API: `${baseUrl}/documentation/api`,
  PUBLIC_DOCUMENTATION_API: `${baseUrl}/documentation/public-api`,
  TOKEN_MANAGEMENT: `${baseUrl}/token-management`,
};
