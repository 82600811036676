import React, { useEffect, useState } from "react";

import moment from "moment";
import PropTypes from "prop-types";

import { colorTheme } from "@utils";

function GanttGraph({ startDate, endDate, onEventClick, events }) {
  let dateCount = moment(endDate).diff(moment(startDate), "days");

  const [eventDateDictionary, setEventDateDictionary] = useState({});
  const [drawnColumns, setDrawnColumns] = useState([]);
  const [refreshGantt, setRefreshGantt] = useState(0);

  function fitToContainer(canvas, timeHeader) {
    canvas.style.width = "100%";
    canvas.width = canvas.offsetWidth;

    timeHeader.width = timeHeader.offsetWidth;
    timeHeader.style.width = "100%";
  }

  const getCanvasColumn = ({ startX, startY, dateWidth, hour, date }) => {
    let lastHeight = startY;

    let columnItems = [];

    if (eventDateDictionary[date] && eventDateDictionary[date][hour]) {
      eventDateDictionary[date][hour].forEach((obj, i) => {
        lastHeight = 25 * i + startY;

        if (Object.keys(obj).length > 0) {
          let startTime = moment.unix(obj.start_time);
          let endTime = obj.end_time
            ? moment.unix(obj.end_time)
            : moment.unix(moment().valueOf() / 1000);

          let startHour = startTime.format("H");
          let startMinute = startTime.format("m");
          let endHour = endTime.format("H");
          let endMinute = endTime.format("m");

          let startPercentage = startMinute / 60;
          let rectWidth = 0;

          if (!startTime.isSame(endTime, "hour")) {
            if (startHour === hour + "") {
              let startPoint = dateWidth * startPercentage;
              rectWidth = dateWidth - startPoint;
            } else {
              rectWidth = dateWidth;
              startPercentage = 0;
            }
          }

          if (endHour === hour + "") {
            rectWidth = dateWidth * (endMinute / 60 - startPercentage);
          }

          if (rectWidth < dateWidth * 0.05) {
            rectWidth = dateWidth * 0.05;
          }
          columnItems.push({
            ...obj,
            x: startX + dateWidth * startPercentage,
            y: lastHeight - 30,
            width: rectWidth,
            color: obj.color,
          });
        }
      });
    }

    return {
      columnItems,
      lastHeight,
    };
  };

  const getTimeLabel = (hour) => {
    if (hour === 12) return "12pm";
    else if (hour === 0) return "12am";

    return hour > 12 ? hour - 12 + "pm" : hour + "am";
  };

  const buildCanvas = () => {
    const canvas = document.getElementById("canvas");
    const ctx = canvas.getContext("2d");
    const timeHeaderCanvas = document.getElementById("timeHeader");
    const timeHeaderCtx = timeHeaderCanvas.getContext("2d");
    fitToContainer(canvas, timeHeaderCanvas);

    const width = canvas.width;

    const dateWidth = 126;
    let columnWidth = (width - dateWidth) / 24;

    ctx.font = "12px Arial";
    timeHeaderCtx.font = "12px Arial";

    let drawDates = [];
    let drawColumns = [];

    let dateHeight = 0;
    let j = 0;
    do {
      let currentDay = moment(endDate);
      currentDay.subtract(j, "days");
      j++;

      let startY = dateHeight + 30;
      for (let i = 0; i < 24; i++) {
        let startX = columnWidth * i + dateWidth;

        let incomingColumn = getCanvasColumn({
          startX,
          startY,
          dateWidth: columnWidth,
          hour: i,
          date: currentDay.format("MM/DD/YYYY"),
        });

        if (incomingColumn.lastHeight > dateHeight) {
          dateHeight = incomingColumn.lastHeight;
        }

        drawColumns = drawColumns.concat(incomingColumn.columnItems);
      }

      let drawDateY = dateHeight - 10;
      if (dateHeight !== startY) {
        drawDateY = dateHeight - (dateHeight - startY) / 2 - 12;
      }

      drawDates.push({
        text: currentDay.format("MM/DD/YYYY"),
        y: drawDateY,
      });
      drawColumns.push({ width, dateHeight });
    } while (j < dateCount + 1);

    canvas.height = dateHeight;

    ctx.fillStyle = "#f3f3f3";
    ctx.fillRect(0, 0, 125, canvas.height);
    ctx.stroke();

    for (let i = 0; i < 24; i++) {
      let startX = columnWidth * i + dateWidth;

      timeHeaderCtx.fillStyle = "#000";
      timeHeaderCtx.fillText(
        getTimeLabel(i),
        startX + columnWidth / 5,
        15,
        columnWidth,
      );

      timeHeaderCtx.strokeStyle = "#ddd";
      timeHeaderCtx.beginPath();
      timeHeaderCtx.moveTo(startX, 0);
      timeHeaderCtx.lineTo(startX, 25);
      timeHeaderCtx.stroke();
      timeHeaderCtx.closePath();

      timeHeaderCtx.beginPath();
      timeHeaderCtx.moveTo(0, 25);
      timeHeaderCtx.lineTo(width, 25);
      timeHeaderCtx.stroke();
      timeHeaderCtx.closePath();

      ctx.strokeStyle = "#ddd";
      ctx.beginPath();
      ctx.moveTo(startX, 0);
      ctx.lineTo(startX, canvas.height);
      ctx.stroke();
      ctx.closePath();
    }

    ctx.fillStyle = "#000";
    ctx.font = "500 16px Arial";
    drawDates.forEach((obj) => {
      ctx.fillText(obj.text, 20, obj.y);
    });

    setDrawnColumns(drawColumns);

    drawColumns.forEach((obj) => {
      if (obj.color) {
        ctx.fillStyle = obj.color;
        ctx.fillRect(obj.x, obj.y, obj.width, 24);
        ctx.stroke();
      } else {
        ctx.beginPath();
        ctx.moveTo(0, obj.dateHeight);
        ctx.lineTo(obj.width, obj.dateHeight);
        ctx.stroke();
        ctx.closePath();
      }
    });
  };

  useEffect(() => {
    let _eventsDateDictionary = {};

    events
      .sort((a, b) => a.end_time - b.start_time)
      .forEach((res) => {
        let startTime = moment.unix(res.start_time);
        let endTime = res.end_time
          ? moment.unix(res.end_time)
          : moment(res.start_time);
        let startTimeDate = startTime.format("MM/DD/YYYY");
        let startTimeHour = startTime.format("H");
        let endTimeHour = endTime.format("H");

        if (!_eventsDateDictionary[startTimeDate]) {
          _eventsDateDictionary[startTimeDate] = {};
        }

        if (_eventsDateDictionary[startTimeDate][startTimeHour]) {
          _eventsDateDictionary[startTimeDate][startTimeHour].push(res);
        } else {
          _eventsDateDictionary[startTimeDate][startTimeHour] = [res];
        }

        /*
        Trying to implemented an event spreading multiple days 

        let startEndDayDifference = endTime.diff(startTime, "days");

        if (startEndDayDifference > 0) {
          for (let j = 0; j < startEndDayDifference; j++) {
            let day = moment(startTime).add(j, "day");

            if (day.isSameOrAfter(startDate)) {
              j = startEndDayDifference;
            }

            let formattedDay = day.format("MM/DD/YYYY");

            if (!_eventsDateDictionary[formattedDay]) {
              _eventsDateDictionary[formattedDay] = {};
            }

            if (endTime.isSame(day)) {
              for (let j = 0; j < endTimeHour; j++) {
                if (!_eventsDateDictionary[formattedDay][j]) {
                  _eventsDateDictionary[formattedDay][j] = [];
                }

                for (
                  let i = _eventsDateDictionary[formattedDay][j].length;
                  i <
                  _eventsDateDictionary[formattedDay][endTimeHour].length - 1;
                  i++
                ) {
                  _eventsDateDictionary[formattedDay][j].push({});
                }

                _eventsDateDictionary[formattedDay][j].push(res);
              }
            } else {
              for (let i = 0; i < 24; i++) {
                if (!_eventsDateDictionary[formattedDay][i]) {
                  _eventsDateDictionary[formattedDay][i] = [];
                }

                for (
                  let ii = _eventsDateDictionary[startTimeDate][i].length;
                  ii <
                  _eventsDateDictionary[startTimeDate][startTimeHour].length -
                    1;
                  ii++
                ) {
                  _eventsDateDictionary[startTimeDate][i].push({});
                }

                _eventsDateDictionary[formattedDay][i].push(res);
              }
            }
          }
        }

        */

        if (endTimeHour !== startTimeHour) {
          for (
            let j = parseInt(startTimeHour) + 1;
            j < parseInt(endTimeHour) + 1;
            j++
          ) {
            if (!_eventsDateDictionary[startTimeDate][j]) {
              _eventsDateDictionary[startTimeDate][j] = [];
            }

            for (
              let i = _eventsDateDictionary[startTimeDate][j].length;
              i <
              _eventsDateDictionary[startTimeDate][startTimeHour].length - 1;
              i++
            ) {
              _eventsDateDictionary[startTimeDate][j].push({});
            }

            _eventsDateDictionary[startTimeDate][j].push(res);
          }
        }
      });

    setEventDateDictionary(_eventsDateDictionary);
  }, [events]);

  useEffect(buildCanvas, [JSON.stringify(eventDateDictionary), refreshGantt]);

  useEffect(() => {
    const refreshGanttFunc = () => setRefreshGantt(Math.random());
    window.addEventListener("resize", refreshGanttFunc);

    return () => {
      window.removeEventListener("resize", refreshGanttFunc);
    };
  }, []);

  const checkTargetHover = (mouseX, mouseY, objX, objY, objWidth) =>
    objX < mouseX &&
    objX + objWidth > mouseX &&
    objY < mouseY &&
    objY + 24 > mouseY;

  const canvasClick = (e) => {
    e.preventDefault();

    let canvas = document.getElementById("canvas");
    let canvasOffset = canvas.getBoundingClientRect();
    let offsetX = canvasOffset.left;
    let offsetY = canvasOffset.top;

    // get the mouse position
    let mouseX = parseInt(e.clientX - offsetX);
    let mouseY = parseInt(e.clientY - offsetY);

    let clickedEvent = drawnColumns.find((obj) =>
      checkTargetHover(mouseX, mouseY, obj.x, obj.y, obj.width),
    );

    if (clickedEvent) {
      onEventClick(clickedEvent);
    }
  };

  const canvasMouseMove = (e) => {
    e.preventDefault();

    let canvas = document.getElementById("canvas");
    let canvasOffset = canvas.getBoundingClientRect();
    let offsetX = canvasOffset.left;
    let offsetY = canvasOffset.top;

    // get the mouse position
    let mouseX = parseInt(e.clientX - offsetX);
    let mouseY = parseInt(e.clientY - offsetY);

    let clickedEvent = drawnColumns.find((obj) =>
      checkTargetHover(mouseX, mouseY, obj.x, obj.y, obj.width),
    );

    if (clickedEvent) {
      canvas.style.cursor = "pointer";
    } else {
      canvas.style.cursor = "default";
    }
  };

  return (
    <div>
      <div style={{ background: colorTheme("neutralL5"), height: 25 }}>
        <canvas id="timeHeader" />
      </div>
      <div
        style={{
          background: colorTheme("white"),
          maxHeight: 425,
          overflow: "auto",
        }}
      >
        <canvas
          id="canvas"
          onClick={canvasClick}
          onMouseMove={canvasMouseMove}
        />
      </div>
    </div>
  );
}

GanttGraph.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  /** Events array with each object containing 'start_time', 'end_time', and 'color' */
  events: PropTypes.array.isRequired,
  /** Callback on event click */
  onEventClick: PropTypes.func,
};

export default GanttGraph;
