const initialState = {
  users: {
    isFetching: true,
    error: false,
    result: [],
  },
  groups: {
    isFetching: true,
    error: false,
    result: [],
  },
  editUsername: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "QUICKSIGHT_SET_USERS": {
      return Object.assign({}, state, {
        users: {
          ...state.users,
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    case "QUICKSIGHT_ADD_USER": {
      return Object.assign({}, state, {
        users: {
          ...state.users,
          result: [...state.users.result, action.payload.userObj],
        },
      });
    }

    case "QUICKSIGHT_EDIT_USER": {
      const updatedData = action.payload.updatedData;

      const users = state.users.result.map((user) => {
        if (user.username === updatedData.username) {
          Object.keys(updatedData).forEach((key) => {
            user[key] = updatedData[key];
          });
        }
        return user;
      });
      return Object.assign({}, state, {
        users: {
          ...state.users,
          result: users,
        },
      });
    }

    case "QUICKSIGHT_REMOVE_USER": {
      return Object.assign({}, state, {
        users: {
          ...state.users,
          result: state.users.result.filter(
            (user) => user.username !== action.payload.username,
          ),
        },
      });
    }

    case "QUICKSIGHT_SET_GROUPS": {
      return Object.assign({}, state, {
        groups: {
          ...state.groups,
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    case "QUICKSIGHT_ADD_GROUP": {
      return Object.assign({}, state, {
        groups: {
          ...state.groups,
          result: [...state.groups.result, action.payload.newGroup],
        },
      });
    }

    case "QUICKSIGHT_UPDATE_GROUP": {
      return Object.assign({}, state, {
        groups: {
          ...state.groups,
          result: state.groups.result.map((group) => {
            if (group.name === action.payload.group.name) {
              return action.payload.group;
            }
            return group;
          }),
        },
      });
    }

    case "QUICKSIGHT_REMOVE_GROUP": {
      return Object.assign({}, state, {
        groups: {
          ...state.groups,
          result: state.groups.result.filter(
            (group) => group.name !== action.payload.name,
          ),
        },
      });
    }

    case "QUICKSIGHT_SET_EDIT_USER": {
      return Object.assign({}, state, {
        editUsername: action.payload.username,
      });
    }

    default:
      return state;
  }
}
