const initialState = {
  ruleSets: {
    isFetching: true,
    error: false,
    result: [],
  },
  ruleSet: {
    isFetching: true,
    error: false,
    result: {},
  },
  targets: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "ENTITY_UNIFICATION_SET_RULE_SETS": {
      return Object.assign({}, state, {
        ruleSets: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    case "ENTITY_UNIFICATION_ADD_RULE_SET": {
      return Object.assign({}, state, {
        ruleSets: {
          ...state.ruleSets,
          result: [...state.ruleSets.result, action.payload],
        },
      });
    }

    case "ENTITY_UNIFICATION_UPDATE_RULE_SET": {
      let _ruleSets = state.ruleSets.result.map((ruleSet) => {
        if (ruleSet.identifier === action.payload.identifier) {
          return action.payload;
        }
        return ruleSet;
      });
      return Object.assign({}, state, {
        ruleSets: {
          ...state.ruleSets,
          result: _ruleSets,
        },
      });
    }

    case "ENTITY_UNIFICATION_SET_RULE_SET": {
      return Object.assign({}, state, {
        ruleSet: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    case "ENTITY_UNIFICATION_DELETE": {
      let newRules = [...state.ruleSets.result].filter(
        (rule) => rule.uuid !== action.payload.targetId,
      );

      return Object.assign({}, state, {
        ruleSets: { ...state.ruleSets, result: newRules },
      });
    }

    case "ENTITY_UNIFICATION_SET_TARGET_TABLE": {
      return Object.assign({}, state, {
        ...state,
        targets: action.payload.targets,
      });
    }

    case "ENTITY_UNIFICATION_ADD_TARGET_TABLE": {
      let newTargets = [...state.targets];
      const foundTarget = state.targets.some(
        (target) => target === action.payload.targetId,
      );

      if (!foundTarget) {
        newTargets.push(action.payload.targetId);

        return Object.assign({}, state, { ...state, targets: newTargets });
      } else {
        return state;
      }
    }

    case "ENTITY_UNIFICATION_REMOVE_TARGET_TABLE": {
      return Object.assign({}, state, {
        ...state,
        targets: state.targets.filter(
          (target) => target !== action.payload.targetId,
        ),
      });
    }

    case "ENTITY_UNIFICATION_UPDATE_STATUS": {
      let newRules = [
        ...state.ruleSets.result.map((rule) => {
          if (rule.identifier === action.payload.identifier) {
            rule.state = rule.state === "ACTIVE" ? "INACTIVE" : "ACTIVE";
          }
          return rule;
        }),
      ];
      return Object.assign({}, state, {
        ruleSets: { ...state.ruleSets, result: newRules },
      });
    }

    default:
      return state;
  }
}
