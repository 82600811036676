import React from "react";

import PropTypes from "prop-types";
import { styled } from "styled-components";

import { colorTheme } from "@utils";

import Header from "./StepperHeader";

const StepperContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  white-space: nowrap;
  justify-content: space-between;
  width: 100%;
`;

const HorizontalLine = styled.div`
  margin: 0;
  min-width: 0;
  position: relative;
  top: 36px;
  border-top-width: 1px;
  border-top-style: solid;
  flex: auto;
  height: 0;
  border-top-color: ${colorTheme("neutralL3")};
  white-space: nowrap;
`;

function Stepper({ children }) {
  return (
    <StepperContainer>
      {children.map((child, i) => (
        <React.Fragment key={i}>
          {i !== children.length - 1 ? (
            <>
              {child}
              <HorizontalLine />
            </>
          ) : (
            child
          )}
        </React.Fragment>
      ))}
    </StepperContainer>
  );
}

Stepper.propTypes = {
  children: PropTypes.any,
};

Stepper.Header = Header;

export default Stepper;
