const initialState = {
  dataObjects: {
    isFetching: true,
    error: false,
    result: [],
  },
  sources: {
    isFetching: true,
    error: false,
    result: [],
  },
  source: {
    isFetching: true,
    error: false,
    result: [],
  },
  connections: {
    isFetching: true,
    error: false,
    result: [],
  },
  pipelineFunctions: {
    isFetching: true,
    error: false,
    result: [],
  },
  lambdaRegistryEntries: {
    isFetching: true,
    error: false,
    result: [],
  },
  emailTemplates: {
    isFetching: true,
    error: false,
    result: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "ORCHESTRATION_SET_SOURCES": {
      return Object.assign({}, state, {
        sources: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    case "ORCHESTRATION_ADD_SOURCE": {
      return Object.assign({}, state, {
        sources: {
          ...state.sources,
          result: [...state.sources.result, action.payload],
        },
      });
    }

    case "ORCHESTRATION_UPDATE_SOURCE": {
      let _sources = state.sources.result.map((source) => {
        if (source.uuid === action.payload.uuid) {
          return action.payload;
        }
        return source;
      });
      return Object.assign({}, state, {
        sources: {
          ...state.sources,
          result: _sources,
        },
      });
    }

    case "ORCHESTRATION_REMOVE_SOURCE": {
      return Object.assign({}, state, {
        sources: {
          ...state.sources,
          result: state.sources.result.filter(
            (source) => source.uuid !== action.payload.sourceId,
          ),
        },
      });
    }

    case "ORCHESTRATION_SET_DATA_OBJECTS": {
      return Object.assign({}, state, {
        dataObjects: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    case "ORCHESTRATION_ADD_DATA_OBJECT": {
      let _sources = state.sources.result.map((source) => {
        if (source.uuid === action.payload.source) {
          source.data_objects = [...source.data_objects, action.payload];
        }

        return source;
      });

      return Object.assign({}, state, {
        dataObjects: {
          ...state.dataObjects,
          result: [...state.dataObjects.result, action.payload],
        },
        sources: {
          ...state.sources,
          result: _sources,
        },
      });
    }

    case "ORCHESTRATION_UPDATE_DATA_OBJECT": {
      let _dataObjects = state.dataObjects.result.map((dataObject) => {
        if (dataObject.uuid === action.payload.uuid) {
          return action.payload;
        }
        return dataObject;
      });

      let _sources = state.sources.result.map((source) => {
        if (source.uuid === action.payload.source) {
          source.data_objects = source.data_objects.map((dataObject) => {
            if (dataObject.uuid === action.payload.uuid) {
              return action.payload;
            }
            return dataObject;
          });
        }

        return source;
      });

      return Object.assign({}, state, {
        dataObjects: {
          ...state.dataObjects,
          result: _dataObjects,
        },
        sources: {
          ...state.sources,
          result: _sources,
        },
      });
    }

    case "ORCHESTRATION_REMOVE_DATA_OBJECT": {
      return Object.assign({}, state, {
        sources: {
          ...state.sources,
          result: state.sources.result.map((source) => {
            return {
              ...source,
              data_objects: source.data_objects.filter(
                (obj) => obj.uuid !== action.payload.dataObjectId,
              ),
            };
          }),
        },
        dataObjects: {
          ...state.dataObjects,
          result: state.dataObjects.result.filter(
            (dataObject) => dataObject.uuid !== action.payload.dataObjectId,
          ),
        },
        source: {
          ...state.source,
          result: {
            ...state.source.result,
            data_objects: state.source.result.data_objects.filter(
              (dataObject) => dataObject.uuid !== action.payload.dataObjectId,
            ),
          },
        },
      });
    }

    case "ORCHESTRATION_SET_CONNECTIONS": {
      return Object.assign({}, state, {
        connections: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    case "ORCHESTRATION_ADD_CONNECTION": {
      return Object.assign({}, state, {
        connections: {
          ...state.connections,
          result: [...state.connections.result, action.payload.connection],
        },
      });
    }

    case "ORCHESTRATION_UPDATE_CONNECTION": {
      const newConnections = state.connections.result.filter(
        (connection) => connection.uuid !== action.payload.connection.uuid,
      );
      newConnections.push(action.payload.connection);

      return Object.assign({}, state, {
        connections: {
          ...state.connections,
          result: newConnections,
        },
      });
    }

    case "ORCHESTRATION_REMOVE_CONNECTION": {
      return Object.assign({}, state, {
        connections: {
          ...state.connections,
          result: state.connections.result.filter(
            (connection) => connection.uuid !== action.payload.connectionId,
          ),
        },
      });
    }

    case "ORCHESTRATION_SET_PIPELINE_FUNCTIONS": {
      return Object.assign({}, state, {
        pipelineFunctions: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    case "ORCHESTRATION_SET_LAMBDA_REGISTRY_ENTRIES": {
      return Object.assign({}, state, {
        lambdaRegistryEntries: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    case "ORCHESTRATION_SET_EMAIL_TEMPLATES": {
      return Object.assign({}, state, {
        emailTemplates: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    case "ORCHESTRATION_SET_SOURCE": {
      return Object.assign({}, state, {
        source: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    case "ORCHESTRATION_SET_CPS_SOURCES": {
      return Object.assign({}, state, {
        cpsSources: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    case "ORCHESTRATION_SET_METRICS": {
      return Object.assign({}, state, {
        metrics: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result.results,
        },
      });
    }
    case "ORCHESTRATION_SET_PANELS": {
      return Object.assign({}, state, {
        panels: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result.results,
        },
      });
    }

    default:
      return state;
  }
}
