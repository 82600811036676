import { DataViz } from "@commonComponents";

import type { DashboardAdvancedFilterProps } from "../../Portal/Portal";
import type { AdvFilterProps } from "~/src/utils/tsUtils";

export const payerBreakdownQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: [
      "roster_active.payer_name",
      "assigned_consumer_detail.panel_name",
    ],
    order: {
      "assigned_consumer_detail.total_luc_id_count": "desc",
    },
    measures: ["assigned_consumer_detail.total_luc_id_count"],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;

export const marketSegmentBreakdownQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: [
      "roster_active.market_segment",
      "assigned_consumer_detail.panel_name",
    ],
    order: {
      "assigned_consumer_detail.total_luc_id_count": "desc",
    },
    measures: ["assigned_consumer_detail.total_luc_id_count"],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;

export const payerBreakdownDimensions = [
  "roster_active.plan_state",
  "roster_active.payer_name",
  "roster_active.market_segment",
  "roster_active.market_sub_segment",
  "assigned_consumer_detail.panel_name",
  "assigned_consumer_detail.facility_name",
  "assigned_consumer_detail.provider_name_npi",
  "assigned_consumer_detail.provider_practice_type_name",
  "assigned_consumer_detail.facility_state",
  "assigned_consumer_detail.facility_zip",
  "assigned_consumer_detail.state",
  "assigned_consumer_detail.city",
  "assigned_consumer_detail.zip",
  "assigned_consumer_detail.gender",
];
