import React, { useState, useEffect } from "react";

import {
  Modal,
  Button,
  Text,
  Callout,
  TextArea,
  Switch,
} from "@lucernahealth/lucerna-health-ui";
import axios from "axios";
import PropTypes from "prop-types";

import { slugifyUrl } from "@utils";

const originalData = {
  name: "",
  slug: "",
  app: "",
  model: "",
  description: "",
  public: false,
};

const SaveFilterModal = ({
  isOpened,
  close,
  incomingData,
  app,
  model,
  query,
  filters,
  addFilter,
  updateFilter,
}) => {
  const [data, setData] = useState(originalData);
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (incomingData) {
      setIsEdit(true);
      setData(incomingData);
    } else {
      setData(originalData);
      setIsEdit(false);
    }

    setErrors({});
  }, [isOpened]);

  const validate = () => {
    let _errors = {};

    if (data.name.trim().length === 0) {
      _errors.name = "Name required";
    } else if (data.name.trim().length < 3) {
      _errors.name = "Name must be 3 or more characters long";
    } else if (data.name.trim().length > 200) {
      _errors.name = "Name must be less than 200 characters long";
    } else if (
      filters.some(
        (obj) =>
          obj.uuid !== data.uuid &&
          obj.name.toLowerCase() === data.name.toLowerCase(),
      )
    ) {
      _errors.name = "Name already exists";
    }

    let isValid = Object.keys(_errors).length === 0;
    if (!isValid) {
      _errors.main = "Not all fields have been satisfied";
    }

    setErrors(_errors);
    return isValid;
  };

  const submit = () => {
    if (validate()) {
      if (isEdit) {
        axios
          .put(`search/filters/${data.uuid}`, {
            ...data,
            app,
            model,
            query,
            slug: slugifyUrl(data.name),
          })
          .then((res) => {
            updateFilter(res.data.result);
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        axios
          .post("search/filters", {
            ...data,
            app,
            model,
            query,
            slug: slugifyUrl(data.name),
          })
          .then((res) => {
            addFilter(res.data.result);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  return (
    <Modal
      isOpened={isOpened}
      title={`${isEdit ? "Edit" : "Add"} Filter`}
      toggle={close}
    >
      <Text
        required
        mb
        label="Name"
        value={data.name}
        onChange={(e) => setData({ ...data, name: e.target.value })}
        error={errors.name}
      />
      <TextArea
        mb
        rows={2}
        label="Description"
        value={data.description}
        placeholder="Enter Description"
        onChange={(e) => setData({ ...data, description: e.target.value })}
      />
      <Switch
        mb
        label="Public"
        checked={data.public}
        onChange={() => setData({ ...data, public: !data.public })}
      />
      {errors.main && <Callout mt mb type="error" text={errors.main} />}
      <Modal.Footer>
        <Button mr type="secondary" text="Cancel" onClick={close} />
        <Button text="Save and Apply" onClick={submit} />
      </Modal.Footer>
    </Modal>
  );
};

SaveFilterModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  incomingData: PropTypes.object,
  close: PropTypes.func,
  app: PropTypes.string,
  model: PropTypes.string,
  query: PropTypes.string,
  filters: PropTypes.array,
  addFilter: PropTypes.func,
  updateFilter: PropTypes.func,
};

export default SaveFilterModal;
