import React, { createContext } from "react";

import PropTypes from "prop-types";
const DragDropContext = createContext();

const DragDropProvider = ({ children, values }) => (
  <DragDropContext.Provider value={values}>{children}</DragDropContext.Provider>
);

DragDropProvider.propTypes = {
  children: PropTypes.any,
  values: PropTypes.object,
};

export { DragDropProvider, DragDropContext };
