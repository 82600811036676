import React, { useEffect, useState } from "react";

import {
  SearchText,
  gridSpacing,
  Tabs,
} from "@lucernahealth/lucerna-health-ui";
import { connect } from "react-redux";
import { styled } from "styled-components";

import type { SaveFilterModalProps } from ".";
import ViewFilterItem from "./ViewFilterItem";

const FiltersContainer = styled.div`
  max-height: 600px;
  overflow-y: auto;
`;

const ViewFilters = (props: SaveFilterModalProps & { username: string }) => {
  const { savedFilters, openSavedFilter, removeFilter, username } = props;
  const [currentTab, setCurrentTab] = useState<"myViews" | "allViews">(
    "allViews",
  );
  const [filterValue, setFilterValue] = useState("");

  const userFilters = savedFilters
    .filter(
      (o) =>
        o.created_by_username === username &&
        o.name.toLowerCase().includes(filterValue.toLowerCase()),
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  const allFilters = savedFilters
    .filter(
      (o) =>
        (o.public || o.created_by_username === username) &&
        o.name.toLowerCase().includes(filterValue.toLowerCase()),
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    if (userFilters.length > 0) {
      setCurrentTab("myViews");
    }
  }, []);

  const getTabTitle = (tabKey: "myViews" | "allViews") => {
    let tabTitle = "";
    if (tabKey === "myViews") {
      tabTitle = "My Saved Views";

      if (userFilters.length > 0) {
        tabTitle = `${tabTitle} (${userFilters.length})`;
      }
    } else if (tabKey === "allViews") {
      tabTitle = "All Views";

      if (allFilters.length > 0) {
        tabTitle = `${tabTitle} (${allFilters.length})`;
      }
    }

    return tabTitle;
  };

  return (
    <Tabs
      currentTab={currentTab}
      onSelect={setCurrentTab}
      style={{ margin: 0, paddingBottom: gridSpacing[5] }}
      tabContentStyle={{ padding: 0 }}
    >
      <Tabs.Content tabKey="myViews" title={getTabTitle("myViews")}>
        <SearchText
          style={{ width: 325 }}
          onChange={(e) => setFilterValue(e.target.value)}
          value={filterValue}
        />
        <FiltersContainer>
          {userFilters.length === 0 ? (
            <p style={{ paddingTop: gridSpacing[5] }}>No saved views found</p>
          ) : (
            userFilters.map((filter) => (
              <ViewFilterItem
                key={filter.uuid}
                {...filter}
                openSavedFilter={() => openSavedFilter(filter)}
                removeFilter={() => removeFilter(filter)}
              />
            ))
          )}
        </FiltersContainer>
      </Tabs.Content>
      <Tabs.Content tabKey="allViews" title={getTabTitle("allViews")}>
        <SearchText
          style={{ width: 325 }}
          onChange={(e) => setFilterValue(e.target.value)}
          value={filterValue}
        />
        <FiltersContainer>
          {allFilters.length === 0 ? (
            <p style={{ paddingTop: gridSpacing[5] }}>No saved views found</p>
          ) : (
            allFilters.map((filter) => (
              <ViewFilterItem
                key={filter.uuid}
                {...filter}
                openSavedFilter={() => openSavedFilter(filter)}
                removeFilter={() => removeFilter(filter)}
              />
            ))
          )}
        </FiltersContainer>
      </Tabs.Content>
    </Tabs>
  );
};

const mapStateToProps = (state: { main: { user: { username: string } } }) => {
  return {
    username: state.main.user.username,
  };
};

export default connect(mapStateToProps)(ViewFilters);
