import React, { useState } from "react";

import {
  Button,
  SlideOutPanel,
  StatusPill,
  gridSpacing,
  Divider,
  TableLink,
  DropDownV2,
} from "@lucernahealth/lucerna-health-ui";
import moment from "moment";
import { styled } from "styled-components";

import { colorTheme } from "@utils";

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  padding: ${gridSpacing[4]}px ${gridSpacing[5]}px ${gridSpacing[4]}px
    ${gridSpacing[6]}px;
  white-space: nowrap;
  height: 40px;
  z-index: 106;
`;

const Container = styled.div`
  overflow-y: auto;
  height: calc(100vh - 80px);
`;

const VersionContainer = styled.div<{ $selectedVersion: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;

  padding: ${gridSpacing[5]}px ${gridSpacing[6]}px ${gridSpacing[5]}px
    ${gridSpacing[9]}px;
  background-color: ${({ $selectedVersion }) =>
    $selectedVersion && colorTheme("primaryL4")};

  &:hover {
    background-color: ${colorTheme("neutralL6")};
  }
`;

const TimeLine = styled.div<{ $isFirst: boolean }>`
  left: 39px;
  top: ${({ $isFirst }) => ($isFirst ? "35px" : "0")};
  position: absolute;
  width: 2px;
  background-color: ${colorTheme("neutralL5")};
  height: 100%;
`;

const VersionComments = styled.div`
  margin-bottom: ${gridSpacing[2]}px;
`;

const VersionFirstRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  font-weight: 500;
  margin-bottom: ${gridSpacing[2]}px;
  color: ${colorTheme("primaryD2")};
`;

const VersionModifiedName = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${gridSpacing[2]}px;
  font-size: 14px;
`;

const VersionDate = styled.div`
  font-size: 14px;
  color: ${colorTheme("neutralL2")};
`;

const NameBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: ${colorTheme("neutralL4")};
  font-size: 14px;
  margin-right: ${gridSpacing[2]}px;
`;

const VersionHeader = styled.div`
  &:hover {
    color: ${colorTheme("primaryD1")};
  }
`;

const TimelineIcon = styled.div`
  height: 12px;
  width: 12px;
  border: 1px solid ${colorTheme("neutralL2")};
  background-color: ${colorTheme("white")};
  margin-right: 17px;
  margin-left: -31px;
  border-radius: 50%;
  position: absolute;
`;

const DropdownToggle = styled.i`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  font-size: 18px;
  padding: 0 ${gridSpacing[3]}px;
  &:hover {
    color: ${colorTheme("neutralL1")};
  }
`;

export interface VersionHistoryObjectProps {
  modified: string;
  modified_by_name?: string;
  version: number;
  version_comment?: string | null;
  version_name?: string | null;
  version_parent?: number | null;
  version_status: "archived" | "draft" | "latest";
  published_date?: string | null;
}

interface VersionHistoryProps {
  isOpened: boolean;
  setIsOpened: (isOpened: boolean) => void;
  versionHistory: VersionHistoryObjectProps[];
  switchVersion: (version: number) => void;
  deleteVersion?: (version: number) => void;
  revertVersion?: (version: number) => void;
  setCompareIsOpened?: (compareIsOpened: boolean) => void;
  currentVersion: number;
  testId?: string;
}

const VersionHistory = ({
  isOpened,
  setIsOpened,
  versionHistory,
  switchVersion,
  deleteVersion,
  revertVersion,
  currentVersion,
  setCompareIsOpened,
  testId = "version-history",
}: VersionHistoryProps) => {
  const [hoveredVersion, setHoveredVersion] = useState<number | null>(null);

  const getAbbreviatedName = (name: string) => {
    const names = name.split(" ");
    if (names.length === 1) {
      return names[0].charAt(0);
    }
    return `${names[0].charAt(0)}${names[names.length - 1].charAt(0)}`;
  };

  return (
    <SlideOutPanel
      noContent
      includeFloatingCloseButton
      size={1}
      isOpened={isOpened}
      setIsOpened={setIsOpened}
    >
      <Header>
        <h3>Version History</h3>
        {setCompareIsOpened && (
          <Button
            icon="fa-solid fa-code-compare"
            text="Compare"
            type="secondary"
            onClick={() => {
              setCompareIsOpened(true);
              setIsOpened(false);
            }}
            testId={`${testId}-compare-button`}
          />
        )}
      </Header>
      <Divider style={{ margin: 0 }} />
      <Container>
        {versionHistory
          .sort((a, b) => b.version - a.version)
          .map((version, i) => {
            const versionString =
              version.version_name || `v.${version.version}`;
            const wasReverted =
              version.version_parent !== version.version - 1 &&
              version.version_parent;
            const versionDate = version.published_date || version.modified;
            return (
              <VersionContainer
                $selectedVersion={currentVersion === version.version}
                key={version.version}
                onMouseEnter={() => setHoveredVersion(version.version)}
                data-testid={`${testId}-version-${version.version}`}
              >
                <TimeLine $isFirst={i === 0} />
                <VersionFirstRow>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TimelineIcon />
                    <TableLink
                      noUnderline
                      onClick={() => {
                        switchVersion(version.version);
                        setIsOpened(false);
                      }}
                    >
                      <VersionHeader>
                        {`${versionString} ${
                          wasReverted
                            ? `(Restored from v.${version.version_parent})`
                            : ""
                        }`}
                      </VersionHeader>
                    </TableLink>

                    {version.version_status !== "archived" && (
                      <StatusPill
                        small
                        cleanText
                        style={{ marginLeft: gridSpacing[1] }}
                        text={version.version_status}
                        type={
                          version.version_status === "draft"
                            ? "primary"
                            : "success"
                        }
                        testId={`${testId}-status-${version.version}`}
                      />
                    )}
                  </div>
                  {((deleteVersion && version.version_status === "archived") ||
                    (revertVersion && version.version_status !== "draft")) &&
                    hoveredVersion === version.version && (
                      <DropDownV2 contentAlign="end" alignOffset={12}>
                        <DropDownV2.Toggle>
                          <DropdownToggle
                            data-testid={`${testId}-dropdown-${version.version}`}
                            className="fa-solid fa-ellipsis-vertical"
                          />
                        </DropDownV2.Toggle>
                        <DropDownV2.Menu>
                          {deleteVersion &&
                            version.version_status === "archived" && (
                              <DropDownV2.Item
                                testId={`${testId}-dropdown-delete-${version.version}`}
                                value="delete"
                                onClick={() => deleteVersion(version.version)}
                              >
                                Delete
                              </DropDownV2.Item>
                            )}
                          {revertVersion && (
                            <DropDownV2.Item
                              testId={`${testId}-dropdown-revert-${version.version}`}
                              value="restore"
                              onClick={() => revertVersion(version.version)}
                            >
                              Restore
                            </DropDownV2.Item>
                          )}
                        </DropDownV2.Menu>
                      </DropDownV2>
                    )}
                </VersionFirstRow>
                {version.version_comment && (
                  <VersionComments
                    data-testid={`${testId}-comments-${version.version}`}
                  >
                    {version.version_comment}
                  </VersionComments>
                )}
                {version.modified_by_name && (
                  <VersionModifiedName>
                    <NameBadge
                      data-testid={`${testId}-initials-badge-${version.version}`}
                    >
                      {getAbbreviatedName(version.modified_by_name)}
                    </NameBadge>
                    {version.modified_by_name}
                  </VersionModifiedName>
                )}
                {versionDate && (
                  <VersionDate
                    data-testid={`${testId}-modified-date-${version.version}`}
                  >
                    {moment(versionDate).format("MM/DD/YYYY h:mm a")}
                  </VersionDate>
                )}
              </VersionContainer>
            );
          })}
      </Container>
    </SlideOutPanel>
  );
};

export default VersionHistory;
