import React, { memo } from "react";

import { gridSpacing } from "@lucernahealth/lucerna-health-ui";
import equal from "fast-deep-equal/es6";
import * as Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more.js";
import bulletChart from "highcharts/modules/bullet";
import HighchartsReact from "highcharts-react-official";

import { colorTheme } from "@utils";

import Error from "./Common/Error";
import Header from "./Common/Header";
import Loading from "./Common/Loading";
import { Wrapper } from "./Common/styles";

highchartsMore(Highcharts);
bulletChart(Highcharts);

type BulletChartDataProps = {
  name: string;
  targetValue: number;
  maxValue: number;
  value: number;
};

type BulletChartProps = {
  title: string;
  toolTip?: string;
  data: BulletChartDataProps[];
  error?: string | null;
  loading?: boolean;
};

const BulletChart = memo(
  ({ title, toolTip, data, error, loading }: BulletChartProps) => {
    const baseOptions: Highcharts.Options = {
      chart: {
        inverted: true,
        type: "bullet",
        height: 75,
        marginLeft: 135,
      },
      yAxis: {
        gridLineWidth: 0,
      },
      plotOptions: {
        bullet: {
          pointPadding: 0.25,
          borderWidth: 0,
          color: "#000",
          targetOptions: {
            width: "200%",
          },
        },
      },
      legend: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
    };

    const renderChart = () => {
      const largestCapacity = data.reduce(
        (max, obj) => Math.max(max, obj.targetValue, obj.maxValue),
        0,
      );

      return data.map((obj, key) => (
        <HighchartsReact
          key={key}
          containerProps={{
            style: { width: "100%", marginTop: gridSpacing[2] },
          }}
          highcharts={Highcharts}
          options={Highcharts.merge(baseOptions, {
            xAxis: {
              categories: [obj.name],
            },
            yAxis: {
              title: null,
              max: largestCapacity,
              plotBands: [
                {
                  from: 0,
                  to: obj.targetValue,
                  color: colorTheme("successL5"),
                },
                {
                  from: obj.targetValue,
                  to: obj.maxValue,
                  color: colorTheme("warningL5"),
                },
                {
                  from: obj.maxValue,
                  to: 9e9,
                  color: colorTheme("neutralL5"),
                },
              ],
            },
            series: [
              {
                data: [
                  {
                    color:
                      (obj.value / obj.targetValue) * 100 > 100
                        ? colorTheme("dangerL1")
                        : (obj.value / obj.targetValue) * 100 > 80
                          ? colorTheme("warningL2")
                          : colorTheme("successL2"),
                    y: obj.value,
                    target: obj.targetValue,
                  },
                ],
              },
            ],
          })}
        />
      ));
    };

    if (error) {
      return <Error errorMessage={error} />;
    }

    if (loading) {
      return <Loading />;
    }

    return (
      <Wrapper>
        <Header title={title} toolTip={toolTip} />
        <div
          style={{
            maxHeight: 850,
            overflowY: "scroll",
            paddingLeft: gridSpacing[3],
            paddingRight: gridSpacing[3],
          }}
        >
          {renderChart()}
        </div>
      </Wrapper>
    );
  },
  (oldProps, newProps) => equal(oldProps, newProps),
);

export default BulletChart;
