import Swal from "sweetalert2";

import { colorTheme } from "@utils";

interface SweetAlertProps {
  title?: string;
  text?: string;
  showCancelButton?: boolean;
  isWarning?: boolean;
  success?: () => void;
  action?: () => void;
}

const SweetAlert = ({
  title,
  text,
  showCancelButton,
  isWarning,
  success,
  action,
}: SweetAlertProps) => {
  const dangerColor = colorTheme("danger");
  const mainColor = colorTheme("primary");

  return Swal.fire({
    showClass: {
      popup: "animate__animated animate__fadeInDown",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOutUp",
    },
    confirmButtonColor: isWarning ? dangerColor : mainColor,
    reverseButtons: true,
    showCancelButton,
    title,
    text,
  }).then((result) => {
    if (action) {
      action();
    }

    if (result.value && success) {
      success();
    }

    return result;
  });
};

export default SweetAlert;
