import React, { createContext, useContext } from "react";

import PropTypes from "prop-types";

const CubeJsApiContext = createContext(null);

export const CubeJsApiProvider = ({ children, cubejsApi, setCubejsApi }) => (
  <CubeJsApiContext.Provider value={{ cubejsApi, setCubejsApi }}>
    {children}
  </CubeJsApiContext.Provider>
);

export const useCubeJsApi = () => {
  const cubejsApi = useContext(CubeJsApiContext);
  if (!cubejsApi) {
    throw new Error("useCubeJsApi must be used within a CubeJsApiProvider");
  }
  return cubejsApi;
};

CubeJsApiProvider.propTypes = {
  cubejsApi: PropTypes.object,
  setCubejsApi: PropTypes.func,
  children: PropTypes.node,
};
