export default [
  {
    isParent: true,
    label: "Facility",
    id: "facility",
  },
  {
    cubeKey:
      "schedule_availability_facility_summary_appt_type.schedule_facility_name",
    label: "Name",
    parentId: "facility",
  },
  {
    cubeKey: "facility.state",
    label: "State",
    parentId: "facility",
  },
  {
    cubeKey: "facility.zip",
    label: "Zip",
    parentId: "facility",
  },
  {
    cubeKey: "facility.region",
    label: "Region",
    parentId: "facility",
  },
  {
    cubeKey:
      "schedule_availability_provider_facility_summary_appt_type.schedule_appointment_type",
    label: "Appointment Type",
    parentId: "facility",
  },
];
