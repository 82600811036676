import { DataViz } from "@commonComponents";
import { type TQueryOrderArray } from "@cubejs-client/core";

import type { DashboardAdvancedFilterProps } from "../../../Portal/Portal";
import type { AdvFilterProps } from "~/src/utils/tsUtils";

export const scheduleAvailabilityKPIQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: [],
    measures: [
      "schedule_availability_provider_summary_appt_type.available_week_1_utilization",
      "schedule_availability_provider_summary_appt_type.available_week_2_utilization",
      "schedule_availability_provider_summary_appt_type.available_week_3_utilization",
      "schedule_availability_provider_summary_appt_type.available_week_4_utilization",
      "schedule_availability_provider_summary_appt_type.average_next_available",
      "schedule_availability_provider_summary_appt_type.average_second_next_available",
      "schedule_availability_provider_summary_appt_type.average_third_next_available",
      "schedule_availability_provider_summary_appt_type.average_fourth_next_available",
      "schedule_availability_provider_summary_appt_type.available_week_1_slot_count_avg",
      "schedule_availability_provider_summary_appt_type.available_week_2_slot_count_avg",
      "schedule_availability_provider_summary_appt_type.available_week_3_slot_count_avg",
      "schedule_availability_provider_summary_appt_type.available_week_4_slot_count_avg",
    ],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;

export const scheduleAvailabilitySingleProviderKPIQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: [],
    measures: [
      "schedule_availability_provider_summary_appt_type.min_next_available",
      "schedule_availability_provider_summary_appt_type.min_second_next_available",
      "schedule_availability_provider_summary_appt_type.min_third_next_available",
      "schedule_availability_provider_summary_appt_type.min_fourth_next_available",
    ],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;

export const scheduleAvailabilityProviderTableQuery = (
  filters?: AdvFilterProps,
  sorting?: TQueryOrderArray,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: [
      "schedule_availability_provider_summary_appt_type.provider_directory_provider_id",
      "schedule_availability_provider_summary_appt_type.schedule_provider_full_name",
    ],
    measures: [
      "schedule_availability_provider_summary_appt_type.available_week_1_utilization",
      "schedule_availability_provider_summary_appt_type.available_week_2_utilization",
      "schedule_availability_provider_summary_appt_type.available_week_3_utilization",
      "schedule_availability_provider_summary_appt_type.available_week_4_utilization",
      "schedule_availability_provider_summary_appt_type.min_next_available",
      "schedule_availability_provider_summary_appt_type.min_second_next_available",
      "schedule_availability_provider_summary_appt_type.min_third_next_available",
      "schedule_availability_provider_summary_appt_type.min_fourth_next_available",
      "schedule_availability_provider_facility_summary_appt_type.facility_count_distinct",
    ],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
    order:
      !sorting || sorting.length === 0
        ? ([
            [
              "schedule_availability_provider_summary_appt_type.schedule_provider_full_name",
              "asc",
            ],
          ] as TQueryOrderArray)
        : sorting,
  }) as const;

export const scheduleAvailabilityProviderFacilityTableQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: [
      "schedule_availability_provider_facility_summary_appt_type.provider_directory_provider_id",
      "schedule_availability_provider_facility_summary_appt_type.provider_directory_facility_id",
      "schedule_availability_provider_facility_summary_appt_type.schedule_facility_name",
    ],
    measures: [
      "schedule_availability_provider_facility_summary_appt_type.available_week_1_utilization",
      "schedule_availability_provider_facility_summary_appt_type.available_week_2_utilization",
      "schedule_availability_provider_facility_summary_appt_type.available_week_3_utilization",
      "schedule_availability_provider_facility_summary_appt_type.available_week_4_utilization",
      "schedule_availability_provider_facility_summary_appt_type.min_next_available",
      "schedule_availability_provider_facility_summary_appt_type.min_second_next_available",
      "schedule_availability_provider_facility_summary_appt_type.min_third_next_available",
      "schedule_availability_provider_facility_summary_appt_type.min_fourth_next_available",
    ],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;

export const scheduleAvailabilityProviderByApptTypeTableQuery = (
  filters?: AdvFilterProps,
  sorting?: TQueryOrderArray,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: [
      "schedule_availability_provider_summary_appt_type.provider_directory_provider_id",
      "schedule_availability_provider_summary_appt_type.schedule_provider_full_name",
    ],
    measures: [
      "schedule_availability_provider_summary_appt_type.available_week_1_utilization",
      "schedule_availability_provider_summary_appt_type.available_week_2_utilization",
      "schedule_availability_provider_summary_appt_type.available_week_3_utilization",
      "schedule_availability_provider_summary_appt_type.available_week_4_utilization",
      "schedule_availability_provider_summary_appt_type.min_next_available",
      "schedule_availability_provider_summary_appt_type.min_second_next_available",
      "schedule_availability_provider_summary_appt_type.min_third_next_available",
      "schedule_availability_provider_summary_appt_type.min_fourth_next_available",
      "schedule_availability_provider_facility_summary_appt_type.appointment_type_count_distinct",
    ],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
    order:
      !sorting || sorting.length === 0
        ? ([
            [
              "schedule_availability_provider_summary_appt_type.schedule_provider_full_name",
              "asc",
            ],
          ] as TQueryOrderArray)
        : sorting,
  }) as const;

export const scheduleAvailabilityProviderFacilityByApptTypeTableQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: [
      "schedule_availability_provider_facility_summary_appt_type.provider_directory_provider_id",
      "schedule_availability_provider_facility_summary_appt_type.provider_directory_facility_id",
      "schedule_availability_provider_facility_summary_appt_type.schedule_facility_name",
      "schedule_availability_provider_facility_summary_appt_type.schedule_appointment_type",
    ],
    measures: [
      "schedule_availability_provider_facility_summary_appt_type.available_week_1_utilization",
      "schedule_availability_provider_facility_summary_appt_type.available_week_2_utilization",
      "schedule_availability_provider_facility_summary_appt_type.available_week_3_utilization",
      "schedule_availability_provider_facility_summary_appt_type.available_week_4_utilization",
      "schedule_availability_provider_facility_summary_appt_type.min_next_available",
      "schedule_availability_provider_facility_summary_appt_type.min_second_next_available",
      "schedule_availability_provider_facility_summary_appt_type.min_third_next_available",
      "schedule_availability_provider_facility_summary_appt_type.min_fourth_next_available",
    ],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;
