import React, { useContext } from "react";

import { DataViz } from "@commonComponents";
import { Table } from "@lucernahealth/lucerna-health-ui";

import { makeHumanReadable, numberWithCommas } from "@utils";

import {
  averageDashboardViewsQuery,
  averageDashboardDailyViewsQuery,
  totalDashboardViewsQuery,
  totalDashboardViewsLastThirtyQuery,
  topTenDashboardsQuery,
  viewPerDashboardsTableQuery,
} from "./Queries";
import { PortalContext } from "../../Portal/Portal";
import {
  roundToTwoDecimals,
  userFilterDimensions,
  groupFilterDimensions,
  viewActivityFilterDimensions,
} from "../Common/userAnalyticsCommon";
import Header from "~/src/CommonComponents/DataVisualization/Common/Header";
import { DASHBOARD_COLOR } from "~/src/CommonComponents/DataVisualization/Common/styles";
import { useCubeApiQuery } from "~/src/utils/tsUtils";

const DashboardAnalysis = () => {
  const { filters, filterOrder } = useContext(PortalContext);

  DataViz.filterUtils.useFilterOptionsNormalized({
    dimensions: [
      ...viewActivityFilterDimensions,
      ...userFilterDimensions,
      ...groupFilterDimensions,
      "insights_dashboard_views.page_name",
      "insights_analytic_domain.analytic_domain_name",
      "insights_subdomain.subdomain_name",
    ],
  });

  const {
    resultSet: totalDashboardViewsResult,
    error: totalDashboardViewsError,
    isLoading: totalDashboardViewsLoading,
  } = useCubeApiQuery(totalDashboardViewsQuery(filters, filterOrder));

  const {
    resultSet: totalDashboardViewsLastThirtyResult,
    error: totalDashboardViewsLastThirtyError,
    isLoading: totalDashboardViewsLastThirtyLoading,
  } = useCubeApiQuery(totalDashboardViewsLastThirtyQuery(filters, filterOrder));

  const {
    resultSet: averageDashboardViewsResult,
    error: averageDashboardViewsError,
    isLoading: averageDashboardViewsLoading,
  } = useCubeApiQuery(averageDashboardViewsQuery(filters, filterOrder));

  const {
    resultSet: averageDashboardDailyViewsResult,
    error: averageDashboardDailyViewsError,
    isLoading: averageDashboardDailyViewsLoading,
  } = useCubeApiQuery(averageDashboardDailyViewsQuery(filters, filterOrder));

  const {
    resultSet: topTenDashboardsResult,
    error: topTenDashboardsError,
    isLoading: topTenDashboardsLoading,
  } = useCubeApiQuery(topTenDashboardsQuery(filters, filterOrder));

  const {
    resultSet: viewPerDashboardsTableResult,
    isLoading: viewPerDashboardsTableLoading,
  } = useCubeApiQuery(viewPerDashboardsTableQuery(filters, filterOrder));

  const averageDashboardViewsData = roundToTwoDecimals(
    (
      (averageDashboardViewsResult?.tablePivot() ?? []) as {
        "insights_dashboard_views.total_pages": string;
      }[]
    )
      .map((entry) => parseInt(entry["insights_dashboard_views.total_pages"]))
      .reduce((acc, val) => acc + val, 0) / 30,
  );

  const averageDashboardDailyViewsData = roundToTwoDecimals(
    (
      (averageDashboardDailyViewsResult?.tablePivot() ?? []) as {
        "insights_dashboard_views.total_pages": string;
        "insights_dashboard_views.total_insights_views": string;
      }[]
    )
      .map(
        (entry) =>
          parseInt(entry["insights_dashboard_views.total_insights_views"]) /
          parseInt(entry["insights_dashboard_views.total_pages"]),
      )
      .reduce((acc, val) => acc + val, 0) / 30,
  );

  const totalDashboardViewsData =
    totalDashboardViewsResult?.rawData()[0]?.[
      "insights_dashboard_views.total_pages"
    ];
  const totalDashboardViewsLastThirtyData =
    totalDashboardViewsLastThirtyResult?.rawData()[0];

  const totalDashBoardViewsTrendPercentage = roundToTwoDecimals(
    (parseFloat(totalDashboardViewsData ?? "") /
      parseFloat(
        totalDashboardViewsLastThirtyData?.[
          "insights_dashboard_views.total_pages"
        ] ?? "1",
      )) *
      100,
  );

  const getDataByTopTenDashboards = () => {
    const _data =
      (topTenDashboardsResult?.tablePivot() as {
        "insights_dashboard_views.page_name": string;
        "insights_dashboard_views.total_insights_views": string;
        "insights_analytic_domain.analytic_domain_name": string;
        "insights_subdomain.subdomain_name": string;
      }[]) ?? [];
    if (!_data) {
      return [];
    }
    const resData = _data.map((obj) => {
      const res = {} as {
        name: string;
        y: number;
        id: string;
        color: string;
      };
      res.name = `${obj["insights_dashboard_views.page_name"]}`;
      res.y = parseInt(
        obj["insights_dashboard_views.total_insights_views"] ?? "0",
      );
      res.id = `${obj["insights_analytic_domain.analytic_domain_name"]} / ${obj["insights_subdomain.subdomain_name"]} / ${obj["insights_dashboard_views.page_name"]}`;
      res.color = DASHBOARD_COLOR;
      return res;
    });
    return [{ name: "Total Views", data: resData, color: DASHBOARD_COLOR }];
  };

  const getDataForDashboardTable = () => {
    const rawData = viewPerDashboardsTableResult?.tablePivot() ?? [];
    // Remap the keys of the data
    const data = rawData.map((obj) => {
      const res = {} as {
        pageName: string;
        totalViews: number;
        dashboardHierarchy: string;
      };
      res["pageName"] = obj["insights_dashboard_views.page_name"]! as string;
      res["totalViews"] = parseInt(
        obj["insights_dashboard_views.total_insights_views"] as string,
      );
      if (
        !obj["insights_analytic_domain.analytic_domain_name"] &&
        !obj["insights_subdomain.subdomain_name"]
      ) {
        res["dashboardHierarchy"] = "N/A";
      } else {
        res["dashboardHierarchy"] =
          makeHumanReadable(
            obj["insights_analytic_domain.analytic_domain_name"] as string,
          ) +
          " / " +
          makeHumanReadable(obj["insights_subdomain.subdomain_name"] as string);
      }
      return res;
    });

    const columns = [
      {
        Header: "Dashboard Name",
        accessor: "pageName",
        Cell: (props: { value: string }) => <p>{props.value}</p>,
      },
      {
        Header: "Total Views",
        accessor: "totalViews",
        Cell: (props: { value: string }) => (
          <p>{numberWithCommas(props.value)}</p>
        ),
      },
      {
        Header: "Dashboard Hierarchy",
        accessor: "dashboardHierarchy",
        Cell: (props: { value: string }) => <p>{props.value}</p>,
      },
    ];

    return { data, columns };
  };

  return (
    <>
      <DataViz.Row>
        <DataViz.Row>
          <DataViz.NumberChart
            loading={
              totalDashboardViewsLoading || totalDashboardViewsLastThirtyLoading
            }
            error={
              (totalDashboardViewsError ||
                totalDashboardViewsLastThirtyError) &&
              "Something went wrong"
            }
            type="metric"
            toolTip="Total number of dashboards viewed in the last 30 days"
            unit="dashboards"
            title="Total Dashboards Viewed (30 Days)"
            numerator={parseFloat(totalDashboardViewsData ?? "")}
            trendDirection={
              totalDashBoardViewsTrendPercentage > 0 ? "up" : "down"
            }
            trendPercentage={totalDashBoardViewsTrendPercentage}
          />
          <DataViz.NumberChart
            loading={averageDashboardViewsLoading}
            error={averageDashboardViewsError && "Something went wrong"}
            type="metric"
            toolTip="Average number of dashboards viewed per day for the last 30 days"
            unit="dashboards"
            title="Average Number of Dashboards Viewed (30 Days)"
            numerator={averageDashboardViewsData}
          />
          <DataViz.NumberChart
            loading={averageDashboardDailyViewsLoading}
            error={averageDashboardDailyViewsError && "Something went wrong"}
            type="metric"
            toolTip="Average number of views per dashboard per day for the last 30 days"
            unit="views"
            title="Average Views per Dashboard (30 Days)"
            numerator={averageDashboardDailyViewsData}
          />
        </DataViz.Row>
        <DataViz.Row>
          <DataViz.BarChart
            title="Frequently Visited Dashboards"
            subtitle="Top 10 most frequently visited dashboards in the last 12 months"
            yAxisLabel="View Counts"
            data={getDataByTopTenDashboards()}
            loading={topTenDashboardsLoading}
            error={topTenDashboardsError && "Something went wrong"}
            toolTip="Top 10 most frequently visited dashboards in the last 12 months"
          />
        </DataViz.Row>
      </DataViz.Row>
      <Header
        title="Views Per Dashboard"
        toolTip="Number of views per dashboard in the last 12 months "
        style={{ marginBottom: 0 }}
      />
      <Table
        isLoading={viewPerDashboardsTableLoading}
        {...getDataForDashboardTable()}
        pageSize={10}
        defaultSorted={[
          {
            id: "totalViews",
            desc: true,
          },
        ]}
      />
    </>
  );
};

export default DashboardAnalysis;
