import { DataViz } from "@commonComponents";

import type { DashboardAdvancedFilterProps } from "../../Portal/Portal";
import type { AdvFilterProps } from "~/src/utils/tsUtils";

export const assignedProviderPct7Days = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: [
      "assigned_consumer_appointments.assigned_provider_appointment_ratio_7_days",
    ],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const assignedProviderPct30Days = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: [
      "assigned_consumer_appointments.assigned_provider_appointment_ratio_30_days",
    ],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const assignedProviderPct90Days = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: [
      "assigned_consumer_appointments.assigned_provider_appointment_ratio_90_days",
    ],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const assignedCareTeamPct7Days = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: [
      "assigned_consumer_appointments.assigned_care_team_appointment_ratio_7_days",
    ],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const assignedCareTeamPct30Days = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: [
      "assigned_consumer_appointments.assigned_care_team_appointment_ratio_30_days",
    ],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const assignedCareTeamPct90Days = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: [
      "assigned_consumer_appointments.assigned_care_team_appointment_ratio_90_days",
    ],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const panelStatsByAppointmentCategoryDrilldownQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: [
      "assigned_consumer_appointments.attribution_panel_name",
      "assigned_consumer_appointments.appointment_category",
      "assigned_consumer_appointments.attribution_panel_id",
    ],
    measures: [
      "assigned_consumer_appointments.assigned_provider_appointment_ratio_90_days",
    ],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const panelStatsByAppointmentCategorySummaryQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: ["assigned_consumer_appointments.attribution_panel_name"],
    measures: [
      "assigned_consumer_appointments.assigned_provider_appointment_ratio_90_days",
    ],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const appointmentAnalysisFilterDimensions = [
  "assigned_consumer_appointments.attribution_panel_name",
  "assigned_consumer_appointments.appointment_category",
  "assigned_consumer_appointments.appointment_type",
  "assigned_consumer_appointments.appointment_provider_npi",
  "assigned_consumer_appointments.attribution_facility_name",
  "assigned_consumer_appointments.attribution_facility_state",
  "assigned_consumer_appointments.attribution_facility_zip",
  "consumer.enrich_state",
  "consumer.enrich_city",
  "consumer.enrich_zip",
  "consumer.gender",
  "roster_active.plan_state",
  "roster_active.payer_name",
  "roster_active.market_segment",
  "roster_active.market_sub_segment",
];
