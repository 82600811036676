import { DataViz } from "@commonComponents";
import moment from "moment";

import type { DashboardAdvancedFilterProps } from "../../Portal/Portal";
import type { AdvFilterProps } from "~/src/utils/tsUtils";

export const averageVisitCountQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["appointment_completed.avg_appointment_count_90_days"],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const averagePatientCountQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["appointment_completed.avg_patient_count_90_days"],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const averageVisitPerProviderQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: [
      "appointment_completed.total_appt_count_last_90_days",
      "appointment_completed.total_provider_count",
    ],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const visitCountByTypeQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: ["appointment_completed.appointment_type"],
    measures: ["appointment_completed.total_appt_count"],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const visitCountByTypeQuarterQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["appointment_completed.appointment_count"],
    timeDimensions: [
      {
        dimension: "appointment_completed.appointment_date",
        granularity: "quarter",
        dateRange: [
          moment().subtract(2, "years").startOf("year").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
      },
    ],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
    dimensions: ["appointment_completed.appointment_type"],
  }) as const;

export const visitCountByCategoryQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: ["appointment_completed.appointment_category"],
    measures: ["appointment_completed.total_appt_count"],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const visitCountByCategoryQuarterQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["appointment_completed.appointment_count"],
    timeDimensions: [
      {
        dimension: "appointment_completed.appointment_date",
        granularity: "quarter",
        dateRange: [
          moment().subtract(2, "years").startOf("year").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
      },
    ],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
    dimensions: ["appointment_completed.appointment_category"],
  }) as const;

export const patientCountByVisitCategoryQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: ["appointment_completed.appointment_category"],
    measures: ["appointment_completed.total_person_count"],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const patientCountByQuarterQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: ["appointment_completed.appointment_category"],
    measures: ["appointment_completed.total_person_count"],
    timeDimensions: [
      {
        dimension: "appointment_completed.appointment_date",
        granularity: "quarter",
        dateRange: [
          moment().subtract(2, "years").startOf("year").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
      },
    ],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
    order: {
      "appointment_completed.appointment_count": "desc",
    },
  }) as const;

export const visitCountTrendQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: ["appointment_completed.appointment_category"],
    timeDimensions: [
      {
        dimension: "appointment_completed.appointment_date",
        granularity: "month",
        dateRange: "Last 24 months",
      },
    ],
    order: [["appointment_completed.appointment_date", "asc"]],
    measures: ["appointment_completed.total_appt_count"],
    filters: [
      ...(filters
        ? DataViz.filterUtils.convertFilters(filters, filterOrder)
        : []),
    ],
  }) as const;

export const summaryFilterDimensions: string[] = [
  "roster_active.plan_state",
  "roster_active.payer_name",
  "roster_active.market_segment",
  "roster_active.market_sub_segment",
  "roster_active.active_flag",
  "assigned_consumer_detail.panel_name",
  "assigned_consumer_detail.provider_name_npi",
  "assigned_consumer_detail.provider_practice_type_name",
  "assigned_consumer_detail.facility_name",
  "assigned_consumer_detail.facility_state",
  "assigned_consumer_detail.facility_zip",
  "appointment_completed.appointment_category",
  "appointment_completed.appointment_type",
  "assigned_consumer_detail.state",
  "assigned_consumer_detail.city",
  "assigned_consumer_detail.zip",
  "assigned_consumer_detail.gender",
];
