/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import React from "react";

import { ToastContainer } from "@commonComponents";
import loadable from "@loadable/component";
import * as Sentry from "@sentry/react";
import axios from "axios";
import Highcharts from "highcharts";
import { createBrowserHistory } from "history";
import queryString from "query-string";
import { createRoot } from "react-dom/client";
import "sweetalert2/src/sweetalert2.scss";
import "./index.css";
import "react-circular-progressbar/dist/styles.css";
import "reactflow/dist/style.css";
import { Provider } from "react-redux";

const App = loadable(() => import("./App.tsx"));

import store from "./store";
const history = createBrowserHistory();

if (window.location.hostname !== "localhost") {
  Sentry.init({
    dsn: "https://d638b6e88bf044eebc803b4a9851d1fa@o524965.ingest.sentry.io/5454563",
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
      }),
      Sentry.captureConsoleIntegration({
        levels: ["error"],
      }),
      Sentry.sessionTimingIntegration(),
    ],
    tracesSampleRate: 1.0,
    normalizeDepth: 15,
  });
}

// Import font awesome kit - we do have local version of FA but this will always ensure we have the latest
(function () {
  var wa = document.createElement("script");
  wa.type = "text/javascript";
  wa.src = "https://kit.fontawesome.com/0870df4268.js";
  wa.crossOrigin = "anonymous";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(wa, s);
})();

// We need to check if we are working locally or on a server, if host contains localhost we are working locally
if (window.location.hostname === "localhost") {
  axios.defaults.baseURL = `${window.location.origin}/api/v1/`;
} else {
  axios.defaults.baseURL = `https://internal-api.${window.location.host}/api/v1/`;
}
axios.defaults.paramsSerializer = (params) =>
  queryString.stringify(params, { arrayFormat: "none" });

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
  chart: {
    style: {
      fontFamily: '"Manrope", sans-serif',
    },
  },
});

function AppRoot() {
  return (
    <Provider store={store}>
      <ToastContainer />
      <App />
    </Provider>
  );
}

const container = document.getElementById("app-root");
const root = createRoot(container);

root.render(<AppRoot />);
