import React, { useContext } from "react";

import { DataViz } from "@commonComponents";

import {
  age5YearSpanQuery,
  consumerCountByStateQuery,
  ethnicityBreakdownQuery,
  genderBreakdownQuery,
  languageBreakdownQuery,
  raceBreakdownQuery,
  top10CitiesQuery,
  consumerDemographicsDimensions,
  top10CitiesByStateQuery,
} from "./Queries";
import { PortalContext } from "../../Portal/Portal";
import { getStackedBarChartData } from "../Common/attributionCommon";
import { convertPieChartData } from "../Common/common";
import { useCubeApiQuery } from "~/src/utils/tsUtils";

const ConsumerDemographics = () => {
  const { filters, filterOrder } = useContext(PortalContext);

  DataViz.filterUtils.useFilterOptionsNormalized({
    dimensions: consumerDemographicsDimensions,
  });

  const {
    resultSet: genderBreakdownResult,
    error: genderBreakdownError,
    isLoading: genderBreakdownLoading,
  } = useCubeApiQuery(genderBreakdownQuery(filters, filterOrder));

  const {
    resultSet: languageBreakdownResult,
    error: languageBreakdownError,
    isLoading: languageBreakdownLoading,
  } = useCubeApiQuery(languageBreakdownQuery(filters, filterOrder));

  const {
    resultSet: raceBreakdownResult,
    error: raceBreakdownError,
    isLoading: raceBreakdownLoading,
  } = useCubeApiQuery(raceBreakdownQuery(filters, filterOrder));

  const {
    resultSet: ethnicityBreakdownResult,
    error: ethnicityBreakdownError,
    isLoading: ethnicityBreakdownLoading,
  } = useCubeApiQuery(ethnicityBreakdownQuery(filters, filterOrder));

  const {
    resultSet: consumerCountByStateResult,
    error: consumerCountByStateError,
    isLoading: consumerCountByStateLoading,
  } = useCubeApiQuery(consumerCountByStateQuery(filters, filterOrder));

  const {
    resultSet: top10CitiesResult,
    error: top10CitiesError,
    isLoading: top10CitiesLoading,
  } = useCubeApiQuery(top10CitiesQuery(filters, filterOrder));

  const {
    resultSet: top10CitiesByStateResult,
    error: top10CitiesByStateError,
    isLoading: top10CitiesByStateLoading,
  } = useCubeApiQuery(top10CitiesByStateQuery(filters, filterOrder));

  const {
    resultSet: age5YearSpanResult,
    error: age5YearSpanError,
    isLoading: age5YearSpanLoading,
  } = useCubeApiQuery(age5YearSpanQuery(filters, filterOrder));

  const getGeographicDrilldownData = () => {
    const drilldownData: {
      name: string;
      id: string;
      data: { name: string; y: number }[];
    }[] = [];

    top10CitiesByStateResult?.rawData()?.forEach((item) => {
      const state = item["consumer.enrich_state"];
      const stateIndex = drilldownData.findIndex((obj) => obj.id === state);
      const data = {
        name: item["consumer.enrich_city"],
        y: parseInt(item["consumer.total_consumer_count"]),
      };

      if (stateIndex === -1) {
        drilldownData.push({
          name: state,
          id: state,
          data: [data],
        });
      } else if (drilldownData[stateIndex].data.length < 10) {
        drilldownData[stateIndex].data.push(data);
      }
    });

    return drilldownData;
  };

  return (
    <>
      <DataViz.Row>
        <DataViz.PieChart
          title="Gender Breakdown"
          toolTip="Breakdown of consumers by gender"
          data={convertPieChartData(
            "consumer.gender",
            "consumer.total_consumer_count",
            genderBreakdownResult?.rawData(),
          )}
          loading={genderBreakdownLoading}
          error={genderBreakdownError && "Something went wrong"}
        />

        <DataViz.PieChart
          title="Language Preference"
          toolTip="Breakdown of consumers by language preference"
          data={convertPieChartData(
            "consumer.language_preference",
            "consumer.total_consumer_count",
            languageBreakdownResult?.rawData(),
          )}
          loading={languageBreakdownLoading}
          error={languageBreakdownError && "Something went wrong"}
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.PieChart
          title="Geographic Breakdown"
          toolTip="Breakdown of consumers by state (based on their address)"
          data={convertPieChartData(
            "consumer.enrich_state",
            "consumer.total_consumer_count",
            consumerCountByStateResult?.rawData(),
          )}
          loading={consumerCountByStateLoading}
          error={consumerCountByStateError && "Something went wrong"}
        />

        <DataViz.PieChart
          title="Race Breakdown"
          toolTip="Breakdown of consumers by race"
          data={convertPieChartData(
            "consumer.race",
            "consumer.total_consumer_count",
            raceBreakdownResult?.rawData(),
          )}
          loading={raceBreakdownLoading}
          error={raceBreakdownError && "Something went wrong"}
        />
      </DataViz.Row>

      <DataViz.Row>
        <DataViz.PieChart
          title="Ethnicity Breakdown"
          toolTip="Breakdown of consumers by ethnicity"
          data={convertPieChartData(
            "consumer.ethnicity",
            "consumer.total_consumer_count",
            ethnicityBreakdownResult?.rawData(),
          )}
          loading={ethnicityBreakdownLoading}
          error={ethnicityBreakdownError && "Something went wrong"}
        />
      </DataViz.Row>

      <>
        <DataViz.Row>
          <DataViz.BarChart
            title="Consumer Count by State"
            toolTip="Count of consumers by state (based on their address)"
            subtitle="Click on the state to see the top 10 cities by consumer count"
            data={
              consumerCountByStateResult
                ?.rawData()
                .sort(
                  (a, b) =>
                    parseInt(b["consumer.total_consumer_count"]) -
                    parseInt(a["consumer.total_consumer_count"]),
                )
                ?.map((obj) => {
                  return {
                    name: obj["consumer.enrich_state"],
                    data: [
                      {
                        name: "State",
                        drilldown: obj["consumer.enrich_state"],
                        y: parseInt(obj["consumer.total_consumer_count"]),
                      },
                    ],
                  };
                })
                .slice(0, 10) ?? []
            }
            drillDown={getGeographicDrilldownData()}
            yAxisLabel="Consumer Count"
            loading={consumerCountByStateLoading || top10CitiesByStateLoading}
            error={
              (consumerCountByStateError || top10CitiesByStateError) &&
              "Something went wrong"
            }
          />
        </DataViz.Row>
        <DataViz.Row>
          <DataViz.BarChart
            title="Top 10 Cities"
            toolTip="Top 10 cities by consumer count (based on their address)"
            data={
              top10CitiesResult?.rawData()?.map((obj) => {
                return {
                  name: obj["consumer.enrich_city"],
                  data: [
                    {
                      name: "City",
                      y: parseInt(obj["consumer.total_consumer_count"]),
                    },
                  ],
                };
              }) ?? []
            }
            type="bar"
            yAxisLabel="Consumer Count"
            loading={top10CitiesLoading}
            error={top10CitiesError && "Something went wrong"}
          />
        </DataViz.Row>
      </>

      <DataViz.Row>
        <DataViz.BarChart
          type="stacked"
          toolTip="Count of consumers by age (in 5 years band) and their gender"
          title="Demographics"
          yAxisLabel="Consumer Count"
          xAxisLabel="Age"
          loading={age5YearSpanLoading}
          error={age5YearSpanError && "Something went wrong"}
          {...getStackedBarChartData(
            age5YearSpanResult
              ?.rawData()
              .filter(
                (obj) =>
                  obj["consumer.age_bands_5_years"] &&
                  obj["consumer.gender"] &&
                  obj["consumer.total_consumer_count"],
              )
              .sort((a, b) => {
                const aNum = parseInt(
                  a["consumer.age_bands_5_years"].split("-")[0],
                );
                const bNum = parseInt(
                  b["consumer.age_bands_5_years"].split("-")[0],
                );

                return aNum > bNum ? 1 : aNum < bNum ? -1 : 0;
              }) ?? [],
            "consumer.age_bands_5_years",
            "consumer.gender",
            "consumer.total_consumer_count",
            "number",
          )}
          formatter={function () {
            return `${this.series.name}: ${this.percentage?.toFixed(2)}%`;
          }}
        />
      </DataViz.Row>
    </>
  );
};
export default ConsumerDemographics;
