import { DataViz } from "@commonComponents";

import type { DashboardAdvancedFilterProps } from "../../Portal/Portal";
import type { AdvFilterProps } from "~/src/utils/tsUtils";

const getCommonFilters = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) => (filters ? DataViz.filterUtils.convertFilters(filters, filterOrder) : []);

export const totalViewsQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["insights_dashboard_views.insights_views_count_recent_30_days"],
    filters: getCommonFilters(filters, filterOrder),
  }) as const;

export const totalViewPercentageChangeQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: [
      "insights_dashboard_views.insights_views_count_previous_30_days",
    ],
    filters: getCommonFilters(filters, filterOrder),
  }) as const;

export const averageNumberOfViewsQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: [
      "insights_dashboard_views.avg_insights_views_per_day_last_30_days",
    ],
    filters: getCommonFilters(filters, filterOrder),
  }) as const;
export const viewsTrendQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["insights_dashboard_views.total_insights_views"],
    timeDimensions: [
      {
        dimension: "insights_dashboard_views.created",
        granularity: "month",
      },
    ],
    order: {
      "insights_dashboard_views.created": "asc",
    },
    filters: getCommonFilters(filters, filterOrder),
  }) as const;

export const averageGroupViewsQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["group.total_groups"],
    order: [["insights_dashboard_views.created", "asc"]],
    timeDimensions: [
      {
        dimension: "insights_dashboard_views.created",
        granularity: "day",
        dateRange: "Last 30 days",
      },
    ],
    filters: getCommonFilters(filters, filterOrder),
  }) as const;
