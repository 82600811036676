const initialState = {
  customTables: {
    isFetching: true,
    error: false,
    result: [],
  },
  validators: {
    isFetching: true,
    error: false,
    result: [],
  },
  edited: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "CUSTOM_TABLES_FETCH": {
      return Object.assign({}, state, {
        customTables: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    case "CUSTOM_TABLES_DELETE": {
      const _customTables = state.customTables.result.filter(
        (table) => table.identifier !== action.payload.identifier,
      );

      return Object.assign({}, state, {
        ...state,
        customTables: {
          ...state.customtables,
          result: _customTables,
        },
      });
    }

    case "CUSTOM_TABLES_CREATE": {
      return Object.assign({}, state, {
        ...state,
        customTables: {
          ...state.customTables,
          result: [...state.customTables.result, action.payload.customTable],
        },
      });
    }

    case "CUSTOM_TABLES_UPDATE": {
      return Object.assign({}, state, {
        ...state,
        customTables: {
          ...state.customTables,
          result: state.customTables.result.map((table) => {
            if (table.identifier === action.payload.customTable.identifier) {
              return action.payload.customTable;
            }

            return table;
          }),
        },
      });
    }

    case "CUSTOM_TABLES_EDITED": {
      return Object.assign({}, state, {
        ...state,
        edited: action.payload.edited,
      });
    }

    case "CUSTOM_TABLES_VALIDATORS_FETCH": {
      return Object.assign({}, state, {
        validators: action.payload,
      });
    }

    default:
      return state;
  }
}
