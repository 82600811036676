export default [
  {
    cubeKey: "roster_volume.plan_state",
    label: "Plan State",
  },
  {
    cubeKey: "roster_volume.payer_name",
    label: "Payer Name",
  },
  {
    cubeKey: "roster_volume.market_segment",
    label: "Market Segment",
  },
  {
    cubeKey: "roster_volume.market_sub_segment",
    label: "Market Sub Segment",
  },
];
