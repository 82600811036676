export default [
  {
    isParent: true,
    label: "Consumer Demographics",
    id: "consumer",
  },
  {
    cubeKey: "consumer.enrich_state",
    label: "State",
    parentId: "consumer",
  },
  {
    cubeKey: "consumer.enrich_city",
    label: "City",
    parentId: "consumer",
  },
  {
    cubeKey: "consumer.enrich_zip",
    label: "Zip",
    parentId: "consumer",
  },
  {
    cubeKey: "consumer.gender",
    label: "Gender",
    parentId: "consumer",
  },
  {
    cubeKey: "consumer.age_bands_5_years",
    label: "5-Year Age Range",
    parentId: "consumer",
  },
  {
    cubeKey: "consumer.race",
    label: "Race",
    parentId: "consumer",
  },
  {
    cubeKey: "consumer.ethnicity",
    label: "Ethnicity",
    parentId: "consumer",
  },
  {
    isParent: true,
    label: "Attribution",
    id: "attribution",
  },
  {
    cubeKey: "consumer_attribution.panel_name",
    label: "Panel Name",
    parentId: "attribution",
  },
];
