export default [
  {
    isParent: true,
    label: "Consumer Demographics",
    id: "consumer",
  },
  {
    cubeKey: "unassigned_consumer_detail.state",
    label: "State",
    parentId: "consumer",
  },
  {
    cubeKey: "unassigned_consumer_detail.city",
    label: "City",
    parentId: "consumer",
  },
  {
    cubeKey: "unassigned_consumer_detail.zip",
    label: "Zip",
    parentId: "consumer",
  },
  {
    cubeKey: "unassigned_consumer_detail.gender",
    label: "Gender",
    parentId: "consumer",
  },
  {
    isParent: true,
    label: "Active Roster",
    id: "active-roster",
  },
  {
    cubeKey: "roster_active.plan_state",
    label: "Plan State",
    parentId: "active-roster",
  },
  {
    cubeKey: "roster_active.payer_name",
    label: "Payer Name",
    parentId: "active-roster",
  },
  {
    cubeKey: "roster_active.market_segment",
    label: "Market Segment",
    parentId: "active-roster",
  },
  {
    cubeKey: "roster_active.market_sub_segment",
    label: "Market Sub Segment",
    parentId: "active-roster",
  },
  {
    isParent: true,
    label: "Attribution",
    id: "attribution",
  },
  {
    cubeKey: "unassigned_consumer_detail.panel_name",
    label: "Panel Name",
    parentId: "attribution",
  },
];
