import { styled } from "styled-components";

import { colorTheme } from "@utils";

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  i {
    padding: 0 8px;
    color: ${colorTheme("neutral")};

    &:hover {
      color: ${colorTheme("neutralL1")};
      cursor: pointer;
    }
  }
`;
