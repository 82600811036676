import React, { useEffect, useState } from "react";

import { Button, Modal, Callout } from "@lucernahealth/lucerna-health-ui";
import axios from "axios";
import type { AxiosResponse, AxiosError } from "axios";
import PropTypes from "prop-types";

import type { ButtonStatus } from "~/src/CommonComponents/TableExport/TableExport";

export type ActionModalMethod = "get" | "post" | "put" | "patch" | "delete";

type ActionModalProps = {
  isOpened: boolean;
  setIsOpened: (isOpened: boolean) => void;
  title: string;
  message: string;
  danger?: boolean;
  url: string;
  method: ActionModalMethod;
  data?: object;
  btnText?: string;
  btnAffirmationText?: string;
  beforeSubmitCallback?: () => void;
  successCallback?: (res: AxiosResponse) => void;
  failureCallback?: (err: AxiosError) => void;
  focusTrap?: boolean;
  confirmButtonTestId?: string;
  closeButtonTestId?: string;
  submit?: () => void;
};

const ActionModal = ({
  isOpened,
  setIsOpened,
  title,
  message,
  danger = false,
  url,
  method,
  data = {},
  btnText,
  btnAffirmationText,
  beforeSubmitCallback,
  successCallback,
  failureCallback,
  focusTrap = true,
  confirmButtonTestId = "confirm-button",
  closeButtonTestId = "close-button",
  submit: _submit,
}: ActionModalProps) => {
  const [error, setError] = useState("");
  const [submitStatus, setSubmitStatus] = useState<ButtonStatus>("ready");

  useEffect(() => {
    if (isOpened) {
      setSubmitStatus("ready");
      setError("");
    }
  }, [isOpened]);

  const getText = () => {
    if (btnText) {
      return btnText;
    } else if (method === "delete") {
      return "Delete";
    } else if (method === "put") {
      return "Update";
    } else {
      return "Save";
    }
  };

  const getAffirmationText = () => {
    if (btnAffirmationText) {
      return btnAffirmationText;
    } else if (method === "delete") {
      return "Deleting";
    } else if (method === "put") {
      return "Updating";
    } else {
      return "Saving";
    }
  };

  const submit = () => {
    setSubmitStatus("retrieving");

    if (beforeSubmitCallback) beforeSubmitCallback();
    axios({ method, url, data })
      .then((res) => {
        if (successCallback) successCallback(res);
        setIsOpened(false);
      })
      .catch((err) => {
        console.error(err);
        if (failureCallback) failureCallback(err);
        setSubmitStatus("ready");

        const errorMessage =
          err?.response?.data?.result || "Something went wrong";

        setError(errorMessage);
      });
  };

  return (
    <Modal
      closeShortcuts
      isOpened={isOpened}
      title={title}
      toggle={() => setIsOpened(false)}
      focusTrap={focusTrap}
    >
      <p>{message}</p>
      {error && <Callout mt type="error" text={error} />}
      <Modal.Footer>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <div style={{ display: "flex" }}>
            <Button
              mr
              type="secondary"
              text="Close"
              onClick={() => setIsOpened(false)}
              testId={closeButtonTestId}
            />
            <Button
              text={getText()}
              affirmationText={getAffirmationText()}
              onClick={_submit ?? submit}
              danger={(method === "delete" && !danger) || danger}
              status={submitStatus}
              testId={confirmButtonTestId}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

ActionModal.propTypes = {
  isOpened: PropTypes.bool,
  setIsOpened: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  danger: PropTypes.bool,
  url: PropTypes.string,
  method: PropTypes.string,
  data: PropTypes.object,
  btnText: PropTypes.string,
  btnAffirmationText: PropTypes.string,
  beforeSubmitCallback: PropTypes.func,
  successCallback: PropTypes.func,
  failureCallback: PropTypes.func,
  focusTrap: PropTypes.bool,
  confirmButtonTestId: PropTypes.string,
  closeButtonTestId: PropTypes.string,
  submit: PropTypes.func,
};

export default ActionModal;
