import React, { useEffect, useState } from "react";

import moment from "moment";
import PropTypes from "prop-types";
import { styled } from "styled-components";

import { colorTheme } from "@utils";

import { getActivityDetails } from "../helpers";
import LogDetails from "../LogDetails";

const LogContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
`;

const ChevronIcon = styled.i`
  font-size: 16px;
  font-weight: bold;
  min-width: 14px;

  &:hover {
    cursor: pointer;
    color: ${colorTheme("neutralL3")};
  }
`;

const Icon = styled.i`
  font-size: 16px;
  font-weight: bold;
  min-width: 14px;
`;

const LogIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  background: ${(props) => props.logStyle.background};
  color: ${colorTheme("white")};
  border-radius: 5px;
  margin-left: 15px;
`;

const LogMessage = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

const ActivityStreamSlideOutPanelLog = ({ entry, expandAll }) => {
  const logStyle = getActivityDetails(entry.action);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(expandAll);
  }, [expandAll]);

  return (
    <LogContainer>
      <LogHeader key={entry.id}>
        <ChevronIcon
          onClick={() => setIsExpanded(!isExpanded)}
          className={`fa-regular fa-chevron-${isExpanded ? "down" : "right"}`}
        />
        <LogIcon logStyle={logStyle}>
          <Icon className={logStyle.icon} />
        </LogIcon>
        <LogMessage>
          <div>{entry.short_friendly_string}</div>
          <div style={{ color: colorTheme("neutralL2"), fontSize: 12 }}>
            <i>{moment(entry.timestamp).format("MM/DD/YYYY h:mm a")}</i>
          </div>
        </LogMessage>
      </LogHeader>
      <div style={{ marginLeft: 25 }}>
        {isExpanded && <LogDetails data={entry} />}
      </div>
    </LogContainer>
  );
};

ActivityStreamSlideOutPanelLog.propTypes = {
  entry: PropTypes.object,
  expandAll: PropTypes.bool,
};

export default ActivityStreamSlideOutPanelLog;
