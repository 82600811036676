import React from "react";

import { styled } from "styled-components";

import { colorTheme } from "~/src/utils";
import { internalPageSuccess } from "~/src/utils/tsUtils";

type CopyButtonProps = {
  value: string;
  style?: React.CSSProperties;
};

const Wrapper = styled.div`
  i {
    color: ${colorTheme("neutralL2")};
    font-size: 13px;
  }
  &:hover {
    cursor: pointer;
    i {
      color: ${colorTheme("neutralL1")};
    }
  }
`;

const CopyButton = ({ value, style }: CopyButtonProps) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    internalPageSuccess("Copied to clipboard");
  };

  return (
    <Wrapper style={style}>
      <i className="fa-regular fa-copy" onClick={handleCopy} />
    </Wrapper>
  );
};

export default CopyButton;
