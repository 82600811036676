import * as Sentry from "@sentry/react";
import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import type { AnyAction, Store } from "redux";
import { createStore, applyMiddleware, compose } from "redux";
import type { ThunkDispatch } from "redux-thunk";
import thunk from "redux-thunk";

import rootReducer from "./reducers";

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

// @ts-expect-error - window is a global object
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(thunk), sentryReduxEnhancer),
);

export type RootState = ReturnType<typeof rootReducer>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

export type AppStore = Omit<Store<RootState, AnyAction>, "dispatch"> & {
  dispatch: AppThunkDispatch;
};
export type AppDispatch = AppStore["dispatch"];

export const useAppDispatch: () => AppThunkDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
