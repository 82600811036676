import React from "react";

import { gridSpacing } from "@lucernahealth/lucerna-health-ui";
import { styled } from "styled-components";

import { colorTheme } from "~/src/utils";

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${colorTheme("neutralL4")};
  padding: ${gridSpacing[4]}px;
  min-height: 147px;
  min-width: 251px;
  flex-grow: 1;
`;

const Loading = () => (
  <LoadingWrapper>
    <i
      style={{ fontSize: 24 }}
      className="fa-regular fa-spinner-third fa-spin"
    />
  </LoadingWrapper>
);

export default Loading;
