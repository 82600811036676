export default [
  {
    isParent: true,
    label: "Dashboard View",
    id: "dashboard-view",
  },
  {
    cubeKey: "insights_dashboard_views.page_name",
    label: "Dashboard Name",
    parentId: "dashboard-view",
  },
  {
    cubeKey: "insights_dashboard_views.object_type",
    label: "Dashboard Type",
    parentId: "dashboard-view",
  },
  {
    isParent: true,
    label: "User",
    id: "user",
  },
  {
    cubeKey: "user.user_name",
    label: "User Name",
    parentId: "user",
  },
  {
    isParent: true,
    label: "Group",
    id: "group",
  },
  {
    cubeKey: "group.group_name",
    label: "Group Name",
    parentId: "group",
  },
];
