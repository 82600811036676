import { styled } from "styled-components";

import { colorTheme } from "@utils";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;

  p,
  b,
  h1,
  h2,
  h3,
  h4 {
    color: ${colorTheme("neutral")};
  }
`;
