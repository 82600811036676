import React, { useEffect, useRef, useState } from "react";

import { Button, ErrorMessage } from "@lucernahealth/lucerna-health-ui";
import axios from "axios";
import Papa from "papaparse";
import PropTypes from "prop-types";
import { styled } from "styled-components";
import XLSX from "xlsx";

import { colorTheme } from "@utils";

const AttachmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colorTheme("neutralL4")};
  padding: 45px 20px;
  border-radius: 5px;
`;

/*
  TODO:
    This file is no longer a valid common component as it only pertains to data mapping, particularly the file
    SourceInformation. In the future this should be removed from the common components and moved into data mapping.
    In result it could make some things a little easier to program when the next iteration/flow of source information 
    is worked on.
*/

const Attachment = ({
  style,
  uploadFileType,
  setHeaders,
  selectedSheetName,
  selectedSheetNumber,
  setSheetNames,
  setFileName,
  delimiter = "",
  extractHeaders,
  buttonOnly,
}) => {
  const uploadFileRef = useRef(null);

  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const accept =
    uploadFileType === "excel"
      ? [
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
        ]
      : ["text/*", ".gz", ".dat"];

  const parseCSV = () => {
    if (file.name.endsWith(".gz")) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append(
        "file_config",
        JSON.stringify({
          delimiter: delimiter,
        }),
      );
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      axios
        .post(`data_mapping/extract-file-headers`, formData, {
          headers: headers,
        })
        .then((response) => {
          setHeaders(response.data.result.headers);
        })
        .catch((err) => {
          console.error(err);
          setError("Cannot extract headers from file");
        });
    } else {
      Papa.parse(file, {
        header: true,
        preview: 1,
        delimiter: delimiter,
        complete: (results) => {
          if (results.data[0]) {
            setHeaders(Object.keys(results.data[0]));
          }
        },
      });
    }
  };

  const parseExcel = async () => {
    const extractHeaders = () =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        const headers = [];

        reader.onload = function (e) {
          reader.onerror = () => reject();
          let data = e.target.result;
          let workbook = XLSX.read(data, { type: "binary" });

          //Sheet is selected using the sheet name. Workbook.SheetNames gets a list of all possible sheet names
          let sheet = workbook.Sheets[selectedSheetName];
          if (selectedSheetNumber !== null) {
            sheet =
              workbook.Sheets[workbook.SheetNames[selectedSheetNumber - 1]];
          }

          var range = XLSX.utils.decode_range(sheet["!ref"]);
          var C,
            R = range.s.r;
          for (C = range.s.c; C <= range.e.c; ++C) {
            var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];

            var hdr = "UNKNOWN " + C; // <-- replace with your desired default
            if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);

            headers.push(hdr);
          }
          resolve(headers);
        };
        reader.readAsBinaryString(file);
      });
    const _headers = await extractHeaders();
    setHeaders(_headers.map((str) => str.trim()));
  };

  //Creates excel sheet selector
  function createExcelSheetInput(file) {
    try {
      let reader = new FileReader();
      reader.onload = function (e) {
        let data = e.target.result;

        let workbook = XLSX.read(data, { type: "binary" });
        setSheetNames(workbook.SheetNames);
      };
      reader.readAsBinaryString(file);
    } catch (e) {
      console.error(e);
    }
  }

  const addFile = () => {
    if (!file) return;

    if (uploadFileType === "excel") {
      if (accept.includes(file.type)) createExcelSheetInput(file);
      else {
        setError("File type not accepted");
        return;
      }
    }
    setFileName(file.name);
    setError(null);
  };

  useEffect(() => {
    addFile();
  }, [file]);

  useEffect(() => {
    setFileName("");
    setFile(null);
  }, [uploadFileType]);

  useEffect(() => {
    if (extractHeaders && file) {
      if (uploadFileType === "excel") parseExcel();
      else {
        parseCSV();
      }
    }
  }, [extractHeaders]);

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files.length > 0) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const onDragEnter = (e) => {
    e.preventDefault();
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onUpload = () => {
    if (uploadFileRef.current.files.length > 0) {
      setFile(uploadFileRef.current.files[0]);
    }
  };

  if (buttonOnly) {
    return (
      <>
        <input
          ref={uploadFileRef}
          accept={accept}
          hidden
          type="file"
          onInput={onUpload}
        />
        <Button
          style={style}
          type="secondary"
          icon="fa-file-upload"
          onClick={() => uploadFileRef.current.click()}
          text="Upload Source File"
        />
      </>
    );
  }

  return (
    <>
      <AttachmentContainer
        style={style}
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        onClick={() => uploadFileRef.current.click()}
      >
        <input
          hidden
          ref={uploadFileRef}
          accept={accept}
          type="file"
          onInput={onUpload}
        />
        <Button icon="fa-file-upload" text="Upload File" />
      </AttachmentContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

Attachment.propTypes = {
  delimiter: PropTypes.string,
  setFileName: PropTypes.func,
  selectedSheetName: PropTypes.string,
  selectedSheetNumber: PropTypes.number,
  setSheetNames: PropTypes.func,
  setHeaders: PropTypes.func,
  style: PropTypes.object,
  uploadFileType: PropTypes.string,
  extractHeaders: PropTypes.bool,
  buttonOnly: PropTypes.bool,
};

export default Attachment;
