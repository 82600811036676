import { combineReducers } from "redux";

import attributionPanel from "./attributionPanel";
import careTeamReducer from "./careTeam";
import companyReducer from "./company";
import facilityReducer from "./facility";
import healthPlanReducer from "./healthPlan";
import metric from "./metric";
import organizationReducer from "./organization";
import providerReducer from "./provider";

export default combineReducers({
  attributionPanel,
  metric,
  providerReducer,
  facilityReducer,
  careTeamReducer,
  companyReducer,
  healthPlanReducer,
  organizationReducer,
});
