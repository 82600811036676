import React, { useContext } from "react";

import { DataViz } from "@commonComponents";
import {
  gridSpacing,
  Table,
  TableLink,
} from "@lucernahealth/lucerna-health-ui";

import { numberWithCommas } from "@utils";

import {
  appointmentsByFacilityQuery,
  appointmentsByProviderQuery,
  appointmentsFilterDimensions,
} from "./Queries";
import { PortalContext } from "../../Portal/Portal";
import Header from "~/src/CommonComponents/DataVisualization/Common/Header";
import { setTableExport } from "~/src/CommonComponents/TableExport/TableExport";
import { PROVIDER_360_URLS } from "~/src/urls";
import { useCubeApiQuery } from "~/src/utils/tsUtils";

const AppointmentTrendFacilityProviders = () => {
  const { filters, filterOrder } = useContext(PortalContext);

  DataViz.filterUtils.useFilterOptionsNormalized({
    dimensions: appointmentsFilterDimensions,
  });

  const {
    resultSet: appointmentsByFacilityResult,
    isLoading: appointmentsByFacilityLoading,
  } = useCubeApiQuery(appointmentsByFacilityQuery(filters, filterOrder));

  const {
    resultSet: appointmentsByProviderResult,
    isLoading: appointmentsByProviderLoading,
  } = useCubeApiQuery(appointmentsByProviderQuery(filters, filterOrder));

  const groupDataByFacilityName = () => {
    const rawData = appointmentsByFacilityResult?.rawData() ?? [];

    const distinctFacilityNames = [
      ...new Set(
        rawData
          .map((item) => item["appointment_completed.facility_name"])
          .filter((item) => !!item?.trim()),
      ),
    ];
    const distinctVisitTypes = [
      ...new Set(
        rawData
          .map((item) => item["appointment_completed.appointment_category"])
          .filter((item) => !!item?.trim()),
      ),
    ];

    const data = distinctFacilityNames.map((facilityName) => {
      const facilityData = rawData.filter(
        (item) => item["appointment_completed.facility_name"] === facilityName,
      );

      const res: Record<string, string | number> = {
        facilityName,
        facilityId: facilityData?.[0]?.["facility.facility_id"],
      };
      facilityData.forEach((item) => {
        res[item["appointment_completed.appointment_category"]] = parseInt(
          item["appointment_completed.total_appt_count_last_12_month"],
        );
      });
      distinctVisitTypes.forEach((visitType) => {
        if (!res[visitType]) {
          res[visitType] = 0;
        }
      });
      return res;
    });

    data.sort((a, b) => {
      const aSum = distinctVisitTypes.reduce(
        (acc, curr) => acc + (a[curr] as number),
        0,
      );
      const bSum = distinctVisitTypes.reduce(
        (acc, curr) => acc + (b[curr] as number),
        0,
      );
      return bSum - aSum;
    });

    const columns = [
      {
        Header: "Facility Name",
        accessor: "facilityName",
        id: "facilityName",
        Cell: (props: {
          value: string;
          row: { original: { facilityId: string } };
        }) => (
          <TableLink
            newTab
            url={`${PROVIDER_360_URLS.PROVIDER_DIRECTORY_FACILITIES}/view/${props.row.original?.facilityId}`}
          >
            {props.value}
          </TableLink>
        ),
        width: 300,
        sticky: "left",
      },
    ];

    distinctVisitTypes.sort((a, b) => {
      const aSum = data.reduce((acc, curr) => acc + (curr[a] as number), 0);
      const bSum = data.reduce((acc, curr) => acc + (curr[b] as number), 0);
      return bSum - aSum;
    });

    distinctVisitTypes.forEach((visitType, i) => {
      columns.push({
        Header: visitType,
        accessor: visitType,
        id: visitType || `${i}`,
        Cell: (props) => <p>{numberWithCommas(props.value)}</p>,
        width: 150,
        sticky: "",
      });
    });

    return { data, columns };
  };

  const groupDataByProviderName = () => {
    const rawData = appointmentsByProviderResult?.rawData() ?? [];

    const distinctProviderNames = [
      ...new Set(
        rawData
          .map((item) => item["appointment_completed.provider_full_name"])
          .filter((item) => !!item?.trim()),
      ),
    ];
    const distinctVisitTypes = [
      ...new Set(
        rawData
          .map((item) => item["appointment_completed.appointment_category"])
          .filter((item) => !!item?.trim()),
      ),
    ];

    const data = distinctProviderNames.map((providerName) => {
      const providerData = rawData.filter(
        (item) =>
          item["appointment_completed.provider_full_name"] === providerName,
      );
      const res: Record<string, number | string> = {
        providerName,
        providerId: providerData?.[0]?.["provider.provider_id"],
      };
      providerData.forEach((item) => {
        res[item["appointment_completed.appointment_category"]] = parseInt(
          item["appointment_completed.total_appt_count_last_12_month"],
        );
      });
      distinctVisitTypes.forEach((visitType) => {
        if (!res[visitType]) {
          res[visitType] = 0;
        }
      });
      return res;
    });

    data.sort((a, b) => {
      const aSum = distinctVisitTypes.reduce(
        (acc, curr) => acc + (a[curr] as number),
        0,
      );
      const bSum = distinctVisitTypes.reduce(
        (acc, curr) => acc + (b[curr] as number),
        0,
      );
      return bSum - aSum;
    });

    const columns = [
      {
        Header: "Provider Name",
        accessor: "providerName",
        id: "providerName",
        Cell: (props: {
          value: string;
          row: { original: { providerId: string } };
        }) => (
          <TableLink
            newTab
            url={`${PROVIDER_360_URLS.PROVIDER_DIRECTORY_PROVIDERS}/view/${props.row.original?.providerId}`}
          >
            {props.value}
          </TableLink>
        ),
        width: 300,
        sticky: "left",
      },
    ];

    distinctVisitTypes.sort((a, b) => {
      const aSum = data.reduce((acc, curr) => acc + (curr[a] as number), 0);
      const bSum = data.reduce((acc, curr) => acc + (curr[b] as number), 0);
      return bSum - aSum;
    });

    distinctVisitTypes.forEach((visitType) => {
      columns.push({
        Header: visitType,
        accessor: visitType,
        id: visitType,
        Cell: (props) => <p>{numberWithCommas(props.value)}</p>,
        width: 150,
        sticky: "",
      });
    });

    return { data, columns };
  };

  const { data: facilityData, columns: facilityColumns } =
    groupDataByFacilityName();
  const { data: providerData, columns: providerColumns } =
    groupDataByProviderName();

  return (
    <>
      <Header
        title="Visit Counts by Category by Facility"
        toolTip="Count of completed visits for visit categories by facility (total as of current date)"
        style={{ marginBottom: 0 }}
      />
      <Table
        isLoading={appointmentsByFacilityLoading}
        data={facilityData}
        columns={facilityColumns}
        style={{ marginBottom: gridSpacing[5] }}
        pageSize={10}
        exportAction={() => {
          setTableExport({
            data: facilityData,
            columnMap: facilityColumns.map((column) => {
              return {
                csvColumn: column.Header,
                jsColumn: column.accessor,
              };
            }),
            exportName: "Visit Counts by Category by Facility",
          });
        }}
      />
      <Header
        title="Visit Counts by Category by Provider"
        toolTip="Count of completed visits for visit categories by provider (total as of current date)"
        style={{ marginBottom: 0 }}
      />
      <Table
        isLoading={appointmentsByProviderLoading}
        data={providerData}
        columns={providerColumns}
        style={{ marginBottom: gridSpacing[5] }}
        pageSize={10}
        exportAction={() => {
          setTableExport({
            data: providerData,
            columnMap: providerColumns.map((column) => {
              return {
                csvColumn: column.Header,
                jsColumn: column.accessor,
              };
            }),
            exportName: "Visit Counts by Category by Provider",
          });
        }}
      />
    </>
  );
};

export default AppointmentTrendFacilityProviders;
