const initialState = {
  isFetching: true,
  error: false,
  result: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "CONSUMER_SET_GOLDEN_RECORD": {
      return Object.assign({}, state, {
        isFetching: action.payload.isFetching,
        error: action.payload.error,
        result: action.payload.result,
      });
    }

    case "CONSUMER_ADD_GOLDEN_RECORD": {
      return Object.assign({}, state, {
        ...state,
        result: [...state.result, action.payload],
      });
    }

    case "CONSUMER_UPDATE_GOLDEN_RECORD": {
      let records = state.result.map((record) => {
        if (record.identifier === action.payload.identifier) {
          return action.payload;
        }
        return record;
      });
      return Object.assign({}, state, {
        ...state,
        result: records,
      });
    }

    case "CONSUMER_DELETE_GOLDEN_RECORD": {
      let records = [...state.result].filter(
        (record) => record.identifier !== action.payload.goldenRecordId,
      );

      return Object.assign({}, state, {
        ...state,
        result: records,
      });
    }

    default:
      return state;
  }
}
