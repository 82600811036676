import { slugifyUrl } from "@utils";

const initialState = {
  dashboards: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "DASHBOARD_FETCH_ALL": {
      let result = action.payload.result;
      if (action.payload.isRefreshing === true) {
        result = state.dashboards.result;
      }
      return Object.assign({}, state, {
        dashboards: {
          ...action.payload,
          result: result.map((dashboard) => {
            return {
              ...dashboard,
              metadata: { dashboardId: dashboard.uuid, labels: [] },
              route: `all-dashboards/${slugifyUrl(dashboard.uuid)}`,
            };
          }),
        },
      });
    }

    default:
      return state;
  }
}
