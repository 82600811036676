import PROVIDER_360_URLS from "./urls";

export default [
  {
    appTitle: true,
    header: "Provider 360",
    icon: "fa-kit fa-hdp-app-provider",
    baseRoute: PROVIDER_360_URLS.baseUrl,
  },
  {
    header: "Attribution",
    children: [
      {
        name: "Panels",
        route: PROVIDER_360_URLS.ATTRIBUTION_PANELS,
        permissions: ["attribution.view_panel"],
      },
      {
        name: "Assignment Operations",
        route: PROVIDER_360_URLS.ATTRIBUTION_ASSIGNMENT_OPERATIONS,
        permissions: ["attribution.manage_panel_attributions"],
      },
      {
        name: "Capacity Operations",
        route: PROVIDER_360_URLS.ATTRIBUTION_CAPACITY_OPERATIONS,
        permissions: ["attribution.view_panel"],
      },
      {
        name: "Metrics",
        route: PROVIDER_360_URLS.ATTRIBUTION_METRICS,
        permissions: ["metric.view_metric"],
      },
      {
        name: "Metric Groups",
        route: PROVIDER_360_URLS.ATTRIBUTION_METRIC_GROUPS,
        permissions: ["metric.view_metricgroup"],
      },
    ],
  },
  {
    header: "Provider Directory",
    children: [
      {
        name: "Providers",
        route: PROVIDER_360_URLS.PROVIDER_DIRECTORY_PROVIDERS,
        permissions: ["accounts.provider_page_access"],
      },
      {
        name: "Care Teams",
        route: PROVIDER_360_URLS.PROVIDER_DIRECTORY_CARE_TEAMS,
        permissions: ["accounts.care_team_page_access"],
      },
      {
        name: "Facilities",
        route: PROVIDER_360_URLS.PROVIDER_DIRECTORY_FACILITIES,
        permissions: ["accounts.facility_page_access"],
      },
      {
        name: "Companies",
        route: PROVIDER_360_URLS.PROVIDER_DIRECTORY_COMPANIES,
        permissions: ["accounts.company_page_access"],
      },
      {
        name: "Organizations",
        route: PROVIDER_360_URLS.PROVIDER_DIRECTORY_ORGANIZATIONS,
        permissions: ["accounts.organization_page_access"],
      },
      {
        name: "Health Plans",
        route: PROVIDER_360_URLS.PROVIDER_DIRECTORY_HEALTH_PLANS,
        permissions: ["accounts.health_plan_page_access"],
      },
    ],
  },

  {
    header: "Configuration",
    children: [
      {
        name: "Activity Stream",
        route: PROVIDER_360_URLS.CONFIGURATION_ACTIVITY_STREAM,
        permissions: ["auditlog.view_logentry"],
      },
      {
        name: "Directory Options",
        route: PROVIDER_360_URLS.CONFIGURATION_DIRECTORY_OPTIONS,
        permissions: ["accounts.directory_options_page_access"],
      },
    ],
  },
];
