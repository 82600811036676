import React, { useState } from "react";

import {
  Modal,
  Button,
  Text,
  Callout,
  TextArea,
  Switch,
} from "@lucernahealth/lucerna-health-ui";
import axios from "axios";
import queryString from "query-string";
import { connect } from "react-redux";

import { slugifyUrl } from "@utils";

import type { SaveFilterModalProps } from ".";

const originalData = {
  uuid: null,
  name: "",
  slug: "",
  app: "",
  model: "",
  pageKey: "",
  description: "",
  public: true, // TODO: Once a user can be attached to a filter, this should be false by default
};

type ErrorProps = {
  name?: string;
  main?: string;
};

const SaveFilterModal = ({
  app,
  model,
  pageKey,
  query,
  savedFilters,
  constantFilters,
  close,
  saveFilter,
}: SaveFilterModalProps) => {
  const [data, setData] = useState(originalData);
  const [errors, setErrors] = useState<ErrorProps>({});

  const validate = () => {
    const _errors: ErrorProps = {};

    if (data.name.trim().length === 0) {
      _errors.name = "Name required";
    } else if (data.name.trim().length < 3) {
      _errors.name = "Name must be 3 or more characters long";
    } else if (data.name.trim().length > 200) {
      _errors.name = "Name must be less than 200 characters long";
    } else if (
      savedFilters.some(
        ({ uuid, name }) =>
          uuid !== data.uuid && name.toLowerCase() === data.name.toLowerCase(),
      )
    ) {
      _errors.name = "Name already exists";
    }

    const isValid = Object.keys(_errors).length === 0;
    if (!isValid) {
      _errors.main = "Not all fields have been satisfied";
    }

    setErrors(_errors);
    return isValid;
  };

  const submit = () => {
    if (validate()) {
      // Filter out constant filters from the query string
      const queryObject = queryString.parse(query);
      Object.keys(queryObject).forEach((key) => {
        if (constantFilters && Object.keys(constantFilters).includes(key)) {
          delete queryObject[key];
        }
      });

      if (Object.keys(queryObject).length === 0) {
        setErrors({ main: "No filters to save" });
        return;
      }

      const sendQuery = queryString.stringify(queryObject);

      axios
        .post("search/filters", {
          ...data,
          app,
          model,
          key: pageKey,
          query: sendQuery,
          slug: slugifyUrl(data.name),
        })
        .then((res) => {
          saveFilter(res.data.result);
        })
        .catch((err) => {
          console.error(err);
          setErrors({ main: "Error saving filter" });
        });
    }
  };

  return (
    <>
      <Text
        required
        mb
        label="Name"
        value={data.name}
        onChange={(e) => setData({ ...data, name: e.target.value })}
        error={errors.name}
      />
      <TextArea
        rows={2}
        label="Description"
        value={data.description}
        placeholder="Enter Description"
        onChange={(e) => setData({ ...data, description: e.target.value })}
      />
      <Switch
        label="Public"
        checked={data.public}
        onChange={() => setData({ ...data, public: !data.public })}
      />
      {errors.main && <Callout mt mb type="error" text={errors.main} />}
      <Modal.Footer>
        <Button mr type="secondary" text="Cancel" onClick={close} />
        <Button text="Save" onClick={submit} />
      </Modal.Footer>
    </>
  );
};

export default connect()(SaveFilterModal);
