import { DataViz } from "@commonComponents";
import moment from "moment/moment";

import type { DashboardAdvancedFilterProps } from "../../Portal/Portal";
import type { AdvFilterProps } from "~/src/utils/tsUtils";

const getCommonFilters = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) => (filters ? DataViz.filterUtils.convertFilters(filters, filterOrder) : []);

export const totalDashboardViewsQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["insights_dashboard_views.total_pages"],
    timeDimensions: [
      {
        dimension: "insights_dashboard_views.created",
        dateRange: "Last 30 days",
      },
    ],
    filters: getCommonFilters(filters, filterOrder),
  }) as const;

export const totalDashboardViewsLastThirtyQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["insights_dashboard_views.total_pages"],
    timeDimensions: [
      {
        dimension: "insights_dashboard_views.created",
        dateRange: [
          moment().subtract(60, "days").format("YYYY-MM-DD"),
          moment().subtract(30, "days").format("YYYY-MM-DD"),
        ],
      },
    ],
    filters: getCommonFilters(filters, filterOrder),
  }) as const;

export const averageDashboardViewsQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    order: {
      "insights_dashboard_views.created": "asc",
    },
    timeDimensions: [
      {
        dimension: "insights_dashboard_views.created",
        granularity: "day",
        dateRange: "Last 30 days",
      },
    ],
    measures: ["insights_dashboard_views.total_pages"],
    filters: getCommonFilters(filters, filterOrder),
  }) as const;

export const averageDashboardDailyViewsQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    order: {
      "insights_dashboard_views.created": "asc",
    },
    timeDimensions: [
      {
        dimension: "insights_dashboard_views.created",
        granularity: "day",
        dateRange: "Last 30 days",
      },
    ],
    measures: [
      "insights_dashboard_views.total_pages",
      "insights_dashboard_views.total_insights_views",
    ],
    filters: getCommonFilters(filters, filterOrder),
  }) as const;

export const topTenDashboardsQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    order: {
      "insights_dashboard_views.total_insights_views": "desc",
    },
    limit: 10,
    dimensions: [
      "insights_dashboard_views.page_name",
      "insights_analytic_domain.analytic_domain_name",
      "insights_subdomain.subdomain_name",
    ],
    timeDimensions: [
      {
        dimension: "insights_dashboard_views.created",
        dateRange: "Last 12 months",
      },
    ],
    measures: ["insights_dashboard_views.total_insights_views"],
    filters: getCommonFilters(filters, filterOrder),
  }) as const;

export const viewPerDashboardsTableQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    dimensions: [
      "insights_dashboard_views.page_name",
      "insights_analytic_domain.analytic_domain_name",
      "insights_subdomain.subdomain_name",
    ],
    timeDimensions: [
      {
        dimension: "insights_dashboard_views.created",
        dateRange: "Last 12 months",
      },
    ],
    measures: ["insights_dashboard_views.total_insights_views"],
    filters: getCommonFilters(filters, filterOrder),
  }) as const;
