const initialState = {
  ruleSets: {
    isFetching: true,
    result: [],
    error: false,
  },
  valueMappings: {
    isFetching: true,
    result: [],
    error: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "RECODING_FETCH_RULE_SETS": {
      return Object.assign({}, state, {
        ruleSets: {
          result: action.payload.result,
          error: action.payload.error,
          isFetching: action.payload.isFetching,
        },
      });
    }

    case "RECODING_FETCH_VALUE_MAPPINGS": {
      let result = action.payload.result;
      if (action.payload.isRefreshing) {
        result = state.valueMappings.result;
      }

      return Object.assign({}, state, {
        valueMappings: {
          result: result,
          error: action.payload.error,
          isFetching: action.payload.isFetching,
        },
      });
    }

    default:
      return state;
  }
}
