import { DataViz } from "@commonComponents";

import type { DashboardAdvancedFilterProps } from "../../Portal/Portal";
import type { AdvFilterProps } from "~/src/utils/tsUtils";

export const overallAssignedQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["assigned_consumer_detail.total_luc_id_count"],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;

export const overallUnassignedQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["unassigned_consumer_detail.total_luc_id_count"],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;

export const unassignedByPanelQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: [
      "unassigned_consumer_detail.total_luc_id_count",
      "appointment_completed.any_provider_total_luc_id_count_540_days",
    ],
    dimensions: ["unassigned_consumer_detail.panel_name"],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;

export const seenFunnelQuery1 = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: [
      "unassigned_consumer_detail.total_luc_id_count",
      "appointment_completed.any_provider_total_luc_id_count_540_days",
    ],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;

export const payerBreakdownUnassignedQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["unassigned_consumer_detail.total_luc_id_count"],
    dimensions: ["roster_active.payer_name"],
    order: {
      "unassigned_consumer_detail.total_luc_id_count": "desc",
    },
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;

export const marketBreakdownUnassignedQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["unassigned_consumer_detail.total_luc_id_count"],
    dimensions: ["roster_active.market_segment"],
    order: {
      "unassigned_consumer_detail.total_luc_id_count": "desc",
    },
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;

export const unassignedDemographicsQuery = (
  filters?: AdvFilterProps,
  filterOrder?: DashboardAdvancedFilterProps[],
) =>
  ({
    measures: ["unassigned_consumer_detail.total_luc_id_count"],
    dimensions: [
      "unassigned_consumer_detail.age_category",
      "unassigned_consumer_detail.gender",
    ],
    order: [
      ["unassigned_consumer_detail.age_category", "asc"],
      ["unassigned_consumer_detail.total_luc_id_count", "desc"],
    ],
    filters: filters
      ? DataViz.filterUtils.convertFilters(filters, filterOrder)
      : [],
  }) as const;

export const unassignedDimensions = [
  "unassigned_consumer_detail.state",
  "unassigned_consumer_detail.city",
  "unassigned_consumer_detail.zip",
  "unassigned_consumer_detail.gender",
  "roster_active.plan_state",
  "roster_active.payer_name",
  "roster_active.market_segment",
  "roster_active.market_sub_segment",
  "unassigned_consumer_detail.panel_name",
];
