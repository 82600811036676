const initialState = {
  configurations: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
  configuration: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
  sources: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
  sqlTransformations: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
  parsers: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
  filters: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
  targets: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
  runHistory: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
  selectedTables: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
  unmappedColumns: [],
  editingSectionsIds: [],
  savingSectionsIds: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "DATA_MAPPING_FETCH_CONFIGURATIONS": {
      let result = action.payload.result?.results ?? action.payload.results;
      if (action.payload.isRefreshing) {
        result = state.configurations.result;
      }

      return Object.assign({}, state, {
        configurations: { ...action.payload, result },
      });
    }
    case "DATA_MAPPING_FETCH_CONFIGURATION": {
      let result = action.payload.result;
      if (action.payload.isRefreshing) {
        result = state.configuration.result;
      }

      return Object.assign({}, state, {
        configuration: {
          isRefreshing: false,
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: result,
        },
      });
    }

    case "DATA_MAPPING_REPLACE_CONFIGURATION": {
      return Object.assign({}, state, {
        configuration: action.payload,
      });
    }

    case "DATA_MAPPING_ADD_UNMAPPED_COLUMNS": {
      return Object.assign({}, state, {
        unmappedColumns: action.payload.columns,
      });
    }

    case "DATA_MAPPING_REMOVE_UNMAPPED_COLUMN": {
      return Object.assign({}, state, {
        unmappedColumns: state.unmappedColumns.filter(
          (col) => col !== action.payload.column,
        ),
      });
    }

    case "DATA_MAPPING_CLEAR_UNMAPPED_COLUMN": {
      return Object.assign({}, state, {
        unmappedColumns: [],
      });
    }

    case "DATA_MAPPING_FETCH_SELECTED_TABLES": {
      // We want to keep any unmapped selected tables along with the currently targeted tables
      const result = [...action.payload.result];
      const unmappedSelectedTables = state.selectedTables.result.filter(
        (table) => !result.find((obj) => obj.uuid === table.uuid),
      );
      const uniqueResults = [...result, ...unmappedSelectedTables];

      return Object.assign({}, state, {
        selectedTables: {
          isFetching: false,
          isRefreshing: false,
          error: false,
          result: uniqueResults,
        },
      });
    }

    case "DATA_MAPPING_UPDATE_SELECTED_TABLES": {
      return Object.assign({}, state, {
        selectedTables: {
          ...state.selectedTables,
          result: [...state.selectedTables.result, ...action.payload.tables],
        },
      });
    }

    case "DATA_MAPPING_REMOVE_SELECTED_TABLES": {
      return Object.assign({}, state, {
        selectedTables: {
          ...state.selectedTables,
          result: state.selectedTables.result.filter(
            (table) => table.uuid !== action.payload.table_uuid,
          ),
        },
      });
    }

    case "DATA_MAPPING_CLEAR_SELECTED_TABLES": {
      return Object.assign({}, state, {
        selectedTables: {
          isFetching: true,
          isRefreshing: false,
          error: null,
          result: [],
        },
      });
    }

    case "DATA_MAPPING_FETCH_TARGETS": {
      let result = action.payload.result;
      if (action.payload.isRefreshing) {
        result = state.targets.result;
      }

      return Object.assign({}, state, {
        targets: { ...action.payload, result },
      });
    }

    case "DATA_MAPPING_REPLACE_TARGETS": {
      return Object.assign({}, state, {
        targets: action.payload,
      });
    }
    case "DATA_MAPPING_CLEAR_TARGETS": {
      return Object.assign({}, state, {
        targets: initialState.targets,
      });
    }

    case "DATA_MAPPING_FETCH_SOURCES": {
      let result = action.payload.result;
      if (action.payload.isRefreshing) {
        result = state.sources.result;
      }

      return Object.assign({}, state, {
        sources: { ...action.payload, result },
      });
    }

    case "DATA_MAPPING_FETCH_SQL_TRANSFORMATIONS": {
      let result = action.payload.result;
      if (action.payload.isRefreshing) {
        result = state.sqlTransformations.result;
      }

      return Object.assign({}, state, {
        sqlTransformations: { ...action.payload, result },
      });
    }

    case "DATA_MAPPING_FETCH_PARSERS": {
      let result = action.payload.result;
      if (action.payload.isRefreshing) {
        result = state.parsers.result;
      }

      return Object.assign({}, state, {
        parsers: { ...action.payload, result },
      });
    }

    case "DATA_MAPPING_FETCH_FILTERS": {
      let result = action.payload.result;
      if (action.payload.isRefreshing) {
        result = state.filters.result;
      }

      return Object.assign({}, state, {
        filters: { ...action.payload, result },
      });
    }

    case "DATA_MAPPING_FETCH_RUN_HISTORY": {
      let result = action.payload.result;
      if (action.payload.isRefreshing) {
        result = state.runHistory.result;
      }

      return Object.assign({}, state, {
        runHistory: { ...action.payload, result },
      });
    }

    case "DATA_MAPPING_UPDATE_EDIT_SECTION_IDS": {
      let editingSectionsIds = [];

      if (!action.payload) {
        editingSectionsIds = [];
      } else {
        editingSectionsIds = [...state.editingSectionsIds];

        if (editingSectionsIds.includes(action.payload)) {
          editingSectionsIds = editingSectionsIds.filter(
            (section) => section !== action.payload,
          );
        } else {
          editingSectionsIds.push(action.payload);
        }
      }

      return Object.assign({}, state, { editingSectionsIds });
    }

    case "DATA_MAPPING_UPDATE_SAVE_SECTION_IDS": {
      let savingSectionsIds = [...state.savingSectionsIds];

      if (savingSectionsIds.includes(action.payload)) {
        savingSectionsIds = savingSectionsIds.filter(
          (section) => section !== action.payload,
        );
      } else {
        savingSectionsIds.push(action.payload);
      }

      return Object.assign({}, state, { savingSectionsIds });
    }

    default:
      return state;
  }
}
