import React, { useEffect, useState } from "react";

import {
  SlideOutPanel,
  Loading,
  Button,
} from "@lucernahealth/lucerna-health-ui";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { styled } from "styled-components";

import ActivityStreamSlideOutLog from "./ActivityStreamSlideOutPanelLog";
import { buildParams } from "../helpers";
import applications from "~/src/Router/applications";

const LogContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorEmptyMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 15px;
`;

const applicationPaths = {
  "data-hub": "",
  "provider-360": "/configuration",
};

const ActivityStreamSlideOutPanel = ({
  isOpened,
  setIsOpened,
  app,
  model,
  object_id,
  application,
}) => {
  const filters = {
    app,
    model,
    object_id,
    limit: 10,
  };
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [applicationUrl, setApplicationUrl] = useState(false);

  useEffect(() => {
    if (isOpened) {
      setIsLoading(true);
      setError(null);
      setApplicationUrl(applications.find((_app) => _app.key === application));

      axios
        .get(`activity?` + buildParams(filters))
        .then((res) => {
          setData(res.data.result.activity);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setError("Error loading activity stream data");
        });
    }
  }, [isOpened]);

  const renderContents = () => {
    if (error) {
      return <ErrorEmptyMessage>{error}</ErrorEmptyMessage>;
    } else if (isLoading) {
      return <Loading center style={{ marginTop: 25 }} />;
    } else if (data.length === 0) {
      return <ErrorEmptyMessage>No entries exist</ErrorEmptyMessage>;
    } else {
      return (
        <>
          <ButtonContainer>
            <Button
              icon={
                expandAll ? "fa-compress-arrows-alt" : "fa-expand-arrows-alt"
              }
              type="secondary"
              onClick={() => setExpandAll(!expandAll)}
              text={`${expandAll ? "Collapse" : "Expand"} All`}
            />
          </ButtonContainer>
          <LogContainer>
            {data.map((entry) => (
              <ActivityStreamSlideOutLog
                key={entry.id}
                expandAll={expandAll}
                entry={entry}
              />
            ))}
          </LogContainer>
        </>
      );
    }
  };

  return (
    <SlideOutPanel
      isOpened={isOpened}
      size={2}
      setIsOpened={setIsOpened}
      title="Activity Stream"
      footerText="View All"
      footerLink={`/${applicationUrl.url}${
        applicationPaths[applicationUrl.url]
      }/activity-stream?${buildParams({ ...filters, limit: null })}`}
      subTitle="Latest 10 Entries"
    >
      {renderContents()}
    </SlideOutPanel>
  );
};

ActivityStreamSlideOutPanel.propTypes = {
  isOpened: PropTypes.bool,
  setIsOpened: PropTypes.func,
  app: PropTypes.string,
  model: PropTypes.string,
  object_id: PropTypes.string,
  application: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    application: state?.main?.application,
  };
};

export default connect(mapStateToProps)(ActivityStreamSlideOutPanel);
